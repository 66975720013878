
import { defineComponent, ref, watch, PropType, onMounted } from 'vue';
import { notify } from "@kyvg/vue3-notification";

import {
  Configuration, EntitiesApiFactory,
  DefsUserPreferencesData, PreferencesApiFactory, ModelsBodyTypePref, ModelsChildrenPref,
  ModelsDistance, ModelsDrinkingPref, ModelsEducationPref, ModelsEyeColorPref, ModelsGenderPref, ModelsHeightPref,
  ModelsIncomePref, ModelsPetsPref, ModelsProfessionPref, ModelsSmokingPref, ModelsZodiacSignPref, ModelsAgePref,
  DefsBodyTypeData, DefsEyesColorData, DefsProfessionData, DefsEducationData,
} from '../../api';
import { useToken } from '../auth';

export default defineComponent({
  props: {
    userID: {
      type: String,
      default: "",
    },
    dialog: Boolean,
  },
  setup(props, { emit }) {
    let listZodiacSign = ref<string[]>(["Aries", "Taurus", "Gemini", "Cancer", "Leo", "Virgo", "Libra", "Scorpio", "Sagittarius", "Capricorn", "Aquarius", "Pisces"]);
    const formTitle = 'Edit User Preferences';
    const preferences = ref<DefsUserPreferencesData>({
      body_type: ref<ModelsBodyTypePref>().value,
      children: ref<ModelsChildrenPref>({}).value,
      distance: ref<ModelsDistance>({}).value,
      drinking: ref<ModelsDrinkingPref>({}).value,
      education: ref<ModelsEducationPref>({}).value,
      eye_color: ref<ModelsEyeColorPref>({}).value,
      gender: ref<ModelsGenderPref>({}).value,
      height: ref<ModelsHeightPref>({"max-height":300, "min-height":100}).value,
      income: ref<ModelsIncomePref>({}).value,
      pet: ref<ModelsPetsPref>({}).value,
      profession: ref<ModelsProfessionPref>({}).value,
      smoking: ref<ModelsSmokingPref>({}).value,
      zodiac_sign: ref<ModelsZodiacSignPref>({}).value,
      age: ref<ModelsAgePref>({"max-age":100, "min-age":18}).value,
      user_id: props.userID,
    });
    const token = useToken();
    const config = new Configuration({ apiKey: token as string });
    const prefApi = PreferencesApiFactory(config);
    const entitiesApi = EntitiesApiFactory(config)
    const bodyTypeList = ref<DefsBodyTypeData[]>()
    const eyeColorList = ref<DefsEyesColorData[]>()
    const professionList = ref<DefsProfessionData[]>()
    const educationList = ref<DefsEducationData[]>()
    const localDialog = ref(props.dialog);
    const tab = ref('basic');

  
    watch(localDialog, (newVal, oldVal) => {
      if (!newVal && oldVal) {
        emit('update:dialog', false);
      }
    });

    watch(() => props.dialog, (newVal) => {
      localDialog.value = newVal;
      getEntities()
      getPreferences(props.userID as string)
    });

    // watch(() => preferences, (newVal) => {
    //   // localPreferences.value = { ...localPreferences.value, ...newVal };
    //   //getPreferences(localPreferences.value.userId)
    // });

    // watch(() => preferences, (newVal) => {
    //   // localPreferences.value = { ...defaultPreferences, ...newVal };
    // }, { deep: true });

    const closeDialog = () => {
      localDialog.value = false;
      emit('update:dialog', false);
    };

    const savePreferences = async () => {
      try {
        let pr = preferences.value;
        let req = ref<DefsUserPreferencesData>({
          user_id: pr?.user_id as string,
          age: pr?.age,
          body_type: pr?.body_type,
          children: pr?.children,
          distance: pr?.distance,
          drinking: pr?.drinking,
          education: pr?.education,
          eye_color: pr?.eye_color,
          gender: pr?.gender,
          height: pr?.height,
          income: pr?.income,
          pet: pr?.pet,
          profession: pr?.profession,
          smoking: pr?.smoking,
          zodiac_sign: pr?.zodiac_sign,
        });
        const response = await prefApi.usersUserIDPreferencesPost(props.userID, req.value as DefsUserPreferencesData)
        console.log(response)
      } catch (error) {
        console.error('Failed to save-preferences:', error);
      }
    };

    onMounted(async () => {
      console.log('onMounted')
      await getEntities()
    })
    
    function preferencesDefault() {
      preferences.value.user_id = props.userID,
      preferences.value.age = {"max-age":100, "min-age":18}
      preferences.value.body_type = {"body-type-id": ""}
      preferences.value.children = {}
      preferences.value.distance = {}
      preferences.value.education = {}
      preferences.value.eye_color = {}
      preferences.value.gender = {}
      preferences.value.height = {"max-height":250, "min-height":100}
      preferences.value.income = {"min-income":1, "max-income":100000000}
      preferences.value.pet = {}
      preferences.value.profession = {}
      preferences.value.smoking = {}
      preferences.value.zodiac_sign = {}
    }

    async function getPreferences(userId: string) {
      console.log('getPreferences userId=' + userId)
      try {
        const resp = await prefApi.usersUserIDPreferencesGet(userId)
        if(resp.data["code"] == 200 && resp.data["message"] == 'sql: no rows in result set') {
          preferencesDefault()
        } else {
          preferences.value = resp.data.data as DefsUserPreferencesData
        }
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
      }
    }

    async function getEntities() {
      try {
        const response = await entitiesApi.entitiesGet()
        if (response.data != null) {
          bodyTypeList.value = response.data.data?.body_types
          eyeColorList.value = response.data.data?.eye_colors
          professionList.value = response.data.data?.professions
          educationList.value = response.data.data?.educations
        }
      } catch (error) {
        console.error('Failed to fetch chat-list:', error);
      }
    }

    const close = () => {
      emit('update:dialog', false);
    };

    return {
      educationList,
      professionList,
      eyeColorList,
      bodyTypeList,
      formTitle,
      close,
      localDialog,
      preferences,
      savePreferences,
      tab,
      closeDialog,
      listZodiacSign,
    };
  },
});
