/* tslint:disable */
/* eslint-disable */
/**
 * Equal API
 * This is a server for Equal API
 *
 * The version of the OpenAPI document: 1.6.520
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DefsAttachmentAnswerData
 */
export interface DefsAttachmentAnswerData {
    /**
     * 
     * @type {string}
     * @memberof DefsAttachmentAnswerData
     */
    'id'?: string;
    /**
     * 
     * @type {DefsQuestionReq}
     * @memberof DefsAttachmentAnswerData
     */
    'question'?: DefsQuestionReq;
    /**
     * 
     * @type {string}
     * @memberof DefsAttachmentAnswerData
     */
    'question_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsAttachmentAnswerData
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface DefsAttachmentQuestion
 */
export interface DefsAttachmentQuestion {
    /**
     * 
     * @type {string}
     * @memberof DefsAttachmentQuestion
     */
    'dimension': string;
    /**
     * 
     * @type {string}
     * @memberof DefsAttachmentQuestion
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsAttachmentQuestion
     */
    'meaning': string;
    /**
     * 
     * @type {DefsQuestionPictureInfo}
     * @memberof DefsAttachmentQuestion
     */
    'picture_one'?: DefsQuestionPictureInfo;
    /**
     * 
     * @type {DefsQuestionPictureInfo}
     * @memberof DefsAttachmentQuestion
     */
    'picture_two'?: DefsQuestionPictureInfo;
    /**
     * 
     * @type {string}
     * @memberof DefsAttachmentQuestion
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof DefsAttachmentQuestion
     */
    'system_type': string;
}
/**
 * 
 * @export
 * @interface DefsBanData
 */
export interface DefsBanData {
    /**
     * 
     * @type {string}
     * @memberof DefsBanData
     */
    'ban_user_id': string;
    /**
     * 
     * @type {DefsScore}
     * @memberof DefsBanData
     */
    'banned_user_score'?: DefsScore;
    /**
     * 
     * @type {DefsCompatibilityRes}
     * @memberof DefsBanData
     */
    'compatibility'?: DefsCompatibilityRes;
    /**
     * 
     * @type {string}
     * @memberof DefsBanData
     */
    'create_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsBanData
     */
    'id'?: string;
    /**
     * 
     * @type {DefsReason}
     * @memberof DefsBanData
     */
    'reason': DefsReason;
    /**
     * 
     * @type {string}
     * @memberof DefsBanData
     */
    'update_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsBanData
     */
    'user_id': string;
    /**
     * 
     * @type {DefsScore}
     * @memberof DefsBanData
     */
    'user_score'?: DefsScore;
}
/**
 * 
 * @export
 * @interface DefsBanListResponse
 */
export interface DefsBanListResponse {
    /**
     * 
     * @type {Array<DefsBanData>}
     * @memberof DefsBanListResponse
     */
    'data'?: Array<DefsBanData>;
}
/**
 * 
 * @export
 * @interface DefsBanResponse
 */
export interface DefsBanResponse {
    /**
     * 
     * @type {DefsBanData}
     * @memberof DefsBanResponse
     */
    'data'?: DefsBanData;
}
/**
 * 
 * @export
 * @interface DefsBodyTypeData
 */
export interface DefsBodyTypeData {
    /**
     * 
     * @type {string}
     * @memberof DefsBodyTypeData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsBodyTypeData
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface DefsBodyTypeListResponse
 */
export interface DefsBodyTypeListResponse {
    /**
     * 
     * @type {Array<DefsBodyTypeData>}
     * @memberof DefsBodyTypeListResponse
     */
    'data'?: Array<DefsBodyTypeData>;
}
/**
 * 
 * @export
 * @interface DefsBodyTypeResponse
 */
export interface DefsBodyTypeResponse {
    /**
     * 
     * @type {DefsBodyTypeData}
     * @memberof DefsBodyTypeResponse
     */
    'data'?: DefsBodyTypeData;
}
/**
 * 
 * @export
 * @interface DefsChatListResponse
 */
export interface DefsChatListResponse {
    /**
     * 
     * @type {Array<DefsOpenChat>}
     * @memberof DefsChatListResponse
     */
    'data'?: Array<DefsOpenChat>;
    /**
     * 
     * @type {number}
     * @memberof DefsChatListResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DefsChatMessageData
 */
export interface DefsChatMessageData {
    /**
     * 
     * @type {string}
     * @memberof DefsChatMessageData
     */
    'client_message_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsChatMessageData
     */
    'content'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsChatMessageData
     */
    'is_sent_by_user'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsChatMessageData
     */
    'message_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsChatMessageData
     */
    'receiver_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsChatMessageData
     */
    'sender_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsChatMessageData
     */
    'sent_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsChatMessageData
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface DefsChatMessageResponse
 */
export interface DefsChatMessageResponse {
    /**
     * 
     * @type {Array<DefsChatMessageData>}
     * @memberof DefsChatMessageResponse
     */
    'data'?: Array<DefsChatMessageData>;
    /**
     * 
     * @type {number}
     * @memberof DefsChatMessageResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DefsChatPartnerData
 */
export interface DefsChatPartnerData {
    /**
     * 
     * @type {string}
     * @memberof DefsChatPartnerData
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsChatPartnerData
     */
    'first_name'?: string;
    /**
     * 
     * @type {Array<ModelsProfileImage>}
     * @memberof DefsChatPartnerData
     */
    'images'?: Array<ModelsProfileImage>;
    /**
     * 
     * @type {boolean}
     * @memberof DefsChatPartnerData
     */
    'is_banned'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsChatPartnerData
     */
    'is_online'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsChatPartnerData
     */
    'last_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsChatPartnerData
     */
    'last_message_sender'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsChatPartnerData
     */
    'last_name'?: string;
    /**
     * 
     * @type {DefsScore}
     * @memberof DefsChatPartnerData
     */
    'partner_score'?: DefsScore;
    /**
     * 
     * @type {string}
     * @memberof DefsChatPartnerData
     */
    'sent_at'?: string;
    /**
     * 
     * @type {ModelsMessageStatusType}
     * @memberof DefsChatPartnerData
     */
    'status'?: ModelsMessageStatusType;
    /**
     * 
     * @type {string}
     * @memberof DefsChatPartnerData
     */
    'user_id'?: string;
    /**
     * 
     * @type {DefsScore}
     * @memberof DefsChatPartnerData
     */
    'user_score'?: DefsScore;
}


/**
 * 
 * @export
 * @interface DefsChatPartnerPaginatedResponse
 */
export interface DefsChatPartnerPaginatedResponse {
    /**
     * 
     * @type {Array<DefsChatPartnerData>}
     * @memberof DefsChatPartnerPaginatedResponse
     */
    'data'?: Array<DefsChatPartnerData>;
    /**
     * 
     * @type {number}
     * @memberof DefsChatPartnerPaginatedResponse
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsChatPartnerPaginatedResponse
     */
    'page_size'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsChatPartnerPaginatedResponse
     */
    'total_count'?: number;
}
/**
 * 
 * @export
 * @interface DefsChatScoreData
 */
export interface DefsChatScoreData {
    /**
     * 
     * @type {Array<DefsUserScore>}
     * @memberof DefsChatScoreData
     */
    'data'?: Array<DefsUserScore>;
}
/**
 * 
 * @export
 * @interface DefsCommonTraitsResponse
 */
export interface DefsCommonTraitsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof DefsCommonTraitsResponse
     */
    'traits'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DefsCompatibilityReq
 */
export interface DefsCompatibilityReq {
    /**
     * 
     * @type {number}
     * @memberof DefsCompatibilityReq
     */
    'attachment_weight': number;
    /**
     * 
     * @type {number}
     * @memberof DefsCompatibilityReq
     */
    'mbti_weight'?: number;
    /**
     * 
     * @type {DefsUserTestResults}
     * @memberof DefsCompatibilityReq
     */
    'user_one': DefsUserTestResults;
    /**
     * 
     * @type {DefsUserTestResults}
     * @memberof DefsCompatibilityReq
     */
    'user_two': DefsUserTestResults;
}
/**
 * 
 * @export
 * @interface DefsCompatibilityRes
 */
export interface DefsCompatibilityRes {
    /**
     * 
     * @type {number}
     * @memberof DefsCompatibilityRes
     */
    'percentage'?: number;
}
/**
 * 
 * @export
 * @interface DefsCompatibilityResponse
 */
export interface DefsCompatibilityResponse {
    /**
     * 
     * @type {number}
     * @memberof DefsCompatibilityResponse
     */
    'code'?: number;
    /**
     * 
     * @type {DefsCompatibilityRes}
     * @memberof DefsCompatibilityResponse
     */
    'data'?: DefsCompatibilityRes;
    /**
     * 
     * @type {DefsHTTPError}
     * @memberof DefsCompatibilityResponse
     */
    'error'?: DefsHTTPError;
    /**
     * 
     * @type {string}
     * @memberof DefsCompatibilityResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DefsDeviceInfoData
 */
export interface DefsDeviceInfoData {
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'app_version'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsDeviceInfoData
     */
    'battery_level'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'device_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'fcm_token'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsDeviceInfoData
     */
    'is_emulator'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsDeviceInfoData
     */
    'is_rooted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'locale'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'manufacturer'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'model'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'network_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'platform'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'player_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'screen_size'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'sdk_version'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsDeviceInfoData
     */
    'time_zone'?: string;
}
/**
 * 
 * @export
 * @interface DefsDistanceResponse
 */
export interface DefsDistanceResponse {
    /**
     * 
     * @type {number}
     * @memberof DefsDistanceResponse
     */
    'data'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefsDistanceResponse
     */
    'metrics'?: string;
}
/**
 * 
 * @export
 * @interface DefsEResponse
 */
export interface DefsEResponse {
    /**
     * 
     * @type {number}
     * @memberof DefsEResponse
     */
    'code'?: number;
    /**
     * 
     * @type {object}
     * @memberof DefsEResponse
     */
    'data'?: object;
    /**
     * 
     * @type {DefsHTTPError}
     * @memberof DefsEResponse
     */
    'error'?: DefsHTTPError;
    /**
     * 
     * @type {string}
     * @memberof DefsEResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DefsEducationData
 */
export interface DefsEducationData {
    /**
     * 
     * @type {string}
     * @memberof DefsEducationData
     */
    'degree'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsEducationData
     */
    'education'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsEducationData
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DefsEducationListResponse
 */
export interface DefsEducationListResponse {
    /**
     * 
     * @type {Array<DefsEducationData>}
     * @memberof DefsEducationListResponse
     */
    'data'?: Array<DefsEducationData>;
}
/**
 * 
 * @export
 * @interface DefsEducationResponse
 */
export interface DefsEducationResponse {
    /**
     * 
     * @type {DefsEducationData}
     * @memberof DefsEducationResponse
     */
    'data'?: DefsEducationData;
}
/**
 * 
 * @export
 * @interface DefsEntitiesData
 */
export interface DefsEntitiesData {
    /**
     * 
     * @type {Array<DefsBodyTypeData>}
     * @memberof DefsEntitiesData
     */
    'body_types'?: Array<DefsBodyTypeData>;
    /**
     * 
     * @type {Array<DefsEducationData>}
     * @memberof DefsEntitiesData
     */
    'educations'?: Array<DefsEducationData>;
    /**
     * 
     * @type {Array<DefsEyesColorData>}
     * @memberof DefsEntitiesData
     */
    'eye_colors'?: Array<DefsEyesColorData>;
    /**
     * 
     * @type {Array<DefsGenderData>}
     * @memberof DefsEntitiesData
     */
    'genders'?: Array<DefsGenderData>;
    /**
     * 
     * @type {Array<DefsHobbyData>}
     * @memberof DefsEntitiesData
     */
    'hobbies'?: Array<DefsHobbyData>;
    /**
     * 
     * @type {Array<DefsInterestsData>}
     * @memberof DefsEntitiesData
     */
    'interests'?: Array<DefsInterestsData>;
    /**
     * 
     * @type {Array<DefsProfessionData>}
     * @memberof DefsEntitiesData
     */
    'professions'?: Array<DefsProfessionData>;
    /**
     * 
     * @type {Array<DefsRaceData>}
     * @memberof DefsEntitiesData
     */
    'races'?: Array<DefsRaceData>;
    /**
     * 
     * @type {Array<DefsSexualOrientationData>}
     * @memberof DefsEntitiesData
     */
    'sexual_orientations'?: Array<DefsSexualOrientationData>;
}
/**
 * 
 * @export
 * @interface DefsEntitiesResponse
 */
export interface DefsEntitiesResponse {
    /**
     * 
     * @type {DefsEntitiesData}
     * @memberof DefsEntitiesResponse
     */
    'data'?: DefsEntitiesData;
}
/**
 * 
 * @export
 * @interface DefsEyesColorData
 */
export interface DefsEyesColorData {
    /**
     * 
     * @type {string}
     * @memberof DefsEyesColorData
     */
    'color': string;
    /**
     * 
     * @type {string}
     * @memberof DefsEyesColorData
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DefsEyesColorListResponse
 */
export interface DefsEyesColorListResponse {
    /**
     * 
     * @type {Array<DefsEyesColorData>}
     * @memberof DefsEyesColorListResponse
     */
    'data'?: Array<DefsEyesColorData>;
}
/**
 * 
 * @export
 * @interface DefsEyesColorResponse
 */
export interface DefsEyesColorResponse {
    /**
     * 
     * @type {DefsEyesColorData}
     * @memberof DefsEyesColorResponse
     */
    'data'?: DefsEyesColorData;
}
/**
 * 
 * @export
 * @interface DefsGenPinReq
 */
export interface DefsGenPinReq {
    /**
     * 
     * @type {string}
     * @memberof DefsGenPinReq
     */
    'phone': string;
}
/**
 * 
 * @export
 * @interface DefsGenPinRes
 */
export interface DefsGenPinRes {
    /**
     * 
     * @type {string}
     * @memberof DefsGenPinRes
     */
    'pin'?: string;
}
/**
 * 
 * @export
 * @interface DefsGenPinResponse
 */
export interface DefsGenPinResponse {
    /**
     * 
     * @type {DefsGenPinRes}
     * @memberof DefsGenPinResponse
     */
    'data'?: DefsGenPinRes;
}
/**
 * 
 * @export
 * @interface DefsGenderData
 */
export interface DefsGenderData {
    /**
     * 
     * @type {string}
     * @memberof DefsGenderData
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof DefsGenderData
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DefsGenderListResponse
 */
export interface DefsGenderListResponse {
    /**
     * 
     * @type {Array<DefsGenderData>}
     * @memberof DefsGenderListResponse
     */
    'data'?: Array<DefsGenderData>;
}
/**
 * 
 * @export
 * @interface DefsGenderResponse
 */
export interface DefsGenderResponse {
    /**
     * 
     * @type {DefsGenderData}
     * @memberof DefsGenderResponse
     */
    'data'?: DefsGenderData;
}
/**
 * 
 * @export
 * @interface DefsGetScoresResponse
 */
export interface DefsGetScoresResponse {
    /**
     * 
     * @type {DefsScore}
     * @memberof DefsGetScoresResponse
     */
    'receiver_score'?: DefsScore;
    /**
     * 
     * @type {DefsScore}
     * @memberof DefsGetScoresResponse
     */
    'sender_score'?: DefsScore;
}
/**
 * 
 * @export
 * @interface DefsHTTPError
 */
export interface DefsHTTPError {
    /**
     * 
     * @type {number}
     * @memberof DefsHTTPError
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefsHTTPError
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DefsHobbyData
 */
export interface DefsHobbyData {
    /**
     * 
     * @type {string}
     * @memberof DefsHobbyData
     */
    'hobby'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsHobbyData
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DefsHobbyListResponse
 */
export interface DefsHobbyListResponse {
    /**
     * 
     * @type {Array<DefsHobbyData>}
     * @memberof DefsHobbyListResponse
     */
    'data'?: Array<DefsHobbyData>;
}
/**
 * 
 * @export
 * @interface DefsHobbyResponse
 */
export interface DefsHobbyResponse {
    /**
     * 
     * @type {DefsHobbyData}
     * @memberof DefsHobbyResponse
     */
    'data'?: DefsHobbyData;
}
/**
 * 
 * @export
 * @interface DefsImageInfo
 */
export interface DefsImageInfo {
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'bucket'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'content_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'deleted'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'md5'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'media_link'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'prefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'signed_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsImageInfo
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefsImageInfo
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @interface DefsIncomeRange
 */
export interface DefsIncomeRange {
    /**
     * 
     * @type {number}
     * @memberof DefsIncomeRange
     */
    'max'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsIncomeRange
     */
    'min'?: number;
}
/**
 * 
 * @export
 * @interface DefsInterestsData
 */
export interface DefsInterestsData {
    /**
     * 
     * @type {string}
     * @memberof DefsInterestsData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsInterestsData
     */
    'interest': string;
}
/**
 * 
 * @export
 * @interface DefsInterestsListResponse
 */
export interface DefsInterestsListResponse {
    /**
     * 
     * @type {Array<DefsInterestsData>}
     * @memberof DefsInterestsListResponse
     */
    'data'?: Array<DefsInterestsData>;
}
/**
 * 
 * @export
 * @interface DefsInterestsResponse
 */
export interface DefsInterestsResponse {
    /**
     * 
     * @type {DefsInterestsData}
     * @memberof DefsInterestsResponse
     */
    'data'?: DefsInterestsData;
}
/**
 * 
 * @export
 * @interface DefsLastActiveUserData
 */
export interface DefsLastActiveUserData {
    /**
     * Attachment is the attachment of the user
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'attachment'?: string;
    /**
     * CreatedAt is the creation time of the user
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'created_at'?: string;
    /**
     * Email is the email of the user
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'email': string;
    /**
     * FirstName is the first name of the user
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'first_name'?: string;
    /**
     * ID is the unique identifier for the user
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'id'?: string;
    /**
     * IsOnline is the online status of the user
     * @type {boolean}
     * @memberof DefsLastActiveUserData
     */
    'is_online'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'last_active'?: string;
    /**
     * LastLocation user last location
     * @type {DefsLastLocation}
     * @memberof DefsLastActiveUserData
     */
    'last_location'?: DefsLastLocation;
    /**
     * LastName is the last name of the user
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'last_name'?: string;
    /**
     * MBTI is the MBTI of the user
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'mbti'?: string;
    /**
     * Phone is the phone number of the user
     * @type {string}
     * @memberof DefsLastActiveUserData
     */
    'phone'?: string;
    /**
     * ProfileImage is the profile image of the user
     * @type {Array<DefsProfileImage>}
     * @memberof DefsLastActiveUserData
     */
    'profile_image'?: Array<DefsProfileImage>;
    /**
     * Suspended is the suspended status of the user
     * @type {boolean}
     * @memberof DefsLastActiveUserData
     */
    'suspended'?: boolean;
    /**
     * Status user status possible values: Regular(500) Pro(501), Contributor(502), Power(503), ReferralLeader(504)
     * @type {ModelsUserStatus}
     * @memberof DefsLastActiveUserData
     */
    'user_status'?: ModelsUserStatus;
}


/**
 * 
 * @export
 * @interface DefsLastLocation
 */
export interface DefsLastLocation {
    /**
     * 
     * @type {number}
     * @memberof DefsLastLocation
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsLastLocation
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface DefsLoginReq
 */
export interface DefsLoginReq {
    /**
     * 
     * @type {string}
     * @memberof DefsLoginReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DefsLoginReq
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface DefsLoginRes
 */
export interface DefsLoginRes {
    /**
     * 
     * @type {string}
     * @memberof DefsLoginRes
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface DefsLoginResponse
 */
export interface DefsLoginResponse {
    /**
     * 
     * @type {DefsLoginRes}
     * @memberof DefsLoginResponse
     */
    'data'?: DefsLoginRes;
}
/**
 * 
 * @export
 * @interface DefsMBTIAnswerData
 */
export interface DefsMBTIAnswerData {
    /**
     * 
     * @type {string}
     * @memberof DefsMBTIAnswerData
     */
    'id'?: string;
    /**
     * 
     * @type {DefsQuestionReq}
     * @memberof DefsMBTIAnswerData
     */
    'question'?: DefsQuestionReq;
    /**
     * 
     * @type {string}
     * @memberof DefsMBTIAnswerData
     */
    'question_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsMBTIAnswerData
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface DefsMBTIQuestion
 */
export interface DefsMBTIQuestion {
    /**
     * 
     * @type {string}
     * @memberof DefsMBTIQuestion
     */
    'dimension': string;
    /**
     * 
     * @type {number}
     * @memberof DefsMBTIQuestion
     */
    'direction': number;
    /**
     * 
     * @type {string}
     * @memberof DefsMBTIQuestion
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsMBTIQuestion
     */
    'meaning': string;
    /**
     * 
     * @type {DefsQuestionPictureInfo}
     * @memberof DefsMBTIQuestion
     */
    'picture_one'?: DefsQuestionPictureInfo;
    /**
     * 
     * @type {DefsQuestionPictureInfo}
     * @memberof DefsMBTIQuestion
     */
    'picture_two'?: DefsQuestionPictureInfo;
    /**
     * 
     * @type {string}
     * @memberof DefsMBTIQuestion
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof DefsMBTIQuestion
     */
    'system_type': string;
}
/**
 * 
 * @export
 * @interface DefsMatchData
 */
export interface DefsMatchData {
    /**
     * 
     * @type {DefsGetScoresResponse}
     * @memberof DefsMatchData
     */
    'chat_scores'?: DefsGetScoresResponse;
    /**
     * 
     * @type {string}
     * @memberof DefsMatchData
     */
    'created_at'?: string;
    /**
     * 
     * @type {DefsUserData}
     * @memberof DefsMatchData
     */
    'match_user'?: DefsUserData;
    /**
     * 
     * @type {number}
     * @memberof DefsMatchData
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface DefsMatchListResponse
 */
export interface DefsMatchListResponse {
    /**
     * 
     * @type {Array<DefsMatchData>}
     * @memberof DefsMatchListResponse
     */
    'data'?: Array<DefsMatchData>;
    /**
     * 
     * @type {number}
     * @memberof DefsMatchListResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DefsOKWithID
 */
export interface DefsOKWithID {
    /**
     * 
     * @type {string}
     * @memberof DefsOKWithID
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DefsOpenChat
 */
export interface DefsOpenChat {
    /**
     * 
     * @type {string}
     * @memberof DefsOpenChat
     */
    'chat_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsOpenChat
     */
    'created_at'?: string;
    /**
     * 
     * @type {DefsUserData}
     * @memberof DefsOpenChat
     */
    'receiver'?: DefsUserData;
    /**
     * 
     * @type {DefsUserData}
     * @memberof DefsOpenChat
     */
    'sender'?: DefsUserData;
    /**
     * 
     * @type {string}
     * @memberof DefsOpenChat
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface DefsPackage
 */
export interface DefsPackage {
    /**
     * 
     * @type {string}
     * @memberof DefsPackage
     */
    'currency': string;
    /**
     * 
     * @type {string}
     * @memberof DefsPackage
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsPackage
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsPackage
     */
    'is_best_value': boolean;
    /**
     * 
     * @type {number}
     * @memberof DefsPackage
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof DefsPackage
     */
    'product_id': string;
    /**
     * 
     * @type {string}
     * @memberof DefsPackage
     */
    'viewer_type': string;
    /**
     * 
     * @type {number}
     * @memberof DefsPackage
     */
    'views': number;
}
/**
 * 
 * @export
 * @interface DefsPicturesRes
 */
export interface DefsPicturesRes {
    /**
     * 
     * @type {string}
     * @memberof DefsPicturesRes
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsPicturesRes
     */
    'id'?: string;
    /**
     * 
     * @type {ModelsImageInfo}
     * @memberof DefsPicturesRes
     */
    'info'?: ModelsImageInfo;
    /**
     * 
     * @type {ModelsStatusType}
     * @memberof DefsPicturesRes
     */
    'status'?: ModelsStatusType;
    /**
     * 
     * @type {string}
     * @memberof DefsPicturesRes
     */
    'updated_at'?: string;
}


/**
 * 
 * @export
 * @interface DefsProfessionData
 */
export interface DefsProfessionData {
    /**
     * 
     * @type {string}
     * @memberof DefsProfessionData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsProfessionData
     */
    'job_title'?: string;
}
/**
 * 
 * @export
 * @interface DefsProfessionListResponse
 */
export interface DefsProfessionListResponse {
    /**
     * 
     * @type {Array<DefsProfessionData>}
     * @memberof DefsProfessionListResponse
     */
    'data'?: Array<DefsProfessionData>;
}
/**
 * 
 * @export
 * @interface DefsProfessionResponse
 */
export interface DefsProfessionResponse {
    /**
     * 
     * @type {DefsProfessionData}
     * @memberof DefsProfessionResponse
     */
    'data'?: DefsProfessionData;
}
/**
 * 
 * @export
 * @interface DefsProfileImage
 */
export interface DefsProfileImage {
    /**
     * 
     * @type {string}
     * @memberof DefsProfileImage
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsProfileImage
     */
    'id'?: string;
    /**
     * 
     * @type {DefsImageInfo}
     * @memberof DefsProfileImage
     */
    'info'?: DefsImageInfo;
    /**
     * 
     * @type {string}
     * @memberof DefsProfileImage
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsProfileImage
     */
    'updated_at'?: string;
}
/**
 * 
 * @export
 * @interface DefsProfileImageData
 */
export interface DefsProfileImageData {
    /**
     * 
     * @type {string}
     * @memberof DefsProfileImageData
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsProfileImageData
     */
    'id'?: string;
    /**
     * 
     * @type {ModelsImageInfo}
     * @memberof DefsProfileImageData
     */
    'info'?: ModelsImageInfo;
    /**
     * 
     * @type {ModelsStatusType}
     * @memberof DefsProfileImageData
     */
    'status'?: ModelsStatusType;
    /**
     * 
     * @type {string}
     * @memberof DefsProfileImageData
     */
    'updated_at'?: string;
}


/**
 * 
 * @export
 * @interface DefsProfileImageResponse
 */
export interface DefsProfileImageResponse {
    /**
     * 
     * @type {Array<DefsProfileImageData>}
     * @memberof DefsProfileImageResponse
     */
    'data'?: Array<DefsProfileImageData>;
}
/**
 * 
 * @export
 * @interface DefsProfileListResponse
 */
export interface DefsProfileListResponse {
    /**
     * 
     * @type {Array<DefsUserProfileData>}
     * @memberof DefsProfileListResponse
     */
    'data'?: Array<DefsUserProfileData>;
    /**
     * 
     * @type {number}
     * @memberof DefsProfileListResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DefsProvider = {
    Google: 'google',
    Apple: 'apple',
    Facebook: 'facebook'
} as const;

export type DefsProvider = typeof DefsProvider[keyof typeof DefsProvider];


/**
 * 
 * @export
 * @interface DefsQuestionLisResponse
 */
export interface DefsQuestionLisResponse {
    /**
     * 
     * @type {DefsQuestionsData}
     * @memberof DefsQuestionLisResponse
     */
    'data'?: DefsQuestionsData;
}
/**
 * 
 * @export
 * @interface DefsQuestionPictureInfo
 */
export interface DefsQuestionPictureInfo {
    /**
     * 
     * @type {string}
     * @memberof DefsQuestionPictureInfo
     */
    'content_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsQuestionPictureInfo
     */
    'md5'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsQuestionPictureInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsQuestionPictureInfo
     */
    'signed_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsQuestionPictureInfo
     */
    'size'?: number;
}
/**
 * 
 * @export
 * @interface DefsQuestionPicturesData
 */
export interface DefsQuestionPicturesData {
    /**
     * 
     * @type {ModelsImageInfo}
     * @memberof DefsQuestionPicturesData
     */
    'picture_one'?: ModelsImageInfo;
    /**
     * 
     * @type {ModelsImageInfo}
     * @memberof DefsQuestionPicturesData
     */
    'picture_two'?: ModelsImageInfo;
}
/**
 * 
 * @export
 * @interface DefsQuestionPicturesResponse
 */
export interface DefsQuestionPicturesResponse {
    /**
     * 
     * @type {DefsQuestionPicturesData}
     * @memberof DefsQuestionPicturesResponse
     */
    'data'?: DefsQuestionPicturesData;
}
/**
 * 
 * @export
 * @interface DefsQuestionReq
 */
export interface DefsQuestionReq {
    /**
     * Dimension of the question. Required: true
     * @type {string}
     * @memberof DefsQuestionReq
     */
    'dimension': DefsQuestionReqDimensionEnum;
    /**
     * Direction of the question. Can only be 1 or -1. Required: true Enum: 1, -1
     * @type {number}
     * @memberof DefsQuestionReq
     */
    'direction': number;
    /**
     * ID of the question.
     * @type {string}
     * @memberof DefsQuestionReq
     */
    'id'?: string;
    /**
     * Meaning of the question. Only \"I\", \"E\", \"N\", \"S\", \"T\", \"F\", \"J\", \"P\" are allowed. Required: true Enum: I, E, N, S, T, F, J, P
     * @type {string}
     * @memberof DefsQuestionReq
     */
    'meaning': DefsQuestionReqMeaningEnum;
    /**
     * The text of the question. Minimum: 1 Maximum: 255 Required: true
     * @type {string}
     * @memberof DefsQuestionReq
     */
    'question': string;
    /**
     * Status of the question. Only \"pending\" \"success\" and \"error\" are allowed.
     * @type {ModelsStatusType}
     * @memberof DefsQuestionReq
     */
    'status'?: ModelsStatusType;
    /**
     * System type of the question. Only \"image\" and \"text\" are allowed. Required: true Enum: image, text
     * @type {ModelsQuestionSysType}
     * @memberof DefsQuestionReq
     */
    'systemType': ModelsQuestionSysType;
    /**
     * Type of the question. Only \"mbti\" and \"attachment\" are allowed. Required: true Enum: mbti, attachment
     * @type {ModelsTestType}
     * @memberof DefsQuestionReq
     */
    'type': ModelsTestType;
}

export const DefsQuestionReqDimensionEnum = {
    Secure: 'Secure',
    Anxious: 'Anxious',
    Fearful: 'Fearful',
    Avoidant: 'Avoidant',
    Ei: 'EI',
    Sn: 'SN',
    Tf: 'TF',
    Jp: 'JP'
} as const;

export type DefsQuestionReqDimensionEnum = typeof DefsQuestionReqDimensionEnum[keyof typeof DefsQuestionReqDimensionEnum];
export const DefsQuestionReqMeaningEnum = {
    I: 'I',
    E: 'E',
    N: 'N',
    S: 'S',
    T: 'T',
    F: 'F',
    J: 'J',
    P: 'P'
} as const;

export type DefsQuestionReqMeaningEnum = typeof DefsQuestionReqMeaningEnum[keyof typeof DefsQuestionReqMeaningEnum];

/**
 * 
 * @export
 * @interface DefsQuestionResp
 */
export interface DefsQuestionResp {
    /**
     * 
     * @type {string}
     * @memberof DefsQuestionResp
     */
    'dimension'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsQuestionResp
     */
    'direction'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefsQuestionResp
     */
    'id'?: string;
    /**
     * 
     * @type {ModelsImageInfo}
     * @memberof DefsQuestionResp
     */
    'picture_one'?: ModelsImageInfo;
    /**
     * 
     * @type {ModelsImageInfo}
     * @memberof DefsQuestionResp
     */
    'picture_two'?: ModelsImageInfo;
    /**
     * 
     * @type {string}
     * @memberof DefsQuestionResp
     */
    'question'?: string;
    /**
     * 
     * @type {ModelsStatusType}
     * @memberof DefsQuestionResp
     */
    'status'?: ModelsStatusType;
    /**
     * 
     * @type {ModelsQuestionSysType}
     * @memberof DefsQuestionResp
     */
    'system_type'?: ModelsQuestionSysType;
    /**
     * 
     * @type {ModelsTestType}
     * @memberof DefsQuestionResp
     */
    'type'?: ModelsTestType;
}


/**
 * 
 * @export
 * @interface DefsQuestionResponse
 */
export interface DefsQuestionResponse {
    /**
     * 
     * @type {DefsQuestionResp}
     * @memberof DefsQuestionResponse
     */
    'data'?: DefsQuestionResp;
}
/**
 * 
 * @export
 * @interface DefsQuestionsData
 */
export interface DefsQuestionsData {
    /**
     * 
     * @type {Array<DefsAttachmentQuestion>}
     * @memberof DefsQuestionsData
     */
    'attachment': Array<DefsAttachmentQuestion>;
    /**
     * 
     * @type {Array<DefsMBTIQuestion>}
     * @memberof DefsQuestionsData
     */
    'mbti': Array<DefsMBTIQuestion>;
}
/**
 * 
 * @export
 * @interface DefsRaceData
 */
export interface DefsRaceData {
    /**
     * 
     * @type {string}
     * @memberof DefsRaceData
     */
    'ethnicity': string;
    /**
     * 
     * @type {string}
     * @memberof DefsRaceData
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface DefsRaceListResponse
 */
export interface DefsRaceListResponse {
    /**
     * 
     * @type {Array<DefsRaceData>}
     * @memberof DefsRaceListResponse
     */
    'data'?: Array<DefsRaceData>;
}
/**
 * 
 * @export
 * @interface DefsRaceResponse
 */
export interface DefsRaceResponse {
    /**
     * 
     * @type {DefsRaceData}
     * @memberof DefsRaceResponse
     */
    'data'?: DefsRaceData;
}
/**
 * 
 * @export
 * @interface DefsReason
 */
export interface DefsReason {
    /**
     * 
     * @type {string}
     * @memberof DefsReason
     */
    'details'?: string;
    /**
     * 
     * @type {ModelsReasonType}
     * @memberof DefsReason
     */
    'type': ModelsReasonType;
}


/**
 * 
 * @export
 * @interface DefsRegisterReq
 */
export interface DefsRegisterReq {
    /**
     * 
     * @type {string}
     * @memberof DefsRegisterReq
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DefsRegisterReq
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface DefsRevealPhotoRequest
 */
export interface DefsRevealPhotoRequest {
    /**
     * 
     * @type {string}
     * @memberof DefsRevealPhotoRequest
     */
    'photo_id': string;
}
/**
 * 
 * @export
 * @interface DefsRevealPhotoResponse
 */
export interface DefsRevealPhotoResponse {
    /**
     * 
     * @type {string}
     * @memberof DefsRevealPhotoResponse
     */
    'message'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsRevealPhotoResponse
     */
    'remaining_views'?: number;
    /**
     * 
     * @type {DefsRevealedPhoto}
     * @memberof DefsRevealPhotoResponse
     */
    'revealed_photo'?: DefsRevealedPhoto;
    /**
     * 
     * @type {boolean}
     * @memberof DefsRevealPhotoResponse
     */
    'success'?: boolean;
}
/**
 * 
 * @export
 * @interface DefsRevealedPhoto
 */
export interface DefsRevealedPhoto {
    /**
     * 
     * @type {string}
     * @memberof DefsRevealedPhoto
     */
    'photo_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsRevealedPhoto
     */
    'revealed_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsRevealedPhoto
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface DefsScore
 */
export interface DefsScore {
    /**
     * 
     * @type {string}
     * @memberof DefsScore
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsScore
     */
    's'?: number;
}
/**
 * 
 * @export
 * @interface DefsSendInvitesRequest
 */
export interface DefsSendInvitesRequest {
    /**
     * UserName string           `json:\"user_name\" binding:\"required\"`
     * @type {Array<ModelsContact>}
     * @memberof DefsSendInvitesRequest
     */
    'contacts': Array<ModelsContact>;
}
/**
 * 
 * @export
 * @interface DefsSendInvitesResponse
 */
export interface DefsSendInvitesResponse {
    /**
     * 
     * @type {number}
     * @memberof DefsSendInvitesResponse
     */
    'contacts_added'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefsSendInvitesResponse
     */
    'invite_link'?: string;
}
/**
 * 
 * @export
 * @interface DefsSexualOrientationData
 */
export interface DefsSexualOrientationData {
    /**
     * 
     * @type {string}
     * @memberof DefsSexualOrientationData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsSexualOrientationData
     */
    'orientation': string;
}
/**
 * 
 * @export
 * @interface DefsSexualOrientationListResponse
 */
export interface DefsSexualOrientationListResponse {
    /**
     * 
     * @type {Array<DefsSexualOrientationData>}
     * @memberof DefsSexualOrientationListResponse
     */
    'data'?: Array<DefsSexualOrientationData>;
}
/**
 * 
 * @export
 * @interface DefsSexualOrientationResponse
 */
export interface DefsSexualOrientationResponse {
    /**
     * 
     * @type {DefsSexualOrientationData}
     * @memberof DefsSexualOrientationResponse
     */
    'data'?: DefsSexualOrientationData;
}
/**
 * 
 * @export
 * @interface DefsSimpleMatchData
 */
export interface DefsSimpleMatchData {
    /**
     * 
     * @type {number}
     * @memberof DefsSimpleMatchData
     */
    'average_score'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefsSimpleMatchData
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsSimpleMatchData
     */
    'last_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsSimpleMatchData
     */
    'match_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof DefsSimpleMatchData
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface DefsSimpleMatchListResponse
 */
export interface DefsSimpleMatchListResponse {
    /**
     * 
     * @type {Array<DefsSimpleMatchData>}
     * @memberof DefsSimpleMatchListResponse
     */
    'data'?: Array<DefsSimpleMatchData>;
    /**
     * 
     * @type {number}
     * @memberof DefsSimpleMatchListResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DefsTestAnswerData
 */
export interface DefsTestAnswerData {
    /**
     * 
     * @type {Array<DefsAttachmentAnswerData>}
     * @memberof DefsTestAnswerData
     */
    'attachment'?: Array<DefsAttachmentAnswerData>;
    /**
     * 
     * @type {string}
     * @memberof DefsTestAnswerData
     */
    'id'?: string;
    /**
     * 
     * @type {Array<DefsMBTIAnswerData>}
     * @memberof DefsTestAnswerData
     */
    'mbti'?: Array<DefsMBTIAnswerData>;
    /**
     * 
     * @type {string}
     * @memberof DefsTestAnswerData
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface DefsTestAnswerResponse
 */
export interface DefsTestAnswerResponse {
    /**
     * 
     * @type {DefsTestAnswerData}
     * @memberof DefsTestAnswerResponse
     */
    'data'?: DefsTestAnswerData;
}
/**
 * 
 * @export
 * @interface DefsTestTypeResponse
 */
export interface DefsTestTypeResponse {
    /**
     * 
     * @type {number}
     * @memberof DefsTestTypeResponse
     */
    'code'?: number;
    /**
     * 
     * @type {EtestType}
     * @memberof DefsTestTypeResponse
     */
    'data'?: EtestType;
    /**
     * 
     * @type {DefsHTTPError}
     * @memberof DefsTestTypeResponse
     */
    'error'?: DefsHTTPError;
    /**
     * 
     * @type {string}
     * @memberof DefsTestTypeResponse
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface DefsThirdPartyLoginReq
 */
export interface DefsThirdPartyLoginReq {
    /**
     * 
     * @type {string}
     * @memberof DefsThirdPartyLoginReq
     */
    'email': string;
    /**
     * 
     * @type {DefsProvider}
     * @memberof DefsThirdPartyLoginReq
     */
    'provider': DefsProvider;
    /**
     * 
     * @type {string}
     * @memberof DefsThirdPartyLoginReq
     */
    'token': string;
}


/**
 * 
 * @export
 * @interface DefsThirdPartyLoginResponse
 */
export interface DefsThirdPartyLoginResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DefsThirdPartyLoginResponse
     */
    'suspended'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsThirdPartyLoginResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface DefsThirdPartyRegisterReq
 */
export interface DefsThirdPartyRegisterReq {
    /**
     * 
     * @type {string}
     * @memberof DefsThirdPartyRegisterReq
     */
    'email': string;
    /**
     * 
     * @type {DefsProvider}
     * @memberof DefsThirdPartyRegisterReq
     */
    'provider': DefsProvider;
    /**
     * 
     * @type {string}
     * @memberof DefsThirdPartyRegisterReq
     */
    'token': string;
}


/**
 * 
 * @export
 * @interface DefsThirdPartyRegisterResponse
 */
export interface DefsThirdPartyRegisterResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DefsThirdPartyRegisterResponse
     */
    'suspended'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsThirdPartyRegisterResponse
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface DefsUpdatePasswordRequest
 */
export interface DefsUpdatePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof DefsUpdatePasswordRequest
     */
    'new_password': string;
}
/**
 * 
 * @export
 * @interface DefsUpdateSuspendRequest
 */
export interface DefsUpdateSuspendRequest {
    /**
     * 
     * @type {boolean}
     * @memberof DefsUpdateSuspendRequest
     */
    'suspended'?: boolean;
}
/**
 * 
 * @export
 * @interface DefsUserActiveData
 */
export interface DefsUserActiveData {
    /**
     * Attachment is the attachment of the user
     * @type {string}
     * @memberof DefsUserActiveData
     */
    'attachment'?: string;
    /**
     * CreatedAt is the creation time of the user
     * @type {string}
     * @memberof DefsUserActiveData
     */
    'created_at'?: string;
    /**
     * Email is the email of the user
     * @type {string}
     * @memberof DefsUserActiveData
     */
    'email': string;
    /**
     * FirstName is the first name of the user
     * @type {string}
     * @memberof DefsUserActiveData
     */
    'first_name'?: string;
    /**
     * ID is the unique identifier for the user
     * @type {string}
     * @memberof DefsUserActiveData
     */
    'id'?: string;
    /**
     * IsOnline is the online status of the user
     * @type {boolean}
     * @memberof DefsUserActiveData
     */
    'is_online'?: boolean;
    /**
     * LastLocation user last location
     * @type {DefsLastLocation}
     * @memberof DefsUserActiveData
     */
    'last_location'?: DefsLastLocation;
    /**
     * LastName is the last name of the user
     * @type {string}
     * @memberof DefsUserActiveData
     */
    'last_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsUserActiveData
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsUserActiveData
     */
    'longitude'?: number;
    /**
     * MBTI is the MBTI of the user
     * @type {string}
     * @memberof DefsUserActiveData
     */
    'mbti'?: string;
    /**
     * Phone is the phone number of the user
     * @type {string}
     * @memberof DefsUserActiveData
     */
    'phone'?: string;
    /**
     * ProfileImage is the profile image of the user
     * @type {Array<DefsProfileImage>}
     * @memberof DefsUserActiveData
     */
    'profile_image'?: Array<DefsProfileImage>;
    /**
     * Suspended is the suspended status of the user
     * @type {boolean}
     * @memberof DefsUserActiveData
     */
    'suspended'?: boolean;
    /**
     * Status user status possible values: Regular(500) Pro(501), Contributor(502), Power(503), ReferralLeader(504)
     * @type {ModelsUserStatus}
     * @memberof DefsUserActiveData
     */
    'user_status'?: ModelsUserStatus;
}


/**
 * 
 * @export
 * @interface DefsUserActiveListResponse
 */
export interface DefsUserActiveListResponse {
    /**
     * 
     * @type {Array<DefsUserActiveData>}
     * @memberof DefsUserActiveListResponse
     */
    'data'?: Array<DefsUserActiveData>;
    /**
     * 
     * @type {number}
     * @memberof DefsUserActiveListResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DefsUserActivityData
 */
export interface DefsUserActivityData {
    /**
     * Attachment is the attachment of the user
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'attachment'?: string;
    /**
     * CreatedAt is the creation time of the user
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'created_at'?: string;
    /**
     * Email is the email of the user
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'email': string;
    /**
     * FirstName is the first name of the user
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'first_name'?: string;
    /**
     * ID is the unique identifier for the user
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'id'?: string;
    /**
     * IsOnline is the online status of the user
     * @type {boolean}
     * @memberof DefsUserActivityData
     */
    'is_online'?: boolean;
    /**
     * LastLocation user last location
     * @type {DefsLastLocation}
     * @memberof DefsUserActivityData
     */
    'last_location'?: DefsLastLocation;
    /**
     * LastName is the last name of the user
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'last_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsUserActivityData
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsUserActivityData
     */
    'longitude'?: number;
    /**
     * MBTI is the MBTI of the user
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'mbti'?: string;
    /**
     * Phone is the phone number of the user
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'phone'?: string;
    /**
     * ProfileImage is the profile image of the user
     * @type {Array<DefsProfileImage>}
     * @memberof DefsUserActivityData
     */
    'profile_image'?: Array<DefsProfileImage>;
    /**
     * Suspended is the suspended status of the user
     * @type {boolean}
     * @memberof DefsUserActivityData
     */
    'suspended'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsUserActivityData
     */
    'timestamp'?: string;
    /**
     * Status user status possible values: Regular(500) Pro(501), Contributor(502), Power(503), ReferralLeader(504)
     * @type {ModelsUserStatus}
     * @memberof DefsUserActivityData
     */
    'user_status'?: ModelsUserStatus;
}


/**
 * 
 * @export
 * @interface DefsUserActivityListResponse
 */
export interface DefsUserActivityListResponse {
    /**
     * 
     * @type {Array<DefsUserActivityData>}
     * @memberof DefsUserActivityListResponse
     */
    'data'?: Array<DefsUserActivityData>;
    /**
     * 
     * @type {number}
     * @memberof DefsUserActivityListResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DefsUserAnswersReq
 */
export interface DefsUserAnswersReq {
    /**
     * 
     * @type {Array<DefsAttachmentAnswerData>}
     * @memberof DefsUserAnswersReq
     */
    'attachment'?: Array<DefsAttachmentAnswerData>;
    /**
     * 
     * @type {Array<DefsMBTIAnswerData>}
     * @memberof DefsUserAnswersReq
     */
    'mbti'?: Array<DefsMBTIAnswerData>;
    /**
     * 
     * @type {string}
     * @memberof DefsUserAnswersReq
     */
    'user_id': string;
}
/**
 * 
 * @export
 * @interface DefsUserData
 */
export interface DefsUserData {
    /**
     * Attachment is the attachment of the user
     * @type {string}
     * @memberof DefsUserData
     */
    'attachment'?: string;
    /**
     * CreatedAt is the creation time of the user
     * @type {string}
     * @memberof DefsUserData
     */
    'created_at'?: string;
    /**
     * Email is the email of the user
     * @type {string}
     * @memberof DefsUserData
     */
    'email': string;
    /**
     * FirstName is the first name of the user
     * @type {string}
     * @memberof DefsUserData
     */
    'first_name'?: string;
    /**
     * ID is the unique identifier for the user
     * @type {string}
     * @memberof DefsUserData
     */
    'id'?: string;
    /**
     * IsOnline is the online status of the user
     * @type {boolean}
     * @memberof DefsUserData
     */
    'is_online'?: boolean;
    /**
     * LastLocation user last location
     * @type {DefsLastLocation}
     * @memberof DefsUserData
     */
    'last_location'?: DefsLastLocation;
    /**
     * LastName is the last name of the user
     * @type {string}
     * @memberof DefsUserData
     */
    'last_name'?: string;
    /**
     * MBTI is the MBTI of the user
     * @type {string}
     * @memberof DefsUserData
     */
    'mbti'?: string;
    /**
     * Phone is the phone number of the user
     * @type {string}
     * @memberof DefsUserData
     */
    'phone'?: string;
    /**
     * ProfileImage is the profile image of the user
     * @type {Array<DefsProfileImage>}
     * @memberof DefsUserData
     */
    'profile_image'?: Array<DefsProfileImage>;
    /**
     * Suspended is the suspended status of the user
     * @type {boolean}
     * @memberof DefsUserData
     */
    'suspended'?: boolean;
    /**
     * Status user status possible values: Regular(500) Pro(501), Contributor(502), Power(503), ReferralLeader(504)
     * @type {ModelsUserStatus}
     * @memberof DefsUserData
     */
    'user_status'?: ModelsUserStatus;
}


/**
 * 
 * @export
 * @interface DefsUserListResponse
 */
export interface DefsUserListResponse {
    /**
     * 
     * @type {Array<DefsUserData>}
     * @memberof DefsUserListResponse
     */
    'data'?: Array<DefsUserData>;
    /**
     * 
     * @type {number}
     * @memberof DefsUserListResponse
     */
    'total'?: number;
}
/**
 * 
 * @export
 * @interface DefsUserLocationData
 */
export interface DefsUserLocationData {
    /**
     * 
     * @type {number}
     * @memberof DefsUserLocationData
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsUserLocationData
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface DefsUserLocationResponse
 */
export interface DefsUserLocationResponse {
    /**
     * 
     * @type {DefsUserLocationData}
     * @memberof DefsUserLocationResponse
     */
    'data'?: DefsUserLocationData;
}
/**
 * 
 * @export
 * @interface DefsUserPreferencesData
 */
export interface DefsUserPreferencesData {
    /**
     * 
     * @type {ModelsAgePref}
     * @memberof DefsUserPreferencesData
     */
    'age': ModelsAgePref;
    /**
     * 
     * @type {ModelsBodyTypePref}
     * @memberof DefsUserPreferencesData
     */
    'body_type'?: ModelsBodyTypePref;
    /**
     * 
     * @type {ModelsChildrenPref}
     * @memberof DefsUserPreferencesData
     */
    'children'?: ModelsChildrenPref;
    /**
     * 
     * @type {ModelsDistance}
     * @memberof DefsUserPreferencesData
     */
    'distance'?: ModelsDistance;
    /**
     * 
     * @type {ModelsDrinkingPref}
     * @memberof DefsUserPreferencesData
     */
    'drinking'?: ModelsDrinkingPref;
    /**
     * 
     * @type {ModelsEducationPref}
     * @memberof DefsUserPreferencesData
     */
    'education'?: ModelsEducationPref;
    /**
     * 
     * @type {ModelsEyeColorPref}
     * @memberof DefsUserPreferencesData
     */
    'eye_color'?: ModelsEyeColorPref;
    /**
     * 
     * @type {ModelsGenderPref}
     * @memberof DefsUserPreferencesData
     */
    'gender'?: ModelsGenderPref;
    /**
     * 
     * @type {ModelsHeightPref}
     * @memberof DefsUserPreferencesData
     */
    'height'?: ModelsHeightPref;
    /**
     * 
     * @type {string}
     * @memberof DefsUserPreferencesData
     */
    'id'?: string;
    /**
     * 
     * @type {ModelsIncomePref}
     * @memberof DefsUserPreferencesData
     */
    'income'?: ModelsIncomePref;
    /**
     * 
     * @type {ModelsPetsPref}
     * @memberof DefsUserPreferencesData
     */
    'pet'?: ModelsPetsPref;
    /**
     * 
     * @type {ModelsProfessionPref}
     * @memberof DefsUserPreferencesData
     */
    'profession'?: ModelsProfessionPref;
    /**
     * 
     * @type {ModelsSmokingPref}
     * @memberof DefsUserPreferencesData
     */
    'smoking'?: ModelsSmokingPref;
    /**
     * 
     * @type {string}
     * @memberof DefsUserPreferencesData
     */
    'user_id': string;
    /**
     * 
     * @type {ModelsZodiacSignPref}
     * @memberof DefsUserPreferencesData
     */
    'zodiac_sign'?: ModelsZodiacSignPref;
}
/**
 * 
 * @export
 * @interface DefsUserPreferencesResponse
 */
export interface DefsUserPreferencesResponse {
    /**
     * 
     * @type {DefsUserPreferencesData}
     * @memberof DefsUserPreferencesResponse
     */
    'data'?: DefsUserPreferencesData;
}
/**
 * 
 * @export
 * @interface DefsUserProfileData
 */
export interface DefsUserProfileData {
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'about_me'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileData
     */
    'age'?: number;
    /**
     * Attachment is the attachment of the user
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'attachment'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'birth_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'body_type_id'?: string;
    /**
     * CreatedAt is the creation time of the user
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'created_at'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileData
     */
    'drinking'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'education_id'?: string;
    /**
     * Email is the email of the user
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'eyes_color_id'?: string;
    /**
     * FirstName is the first name of the user
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'gender_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileData
     */
    'have_children'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileData
     */
    'have_pets'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileData
     */
    'height'?: number;
    /**
     * ID is the unique identifier for the user
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileData
     */
    'income_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileData
     */
    'income_min'?: number;
    /**
     * IsOnline is the online status of the user
     * @type {boolean}
     * @memberof DefsUserProfileData
     */
    'is_online'?: boolean;
    /**
     * LastLocation user last location
     * @type {DefsLastLocation}
     * @memberof DefsUserProfileData
     */
    'last_location'?: DefsLastLocation;
    /**
     * LastName is the last name of the user
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'last_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileData
     */
    'like_pets'?: boolean;
    /**
     * MBTI is the MBTI of the user
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'mbti'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'nickname'?: string;
    /**
     * Phone is the phone number of the user
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'profession_id'?: string;
    /**
     * ProfileImage is the profile image of the user
     * @type {Array<DefsProfileImage>}
     * @memberof DefsUserProfileData
     */
    'profile_image'?: Array<DefsProfileImage>;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'sexual_orientation_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileData
     */
    'smoking'?: boolean;
    /**
     * Suspended is the suspended status of the user
     * @type {boolean}
     * @memberof DefsUserProfileData
     */
    'suspended'?: boolean;
    /**
     * Status user status possible values: Regular(500) Pro(501), Contributor(502), Power(503), ReferralLeader(504)
     * @type {ModelsUserStatus}
     * @memberof DefsUserProfileData
     */
    'user_status'?: ModelsUserStatus;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileData
     */
    'want_children'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileData
     */
    'zodiac_sign'?: string;
}


/**
 * 
 * @export
 * @interface DefsUserProfileReq
 */
export interface DefsUserProfileReq {
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'about_me'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'birth_date': string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'body_type_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileReq
     */
    'drinking'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'education_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'eye_color_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'gender_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileReq
     */
    'have_children'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileReq
     */
    'have_pets'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileReq
     */
    'height'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefsUserProfileReq
     */
    'hobbies'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'id'?: string;
    /**
     * 
     * @type {DefsIncomeRange}
     * @memberof DefsUserProfileReq
     */
    'income'?: DefsIncomeRange;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefsUserProfileReq
     */
    'interests'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileReq
     */
    'like_pets'?: boolean;
    /**
     * 
     * @type {ModelsMetrics}
     * @memberof DefsUserProfileReq
     */
    'metric'?: ModelsMetrics;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'nickname'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefsUserProfileReq
     */
    'pictures'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'profession_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DefsUserProfileReq
     */
    'races'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'sexual_orientation_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileReq
     */
    'smoking'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileReq
     */
    'user_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileReq
     */
    'want_children'?: boolean;
}


/**
 * 
 * @export
 * @interface DefsUserProfileRes
 */
export interface DefsUserProfileRes {
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileRes
     */
    'about_me'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileRes
     */
    'birth_date'?: string;
    /**
     * 
     * @type {DefsBodyTypeData}
     * @memberof DefsUserProfileRes
     */
    'body_type'?: DefsBodyTypeData;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileRes
     */
    'drinking'?: boolean;
    /**
     * 
     * @type {DefsEducationData}
     * @memberof DefsUserProfileRes
     */
    'education'?: DefsEducationData;
    /**
     * 
     * @type {DefsEyesColorData}
     * @memberof DefsUserProfileRes
     */
    'eye_color'?: DefsEyesColorData;
    /**
     * 
     * @type {DefsGenderData}
     * @memberof DefsUserProfileRes
     */
    'gender'?: DefsGenderData;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileRes
     */
    'have_children'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileRes
     */
    'have_pets'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileRes
     */
    'height'?: number;
    /**
     * 
     * @type {Array<DefsHobbyData>}
     * @memberof DefsUserProfileRes
     */
    'hobbies'?: Array<DefsHobbyData>;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileRes
     */
    'id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileRes
     */
    'income_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileRes
     */
    'income_min'?: number;
    /**
     * 
     * @type {Array<DefsInterestsData>}
     * @memberof DefsUserProfileRes
     */
    'interests'?: Array<DefsInterestsData>;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileRes
     */
    'like_pets'?: boolean;
    /**
     * 
     * @type {ModelsMetrics}
     * @memberof DefsUserProfileRes
     */
    'metric'?: ModelsMetrics;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileRes
     */
    'nickname'?: string;
    /**
     * 
     * @type {Array<DefsPicturesRes>}
     * @memberof DefsUserProfileRes
     */
    'pictures'?: Array<DefsPicturesRes>;
    /**
     * 
     * @type {DefsProfessionData}
     * @memberof DefsUserProfileRes
     */
    'profession'?: DefsProfessionData;
    /**
     * 
     * @type {Array<DefsRaceData>}
     * @memberof DefsUserProfileRes
     */
    'races'?: Array<DefsRaceData>;
    /**
     * 
     * @type {number}
     * @memberof DefsUserProfileRes
     */
    'remaining_views'?: number;
    /**
     * 
     * @type {DefsSexualOrientationData}
     * @memberof DefsUserProfileRes
     */
    'sexual_orientation'?: DefsSexualOrientationData;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileRes
     */
    'smoking'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DefsUserProfileRes
     */
    'user_id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserProfileRes
     */
    'want_children'?: boolean;
    /**
     * 
     * @type {ModelsZodiacSign}
     * @memberof DefsUserProfileRes
     */
    'zodiac_sign'?: ModelsZodiacSign;
}


/**
 * 
 * @export
 * @interface DefsUserProfileResponse
 */
export interface DefsUserProfileResponse {
    /**
     * 
     * @type {DefsUserProfileRes}
     * @memberof DefsUserProfileResponse
     */
    'data'?: DefsUserProfileRes;
}
/**
 * 
 * @export
 * @interface DefsUserResponse
 */
export interface DefsUserResponse {
    /**
     * 
     * @type {DefsUserData}
     * @memberof DefsUserResponse
     */
    'data'?: DefsUserData;
}
/**
 * 
 * @export
 * @interface DefsUserScore
 */
export interface DefsUserScore {
    /**
     * Attachment is the attachment of the user
     * @type {string}
     * @memberof DefsUserScore
     */
    'attachment'?: string;
    /**
     * CreatedAt is the creation time of the user
     * @type {string}
     * @memberof DefsUserScore
     */
    'created_at'?: string;
    /**
     * Email is the email of the user
     * @type {string}
     * @memberof DefsUserScore
     */
    'email': string;
    /**
     * FirstName is the first name of the user
     * @type {string}
     * @memberof DefsUserScore
     */
    'first_name'?: string;
    /**
     * ID is the unique identifier for the user
     * @type {string}
     * @memberof DefsUserScore
     */
    'id'?: string;
    /**
     * IsOnline is the online status of the user
     * @type {boolean}
     * @memberof DefsUserScore
     */
    'is_online'?: boolean;
    /**
     * LastLocation user last location
     * @type {DefsLastLocation}
     * @memberof DefsUserScore
     */
    'last_location'?: DefsLastLocation;
    /**
     * LastName is the last name of the user
     * @type {string}
     * @memberof DefsUserScore
     */
    'last_name'?: string;
    /**
     * MBTI is the MBTI of the user
     * @type {string}
     * @memberof DefsUserScore
     */
    'mbti'?: string;
    /**
     * Phone is the phone number of the user
     * @type {string}
     * @memberof DefsUserScore
     */
    'phone'?: string;
    /**
     * ProfileImage is the profile image of the user
     * @type {Array<DefsProfileImage>}
     * @memberof DefsUserScore
     */
    'profile_image'?: Array<DefsProfileImage>;
    /**
     * 
     * @type {number}
     * @memberof DefsUserScore
     */
    'score'?: number;
    /**
     * Suspended is the suspended status of the user
     * @type {boolean}
     * @memberof DefsUserScore
     */
    'suspended'?: boolean;
    /**
     * Status user status possible values: Regular(500) Pro(501), Contributor(502), Power(503), ReferralLeader(504)
     * @type {ModelsUserStatus}
     * @memberof DefsUserScore
     */
    'user_status'?: ModelsUserStatus;
}


/**
 * 
 * @export
 * @interface DefsUserSettingsData
 */
export interface DefsUserSettingsData {
    /**
     * 
     * @type {string}
     * @memberof DefsUserSettingsData
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserSettingsData
     */
    'language'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_age'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_body_type'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_children'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_drinking'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_education'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_eye_color'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_height'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_income'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_location'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_pets'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_profession'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_sexual_orientation'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_smoking'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_status'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DefsUserSettingsData
     */
    'show_zodiac_sign'?: boolean;
    /**
     * 
     * @type {ModelsTheme}
     * @memberof DefsUserSettingsData
     */
    'theme'?: ModelsTheme;
    /**
     * 
     * @type {string}
     * @memberof DefsUserSettingsData
     */
    'user_id': string;
}


/**
 * 
 * @export
 * @interface DefsUserSettingsResponse
 */
export interface DefsUserSettingsResponse {
    /**
     * 
     * @type {DefsUserSettingsData}
     * @memberof DefsUserSettingsResponse
     */
    'data'?: DefsUserSettingsData;
}
/**
 * 
 * @export
 * @interface DefsUserTestResults
 */
export interface DefsUserTestResults {
    /**
     * 
     * @type {string}
     * @memberof DefsUserTestResults
     */
    'attachment_type': string;
    /**
     * 
     * @type {string}
     * @memberof DefsUserTestResults
     */
    'mbti_type': string;
}
/**
 * 
 * @export
 * @interface DefsValidatePinReq
 */
export interface DefsValidatePinReq {
    /**
     * 
     * @type {string}
     * @memberof DefsValidatePinReq
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof DefsValidatePinReq
     */
    'pin': string;
}
/**
 * 
 * @export
 * @interface DefsVerifyApplePurchaseRequest
 */
export interface DefsVerifyApplePurchaseRequest {
    /**
     * 
     * @type {string}
     * @memberof DefsVerifyApplePurchaseRequest
     */
    'product_id': string;
    /**
     * 
     * @type {string}
     * @memberof DefsVerifyApplePurchaseRequest
     */
    'transaction_id': string;
}
/**
 * 
 * @export
 * @interface DefsVerifyGooglePurchaseRequest
 */
export interface DefsVerifyGooglePurchaseRequest {
    /**
     * 
     * @type {string}
     * @memberof DefsVerifyGooglePurchaseRequest
     */
    'package_name': string;
    /**
     * 
     * @type {string}
     * @memberof DefsVerifyGooglePurchaseRequest
     */
    'product_id': string;
    /**
     * 
     * @type {string}
     * @memberof DefsVerifyGooglePurchaseRequest
     */
    'purchase_token': string;
}
/**
 * 
 * @export
 * @interface DefsVerifyPurchaseResponse
 */
export interface DefsVerifyPurchaseResponse {
    /**
     * 
     * @type {ModelsPurchaseApple}
     * @memberof DefsVerifyPurchaseResponse
     */
    'apple_details'?: ModelsPurchaseApple;
    /**
     * 
     * @type {string}
     * @memberof DefsVerifyPurchaseResponse
     */
    'created_at'?: string;
    /**
     * 
     * @type {ModelsPurchaseGoogle}
     * @memberof DefsVerifyPurchaseResponse
     */
    'google_details'?: ModelsPurchaseGoogle;
    /**
     * 
     * @type {string}
     * @memberof DefsVerifyPurchaseResponse
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DefsVerifyPurchaseResponse
     */
    'platform'?: string;
    /**
     * 
     * @type {number}
     * @memberof DefsVerifyPurchaseResponse
     */
    'remaining_views'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DefsVerifyPurchaseResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DefsVerifyPurchaseResponse
     */
    'views_added'?: number;
}
/**
 * 
 * @export
 * @interface EtestType
 */
export interface EtestType {
    /**
     * 
     * @type {string}
     * @memberof EtestType
     */
    'attachmentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof EtestType
     */
    'mbtiType'?: string;
}
/**
 * 
 * @export
 * @interface HandlersBodyTypeTranslationRequest
 */
export interface HandlersBodyTypeTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersBodyTypeTranslationRequest
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface HandlersEducationTranslationRequest
 */
export interface HandlersEducationTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersEducationTranslationRequest
     */
    'degree': string;
    /**
     * 
     * @type {string}
     * @memberof HandlersEducationTranslationRequest
     */
    'education': string;
}
/**
 * 
 * @export
 * @interface HandlersEyesColorTranslationRequest
 */
export interface HandlersEyesColorTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersEyesColorTranslationRequest
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface HandlersGenIDResponse
 */
export interface HandlersGenIDResponse {
    /**
     * 
     * @type {string}
     * @memberof HandlersGenIDResponse
     */
    'uuid'?: string;
}
/**
 * 
 * @export
 * @interface HandlersGenderTranslationRequest
 */
export interface HandlersGenderTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersGenderTranslationRequest
     */
    'gender': string;
}
/**
 * 
 * @export
 * @interface HandlersHobbyTranslationRequest
 */
export interface HandlersHobbyTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersHobbyTranslationRequest
     */
    'hobby': string;
}
/**
 * 
 * @export
 * @interface HandlersInterestTranslationRequest
 */
export interface HandlersInterestTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersInterestTranslationRequest
     */
    'interest': string;
}
/**
 * 
 * @export
 * @interface HandlersProfessionTranslationRequest
 */
export interface HandlersProfessionTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersProfessionTranslationRequest
     */
    'job_title': string;
}
/**
 * 
 * @export
 * @interface HandlersRaceTranslationRequest
 */
export interface HandlersRaceTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersRaceTranslationRequest
     */
    'ethnicity': string;
}
/**
 * 
 * @export
 * @interface HandlersSexualOrientationTranslationRequest
 */
export interface HandlersSexualOrientationTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersSexualOrientationTranslationRequest
     */
    'orientation': string;
}
/**
 * 
 * @export
 * @interface HandlersTranslationRequest
 */
export interface HandlersTranslationRequest {
    /**
     * 
     * @type {string}
     * @memberof HandlersTranslationRequest
     */
    'question': string;
}
/**
 * 
 * @export
 * @interface HandlersUserState
 */
export interface HandlersUserState {
    /**
     * 
     * @type {boolean}
     * @memberof HandlersUserState
     */
    'has_picture_profile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HandlersUserState
     */
    'has_profile'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HandlersUserState
     */
    'has_questionnaire'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof HandlersUserState
     */
    'has_user_preferences'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof HandlersUserState
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface ModelsAgePref
 */
export interface ModelsAgePref {
    /**
     * 
     * @type {number}
     * @memberof ModelsAgePref
     */
    'max-age': number;
    /**
     * 
     * @type {number}
     * @memberof ModelsAgePref
     */
    'min-age': number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsAgePref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsBodyType
 */
export interface ModelsBodyType {
    /**
     * 
     * @type {string}
     * @memberof ModelsBodyType
     */
    'id'?: string;
    /**
     * translations
     * @type {{ [key: string]: ModelsBodyTypeTranslations; }}
     * @memberof ModelsBodyType
     */
    'translations'?: { [key: string]: ModelsBodyTypeTranslations; };
    /**
     * 
     * @type {string}
     * @memberof ModelsBodyType
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ModelsBodyTypePref
 */
export interface ModelsBodyTypePref {
    /**
     * 
     * @type {string}
     * @memberof ModelsBodyTypePref
     */
    'body-type-id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsBodyTypePref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsBodyTypeTranslations
 */
export interface ModelsBodyTypeTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsBodyTypeTranslations
     */
    'type'?: string;
}
/**
 * 
 * @export
 * @interface ModelsChildrenPref
 */
export interface ModelsChildrenPref {
    /**
     * 
     * @type {boolean}
     * @memberof ModelsChildrenPref
     */
    'have-children'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsChildrenPref
     */
    'have-children-strong'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsChildrenPref
     */
    'want-children'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsChildrenPref
     */
    'want-children-strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsContact
 */
export interface ModelsContact {
    /**
     * 
     * @type {string}
     * @memberof ModelsContact
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsContact
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface ModelsDistance
 */
export interface ModelsDistance {
    /**
     * 
     * @type {boolean}
     * @memberof ModelsDistance
     */
    'strong'?: boolean;
    /**
     * 
     * @type {ModelsUnitDistance}
     * @memberof ModelsDistance
     */
    'unit'?: ModelsUnitDistance;
    /**
     * 
     * @type {number}
     * @memberof ModelsDistance
     */
    'value'?: number;
}


/**
 * 
 * @export
 * @interface ModelsDrinkingPref
 */
export interface ModelsDrinkingPref {
    /**
     * 
     * @type {boolean}
     * @memberof ModelsDrinkingPref
     */
    'drinking'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsDrinkingPref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsEducation
 */
export interface ModelsEducation {
    /**
     * 
     * @type {string}
     * @memberof ModelsEducation
     */
    'degree'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsEducation
     */
    'education'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsEducation
     */
    'id'?: string;
    /**
     * translations
     * @type {{ [key: string]: ModelsEducationTranslations; }}
     * @memberof ModelsEducation
     */
    'translations'?: { [key: string]: ModelsEducationTranslations; };
}
/**
 * 
 * @export
 * @interface ModelsEducationPref
 */
export interface ModelsEducationPref {
    /**
     * 
     * @type {string}
     * @memberof ModelsEducationPref
     */
    'education-id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsEducationPref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsEducationTranslations
 */
export interface ModelsEducationTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsEducationTranslations
     */
    'degree'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsEducationTranslations
     */
    'education'?: string;
}
/**
 * 
 * @export
 * @interface ModelsEyeColorPref
 */
export interface ModelsEyeColorPref {
    /**
     * 
     * @type {string}
     * @memberof ModelsEyeColorPref
     */
    'eye-color-id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsEyeColorPref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsEyesColor
 */
export interface ModelsEyesColor {
    /**
     * 
     * @type {string}
     * @memberof ModelsEyesColor
     */
    'color'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsEyesColor
     */
    'id'?: string;
    /**
     * translations
     * @type {{ [key: string]: ModelsEyesColorTranslations; }}
     * @memberof ModelsEyesColor
     */
    'translations'?: { [key: string]: ModelsEyesColorTranslations; };
}
/**
 * 
 * @export
 * @interface ModelsEyesColorTranslations
 */
export interface ModelsEyesColorTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsEyesColorTranslations
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface ModelsGender
 */
export interface ModelsGender {
    /**
     * 
     * @type {string}
     * @memberof ModelsGender
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsGender
     */
    'id'?: string;
    /**
     * 
     * @type {{ [key: string]: ModelsGenderTranslations; }}
     * @memberof ModelsGender
     */
    'translations'?: { [key: string]: ModelsGenderTranslations; };
}
/**
 * 
 * @export
 * @interface ModelsGenderPref
 */
export interface ModelsGenderPref {
    /**
     * 
     * @type {string}
     * @memberof ModelsGenderPref
     */
    'gender-id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsGenderPref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsGenderTranslations
 */
export interface ModelsGenderTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsGenderTranslations
     */
    'gender'?: string;
}
/**
 * 
 * @export
 * @interface ModelsHeightPref
 */
export interface ModelsHeightPref {
    /**
     * 
     * @type {number}
     * @memberof ModelsHeightPref
     */
    'max-height'?: number;
    /**
     * 
     * @type {ModelsMetrics}
     * @memberof ModelsHeightPref
     */
    'metrics'?: ModelsMetrics;
    /**
     * 
     * @type {number}
     * @memberof ModelsHeightPref
     */
    'min-height'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsHeightPref
     */
    'strong'?: boolean;
}


/**
 * 
 * @export
 * @interface ModelsHobby
 */
export interface ModelsHobby {
    /**
     * 
     * @type {string}
     * @memberof ModelsHobby
     */
    'hobby'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsHobby
     */
    'id'?: string;
    /**
     * translations
     * @type {{ [key: string]: ModelsHobbyTranslations; }}
     * @memberof ModelsHobby
     */
    'translations'?: { [key: string]: ModelsHobbyTranslations; };
}
/**
 * 
 * @export
 * @interface ModelsHobbyTranslations
 */
export interface ModelsHobbyTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsHobbyTranslations
     */
    'hobby'?: string;
}
/**
 * 
 * @export
 * @interface ModelsImageInfo
 */
export interface ModelsImageInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'bucket'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'content_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'created'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'deleted'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'md5'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'media_link'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'owner'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'prefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'signed_url'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelsImageInfo
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelsImageInfo
     */
    'updated'?: string;
}
/**
 * 
 * @export
 * @interface ModelsIncomePref
 */
export interface ModelsIncomePref {
    /**
     * 
     * @type {number}
     * @memberof ModelsIncomePref
     */
    'max-income'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelsIncomePref
     */
    'min-income'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsIncomePref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsInterest
 */
export interface ModelsInterest {
    /**
     * 
     * @type {string}
     * @memberof ModelsInterest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsInterest
     */
    'interest'?: string;
    /**
     * 
     * @type {Array<ModelsUserProfile>}
     * @memberof ModelsInterest
     */
    'profiles'?: Array<ModelsUserProfile>;
    /**
     * translations
     * @type {{ [key: string]: ModelsInterestTranslations; }}
     * @memberof ModelsInterest
     */
    'translations'?: { [key: string]: ModelsInterestTranslations; };
}
/**
 * 
 * @export
 * @interface ModelsInterestTranslations
 */
export interface ModelsInterestTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsInterestTranslations
     */
    'interest'?: string;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const ModelsMessageStatusType = {
    MsgAll: 2999,
    MsgSent: 3000,
    MsgReceived: 3001,
    MsgRead: 3002,
    MsgError: 3003
} as const;

export type ModelsMessageStatusType = typeof ModelsMessageStatusType[keyof typeof ModelsMessageStatusType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsMetrics = {
    Centimeters: 'cm',
    Inches: 'in'
} as const;

export type ModelsMetrics = typeof ModelsMetrics[keyof typeof ModelsMetrics];


/**
 * 
 * @export
 * @interface ModelsPermission
 */
export interface ModelsPermission {
    /**
     * 
     * @type {ModelsPermissionType}
     * @memberof ModelsPermission
     */
    'alias'?: ModelsPermissionType;
    /**
     * 
     * @type {string}
     * @memberof ModelsPermission
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsPermission
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsPermission
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ModelsRole>}
     * @memberof ModelsPermission
     */
    'roles'?: Array<ModelsRole>;
    /**
     * 
     * @type {string}
     * @memberof ModelsPermission
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsPermissionType = {
    AllPerm: '*',
    WritePerm: 'w',
    ReadPerm: 'r',
    ReadWritePerm: 'rw'
} as const;

export type ModelsPermissionType = typeof ModelsPermissionType[keyof typeof ModelsPermissionType];


/**
 * 
 * @export
 * @interface ModelsPetsPref
 */
export interface ModelsPetsPref {
    /**
     * 
     * @type {boolean}
     * @memberof ModelsPetsPref
     */
    'have-pets'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsPetsPref
     */
    'have-pets-strong'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsPetsPref
     */
    'like-pets'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsPetsPref
     */
    'like-pets-strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsProfession
 */
export interface ModelsProfession {
    /**
     * 
     * @type {string}
     * @memberof ModelsProfession
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsProfession
     */
    'jobTitle'?: string;
    /**
     * translations
     * @type {{ [key: string]: ModelsProfessionTranslations; }}
     * @memberof ModelsProfession
     */
    'translations'?: { [key: string]: ModelsProfessionTranslations; };
}
/**
 * 
 * @export
 * @interface ModelsProfessionPref
 */
export interface ModelsProfessionPref {
    /**
     * 
     * @type {string}
     * @memberof ModelsProfessionPref
     */
    'profession-id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsProfessionPref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @interface ModelsProfessionTranslations
 */
export interface ModelsProfessionTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsProfessionTranslations
     */
    'job_title'?: string;
}
/**
 * 
 * @export
 * @interface ModelsProfileImage
 */
export interface ModelsProfileImage {
    /**
     * 
     * @type {string}
     * @memberof ModelsProfileImage
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsProfileImage
     */
    'id'?: string;
    /**
     * 
     * @type {ModelsImageInfo}
     * @memberof ModelsProfileImage
     */
    'info'?: ModelsImageInfo;
    /**
     * 
     * @type {Array<ModelsUserProfile>}
     * @memberof ModelsProfileImage
     */
    'profiles'?: Array<ModelsUserProfile>;
    /**
     * 
     * @type {ModelsStatusType}
     * @memberof ModelsProfileImage
     */
    'status'?: ModelsStatusType;
    /**
     * 
     * @type {string}
     * @memberof ModelsProfileImage
     */
    'updatedAt'?: string;
}


/**
 * 
 * @export
 * @interface ModelsPurchaseApple
 */
export interface ModelsPurchaseApple {
    /**
     * 
     * @type {string}
     * @memberof ModelsPurchaseApple
     */
    'product_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsPurchaseApple
     */
    'transaction_id'?: string;
}
/**
 * 
 * @export
 * @interface ModelsPurchaseGoogle
 */
export interface ModelsPurchaseGoogle {
    /**
     * 
     * @type {string}
     * @memberof ModelsPurchaseGoogle
     */
    'package_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsPurchaseGoogle
     */
    'purchase_token'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsQuestionSysType = {
    QuestionSysImage: 'image',
    QuestionSysText: 'text'
} as const;

export type ModelsQuestionSysType = typeof ModelsQuestionSysType[keyof typeof ModelsQuestionSysType];


/**
 * 
 * @export
 * @interface ModelsRace
 */
export interface ModelsRace {
    /**
     * 
     * @type {string}
     * @memberof ModelsRace
     */
    'ethnicity'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsRace
     */
    'id'?: string;
    /**
     * 
     * @type {Array<ModelsUserProfile>}
     * @memberof ModelsRace
     */
    'profiles'?: Array<ModelsUserProfile>;
    /**
     * translations
     * @type {{ [key: string]: ModelsRaceTranslations; }}
     * @memberof ModelsRace
     */
    'translations'?: { [key: string]: ModelsRaceTranslations; };
}
/**
 * 
 * @export
 * @interface ModelsRaceTranslations
 */
export interface ModelsRaceTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsRaceTranslations
     */
    'ethnicity'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsReasonType = {
    ReasonTypeSpam: 'spam',
    ReasonTypeRudeBehaviour: 'rude_behaviour',
    ReasonTypeInappropriate: 'inappropriate',
    ReasonTypeOther: 'report'
} as const;

export type ModelsReasonType = typeof ModelsReasonType[keyof typeof ModelsReasonType];


/**
 * 
 * @export
 * @interface ModelsRole
 */
export interface ModelsRole {
    /**
     * 
     * @type {string}
     * @memberof ModelsRole
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsRole
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsRole
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ModelsPermission>}
     * @memberof ModelsRole
     */
    'permissions'?: Array<ModelsPermission>;
    /**
     * 
     * @type {string}
     * @memberof ModelsRole
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface ModelsSexualOrientation
 */
export interface ModelsSexualOrientation {
    /**
     * 
     * @type {string}
     * @memberof ModelsSexualOrientation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsSexualOrientation
     */
    'orientation'?: string;
    /**
     * translations
     * @type {{ [key: string]: ModelsSexualOrientationTranslations; }}
     * @memberof ModelsSexualOrientation
     */
    'translations'?: { [key: string]: ModelsSexualOrientationTranslations; };
}
/**
 * 
 * @export
 * @interface ModelsSexualOrientationTranslations
 */
export interface ModelsSexualOrientationTranslations {
    /**
     * 
     * @type {string}
     * @memberof ModelsSexualOrientationTranslations
     */
    'orientation'?: string;
}
/**
 * 
 * @export
 * @interface ModelsSmokingPref
 */
export interface ModelsSmokingPref {
    /**
     * 
     * @type {boolean}
     * @memberof ModelsSmokingPref
     */
    'smoking'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsSmokingPref
     */
    'strong'?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsStatusType = {
    Pending: 'pending',
    Success: 'success',
    Error: 'error'
} as const;

export type ModelsStatusType = typeof ModelsStatusType[keyof typeof ModelsStatusType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsTestType = {
    MBTI: 'mbti',
    Attachment: 'attachment'
} as const;

export type ModelsTestType = typeof ModelsTestType[keyof typeof ModelsTestType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsTheme = {
    ThemeWhite: 'white',
    ThemeBlack: 'black'
} as const;

export type ModelsTheme = typeof ModelsTheme[keyof typeof ModelsTheme];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsUnitDistance = {
    UnitDistanceKm: 'km',
    UnitDistanceMiles: 'miles'
} as const;

export type ModelsUnitDistance = typeof ModelsUnitDistance[keyof typeof ModelsUnitDistance];


/**
 * 
 * @export
 * @interface ModelsUser
 */
export interface ModelsUser {
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'attachment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUser
     */
    'isOnline'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'mbti'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'passHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'phone'?: string;
    /**
     * 
     * @type {ModelsRole}
     * @memberof ModelsUser
     */
    'role'?: ModelsRole;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'roleId'?: string;
    /**
     * 
     * @type {ModelsUserStatus}
     * @memberof ModelsUser
     */
    'status'?: ModelsUserStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUser
     */
    'suspended'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelsUser
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ModelsUserType}
     * @memberof ModelsUser
     */
    'userType'?: ModelsUserType;
}


/**
 * 
 * @export
 * @interface ModelsUserGamificationInfo
 */
export interface ModelsUserGamificationInfo {
    /**
     * 
     * @type {number}
     * @memberof ModelsUserGamificationInfo
     */
    'conversation_points'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelsUserGamificationInfo
     */
    'level'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelsUserGamificationInfo
     */
    'message_points'?: number;
}
/**
 * 
 * @export
 * @interface ModelsUserProfile
 */
export interface ModelsUserProfile {
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'aboutMe'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelsUserProfile
     */
    'age'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'birthDate'?: string;
    /**
     * 
     * @type {ModelsBodyType}
     * @memberof ModelsUserProfile
     */
    'bodyType'?: ModelsBodyType;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'bodyTypeID'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'createdAt'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUserProfile
     */
    'drinking'?: boolean;
    /**
     * 
     * @type {ModelsEducation}
     * @memberof ModelsUserProfile
     */
    'education'?: ModelsEducation;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'educationID'?: string;
    /**
     * 
     * @type {ModelsEyesColor}
     * @memberof ModelsUserProfile
     */
    'eyesColor'?: ModelsEyesColor;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'eyesColorID'?: string;
    /**
     * 
     * @type {ModelsGender}
     * @memberof ModelsUserProfile
     */
    'gender'?: ModelsGender;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'genderID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUserProfile
     */
    'haveChildren'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUserProfile
     */
    'havePets'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelsUserProfile
     */
    'height'?: number;
    /**
     * 
     * @type {Array<ModelsHobby>}
     * @memberof ModelsUserProfile
     */
    'hobbies'?: Array<ModelsHobby>;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'id'?: string;
    /**
     * 
     * @type {Array<ModelsProfileImage>}
     * @memberof ModelsUserProfile
     */
    'images'?: Array<ModelsProfileImage>;
    /**
     * 
     * @type {number}
     * @memberof ModelsUserProfile
     */
    'incomeMax'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelsUserProfile
     */
    'incomeMin'?: number;
    /**
     * 
     * @type {Array<ModelsInterest>}
     * @memberof ModelsUserProfile
     */
    'interests'?: Array<ModelsInterest>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUserProfile
     */
    'likePets'?: boolean;
    /**
     * 
     * @type {ModelsMetrics}
     * @memberof ModelsUserProfile
     */
    'metric'?: ModelsMetrics;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'nickname'?: string;
    /**
     * 
     * @type {ModelsProfession}
     * @memberof ModelsUserProfile
     */
    'profession'?: ModelsProfession;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'professionID'?: string;
    /**
     * 
     * @type {Array<ModelsRace>}
     * @memberof ModelsUserProfile
     */
    'races'?: Array<ModelsRace>;
    /**
     * 
     * @type {ModelsSexualOrientation}
     * @memberof ModelsUserProfile
     */
    'sexualOrientation'?: ModelsSexualOrientation;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'sexualOrientationID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUserProfile
     */
    'smoking'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {ModelsUser}
     * @memberof ModelsUserProfile
     */
    'user'?: ModelsUser;
    /**
     * 
     * @type {string}
     * @memberof ModelsUserProfile
     */
    'userId'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelsUserProfile
     */
    'wantChildren'?: boolean;
    /**
     * 
     * @type {ModelsZodiacSign}
     * @memberof ModelsUserProfile
     */
    'zodiacSign'?: ModelsZodiacSign;
}


/**
 * 
 * @export
 * @enum {number}
 */

export const ModelsUserStatus = {
    RegularUS: 500,
    ProUS: 501,
    ContributorUS: 502,
    PowerUS: 503,
    ReferralLeaderUS: 504
} as const;

export type ModelsUserStatus = typeof ModelsUserStatus[keyof typeof ModelsUserStatus];


/**
 * 
 * @export
 * @enum {number}
 */

export const ModelsUserType = {
    AUserType: 200,
    BUserType: 201,
    CUserType: 202,
    DUserType: 203
} as const;

export type ModelsUserType = typeof ModelsUserType[keyof typeof ModelsUserType];


/**
 * 
 * @export
 * @enum {string}
 */

export const ModelsZodiacSign = {
    Aries: 'Aries',
    Taurus: 'Taurus',
    Gemini: 'Gemini',
    Cancer: 'Cancer',
    Leo: 'Leo',
    Virgo: 'Virgo',
    Libra: 'Libra',
    Scorpio: 'Scorpio',
    Sagittarius: 'Sagittarius',
    Capricorn: 'Capricorn',
    Aquarius: 'Aquarius',
    Pisces: 'Pisces',
    Invalid: 'Invalid'
} as const;

export type ModelsZodiacSign = typeof ModelsZodiacSign[keyof typeof ModelsZodiacSign];


/**
 * 
 * @export
 * @interface ModelsZodiacSignPref
 */
export interface ModelsZodiacSignPref {
    /**
     * 
     * @type {boolean}
     * @memberof ModelsZodiacSignPref
     */
    'strong'?: boolean;
    /**
     * 
     * @type {Array<ModelsZodiacSign>}
     * @memberof ModelsZodiacSignPref
     */
    'zodiac-signs'?: Array<ModelsZodiacSign>;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Login a user
         * @summary Login a user
         * @param {DefsLoginReq} defsLoginReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost: async (defsLoginReq: DefsLoginReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsLoginReq' is not null or undefined
            assertParamExists('authLoginPost', 'defsLoginReq', defsLoginReq)
            const localVarPath = `/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsLoginReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate a pin for a phone number
         * @summary Generate a pin for a phone number
         * @param {DefsGenPinReq} defsGenPinReq Generate Pin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPinGenPost: async (defsGenPinReq: DefsGenPinReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsGenPinReq' is not null or undefined
            assertParamExists('authPinGenPost', 'defsGenPinReq', defsGenPinReq)
            const localVarPath = `/auth/pin/gen`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsGenPinReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validate a pin for a phone number
         * @summary Validate a pin for a phone number
         * @param {DefsValidatePinReq} defsValidatePinReq Validate Pin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPinValidatePost: async (defsValidatePinReq: DefsValidatePinReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsValidatePinReq' is not null or undefined
            assertParamExists('authPinValidatePost', 'defsValidatePinReq', defsValidatePinReq)
            const localVarPath = `/auth/pin/validate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsValidatePinReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh token
         * @summary Refresh token
         * @param {string} xAuth x-auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshGet: async (xAuth: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xAuth' is not null or undefined
            assertParamExists('authRefreshGet', 'xAuth', xAuth)
            const localVarPath = `/auth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (xAuth != null) {
                localVarHeaderParameter['x-auth'] = String(xAuth);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register a new user
         * @summary Register a new user
         * @param {DefsRegisterReq} defsRegisterReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterPost: async (defsRegisterReq: DefsRegisterReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsRegisterReq' is not null or undefined
            assertParamExists('authRegisterPost', 'defsRegisterReq', defsRegisterReq)
            const localVarPath = `/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsRegisterReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Third party login
         * @summary Third party login
         * @param {DefsThirdPartyLoginReq} defsThirdPartyLoginReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authThirdPartyLoginPost: async (defsThirdPartyLoginReq: DefsThirdPartyLoginReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsThirdPartyLoginReq' is not null or undefined
            assertParamExists('authThirdPartyLoginPost', 'defsThirdPartyLoginReq', defsThirdPartyLoginReq)
            const localVarPath = `/auth/third-party/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsThirdPartyLoginReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Third party register
         * @summary Third party register
         * @param {DefsThirdPartyRegisterReq} defsThirdPartyRegisterReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authThirdPartyRegisterPost: async (defsThirdPartyRegisterReq: DefsThirdPartyRegisterReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsThirdPartyRegisterReq' is not null or undefined
            assertParamExists('authThirdPartyRegisterPost', 'defsThirdPartyRegisterReq', defsThirdPartyRegisterReq)
            const localVarPath = `/auth/third-party/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsThirdPartyRegisterReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * Login a user
         * @summary Login a user
         * @param {DefsLoginReq} defsLoginReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authLoginPost(defsLoginReq: DefsLoginReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authLoginPost(defsLoginReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Generate a pin for a phone number
         * @summary Generate a pin for a phone number
         * @param {DefsGenPinReq} defsGenPinReq Generate Pin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPinGenPost(defsGenPinReq: DefsGenPinReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsGenPinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPinGenPost(defsGenPinReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authPinGenPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Validate a pin for a phone number
         * @summary Validate a pin for a phone number
         * @param {DefsValidatePinReq} defsValidatePinReq Validate Pin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authPinValidatePost(defsValidatePinReq: DefsValidatePinReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsGenPinResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authPinValidatePost(defsValidatePinReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authPinValidatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Refresh token
         * @summary Refresh token
         * @param {string} xAuth x-auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRefreshGet(xAuth: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRefreshGet(xAuth, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authRefreshGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Register a new user
         * @summary Register a new user
         * @param {DefsRegisterReq} defsRegisterReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authRegisterPost(defsRegisterReq: DefsRegisterReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authRegisterPost(defsRegisterReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Third party login
         * @summary Third party login
         * @param {DefsThirdPartyLoginReq} defsThirdPartyLoginReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authThirdPartyLoginPost(defsThirdPartyLoginReq: DefsThirdPartyLoginReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsThirdPartyLoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authThirdPartyLoginPost(defsThirdPartyLoginReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authThirdPartyLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Third party register
         * @summary Third party register
         * @param {DefsThirdPartyRegisterReq} defsThirdPartyRegisterReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authThirdPartyRegisterPost(defsThirdPartyRegisterReq: DefsThirdPartyRegisterReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsThirdPartyRegisterResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authThirdPartyRegisterPost(defsThirdPartyRegisterReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthApi.authThirdPartyRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * Login a user
         * @summary Login a user
         * @param {DefsLoginReq} defsLoginReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authLoginPost(defsLoginReq: DefsLoginReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsLoginResponse> {
            return localVarFp.authLoginPost(defsLoginReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate a pin for a phone number
         * @summary Generate a pin for a phone number
         * @param {DefsGenPinReq} defsGenPinReq Generate Pin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPinGenPost(defsGenPinReq: DefsGenPinReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsGenPinResponse> {
            return localVarFp.authPinGenPost(defsGenPinReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Validate a pin for a phone number
         * @summary Validate a pin for a phone number
         * @param {DefsValidatePinReq} defsValidatePinReq Validate Pin request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authPinValidatePost(defsValidatePinReq: DefsValidatePinReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsGenPinResponse> {
            return localVarFp.authPinValidatePost(defsValidatePinReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh token
         * @summary Refresh token
         * @param {string} xAuth x-auth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRefreshGet(xAuth: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsLoginResponse> {
            return localVarFp.authRefreshGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * Register a new user
         * @summary Register a new user
         * @param {DefsRegisterReq} defsRegisterReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authRegisterPost(defsRegisterReq: DefsRegisterReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.authRegisterPost(defsRegisterReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Third party login
         * @summary Third party login
         * @param {DefsThirdPartyLoginReq} defsThirdPartyLoginReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authThirdPartyLoginPost(defsThirdPartyLoginReq: DefsThirdPartyLoginReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsThirdPartyLoginResponse> {
            return localVarFp.authThirdPartyLoginPost(defsThirdPartyLoginReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Third party register
         * @summary Third party register
         * @param {DefsThirdPartyRegisterReq} defsThirdPartyRegisterReq Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authThirdPartyRegisterPost(defsThirdPartyRegisterReq: DefsThirdPartyRegisterReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsThirdPartyRegisterResponse> {
            return localVarFp.authThirdPartyRegisterPost(defsThirdPartyRegisterReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * Login a user
     * @summary Login a user
     * @param {DefsLoginReq} defsLoginReq Color
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authLoginPost(defsLoginReq: DefsLoginReq, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authLoginPost(defsLoginReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate a pin for a phone number
     * @summary Generate a pin for a phone number
     * @param {DefsGenPinReq} defsGenPinReq Generate Pin request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPinGenPost(defsGenPinReq: DefsGenPinReq, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authPinGenPost(defsGenPinReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validate a pin for a phone number
     * @summary Validate a pin for a phone number
     * @param {DefsValidatePinReq} defsValidatePinReq Validate Pin request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authPinValidatePost(defsValidatePinReq: DefsValidatePinReq, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authPinValidatePost(defsValidatePinReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refresh token
     * @summary Refresh token
     * @param {string} xAuth x-auth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRefreshGet(xAuth: string, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRefreshGet(xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register a new user
     * @summary Register a new user
     * @param {DefsRegisterReq} defsRegisterReq Color
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authRegisterPost(defsRegisterReq: DefsRegisterReq, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authRegisterPost(defsRegisterReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Third party login
     * @summary Third party login
     * @param {DefsThirdPartyLoginReq} defsThirdPartyLoginReq Color
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authThirdPartyLoginPost(defsThirdPartyLoginReq: DefsThirdPartyLoginReq, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authThirdPartyLoginPost(defsThirdPartyLoginReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Third party register
     * @summary Third party register
     * @param {DefsThirdPartyRegisterReq} defsThirdPartyRegisterReq Color
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authThirdPartyRegisterPost(defsThirdPartyRegisterReq: DefsThirdPartyRegisterReq, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authThirdPartyRegisterPost(defsThirdPartyRegisterReq, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BanApi - axios parameter creator
 * @export
 */
export const BanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete ban by id
         * @summary delete ban
         * @param {string} bID bID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banBIDDelete: async (bID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bID' is not null or undefined
            assertParamExists('banBIDDelete', 'bID', bID)
            const localVarPath = `/ban/{bID}`
                .replace(`{${"bID"}}`, encodeURIComponent(String(bID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get ban by id
         * @summary get ban
         * @param {string} bID bID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banBIDGet: async (bID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bID' is not null or undefined
            assertParamExists('banBIDGet', 'bID', bID)
            const localVarPath = `/ban/{bID}`
                .replace(`{${"bID"}}`, encodeURIComponent(String(bID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update ban
         * @summary save or update ban
         * @param {string} bID bID
         * @param {DefsBanData} defsBanData Ban Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banBIDPut: async (bID: string, defsBanData: DefsBanData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bID' is not null or undefined
            assertParamExists('banBIDPut', 'bID', bID)
            // verify required parameter 'defsBanData' is not null or undefined
            assertParamExists('banBIDPut', 'defsBanData', defsBanData)
            const localVarPath = `/ban/{bID}`
                .replace(`{${"bID"}}`, encodeURIComponent(String(bID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsBanData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get ban list by user id
         * @summary get ban list by user id
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banListUserIDGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('banListUserIDGet', 'userID', userID)
            const localVarPath = `/ban/list/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update ban
         * @summary save or update ban
         * @param {DefsBanData} defsBanData Ban Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banPost: async (defsBanData: DefsBanData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsBanData' is not null or undefined
            assertParamExists('banPost', 'defsBanData', defsBanData)
            const localVarPath = `/ban`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsBanData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BanApi - functional programming interface
 * @export
 */
export const BanApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BanApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete ban by id
         * @summary delete ban
         * @param {string} bID bID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banBIDDelete(bID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banBIDDelete(bID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanApi.banBIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get ban by id
         * @summary get ban
         * @param {string} bID bID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banBIDGet(bID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsBanResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banBIDGet(bID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanApi.banBIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update ban
         * @summary save or update ban
         * @param {string} bID bID
         * @param {DefsBanData} defsBanData Ban Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banBIDPut(bID: string, defsBanData: DefsBanData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banBIDPut(bID, defsBanData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanApi.banBIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get ban list by user id
         * @summary get ban list by user id
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banListUserIDGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsBanListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banListUserIDGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanApi.banListUserIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update ban
         * @summary save or update ban
         * @param {DefsBanData} defsBanData Ban Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async banPost(defsBanData: DefsBanData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.banPost(defsBanData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BanApi.banPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BanApi - factory interface
 * @export
 */
export const BanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BanApiFp(configuration)
    return {
        /**
         * Delete ban by id
         * @summary delete ban
         * @param {string} bID bID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banBIDDelete(bID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.banBIDDelete(bID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get ban by id
         * @summary get ban
         * @param {string} bID bID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banBIDGet(bID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsBanResponse> {
            return localVarFp.banBIDGet(bID, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update ban
         * @summary save or update ban
         * @param {string} bID bID
         * @param {DefsBanData} defsBanData Ban Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banBIDPut(bID: string, defsBanData: DefsBanData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.banBIDPut(bID, defsBanData, options).then((request) => request(axios, basePath));
        },
        /**
         * Get ban list by user id
         * @summary get ban list by user id
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banListUserIDGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsBanListResponse> {
            return localVarFp.banListUserIDGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update ban
         * @summary save or update ban
         * @param {DefsBanData} defsBanData Ban Data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        banPost(defsBanData: DefsBanData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.banPost(defsBanData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BanApi - object-oriented interface
 * @export
 * @class BanApi
 * @extends {BaseAPI}
 */
export class BanApi extends BaseAPI {
    /**
     * Delete ban by id
     * @summary delete ban
     * @param {string} bID bID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanApi
     */
    public banBIDDelete(bID: string, options?: RawAxiosRequestConfig) {
        return BanApiFp(this.configuration).banBIDDelete(bID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get ban by id
     * @summary get ban
     * @param {string} bID bID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanApi
     */
    public banBIDGet(bID: string, options?: RawAxiosRequestConfig) {
        return BanApiFp(this.configuration).banBIDGet(bID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update ban
     * @summary save or update ban
     * @param {string} bID bID
     * @param {DefsBanData} defsBanData Ban Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanApi
     */
    public banBIDPut(bID: string, defsBanData: DefsBanData, options?: RawAxiosRequestConfig) {
        return BanApiFp(this.configuration).banBIDPut(bID, defsBanData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get ban list by user id
     * @summary get ban list by user id
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanApi
     */
    public banListUserIDGet(userID: string, options?: RawAxiosRequestConfig) {
        return BanApiFp(this.configuration).banListUserIDGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update ban
     * @summary save or update ban
     * @param {DefsBanData} defsBanData Ban Data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BanApi
     */
    public banPost(defsBanData: DefsBanData, options?: RawAxiosRequestConfig) {
        return BanApiFp(this.configuration).banPost(defsBanData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BodyTypesApi - axios parameter creator
 * @export
 */
export const BodyTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete body type by id
         * @summary delete body type
         * @param {string} btID btID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesBtIDDelete: async (btID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'btID' is not null or undefined
            assertParamExists('bodyTypesBtIDDelete', 'btID', btID)
            const localVarPath = `/body-types/{btID}`
                .replace(`{${"btID"}}`, encodeURIComponent(String(btID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get body type by id
         * @summary get body type
         * @param {string} btID btID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesBtIDGet: async (btID: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'btID' is not null or undefined
            assertParamExists('bodyTypesBtIDGet', 'btID', btID)
            const localVarPath = `/body-types/{btID}`
                .replace(`{${"btID"}}`, encodeURIComponent(String(btID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update body type
         * @summary save or update body type
         * @param {string} btID btID
         * @param {DefsBodyTypeData} defsBodyTypeData Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesBtIDPut: async (btID: string, defsBodyTypeData: DefsBodyTypeData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'btID' is not null or undefined
            assertParamExists('bodyTypesBtIDPut', 'btID', btID)
            // verify required parameter 'defsBodyTypeData' is not null or undefined
            assertParamExists('bodyTypesBtIDPut', 'defsBodyTypeData', defsBodyTypeData)
            const localVarPath = `/body-types/{btID}`
                .replace(`{${"btID"}}`, encodeURIComponent(String(btID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsBodyTypeData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to body type
         * @param {string} btID Body Type ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersBodyTypeTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesBtIDTranslationLocalePost: async (btID: string, locale: string, translation: HandlersBodyTypeTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'btID' is not null or undefined
            assertParamExists('bodyTypesBtIDTranslationLocalePost', 'btID', btID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('bodyTypesBtIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('bodyTypesBtIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/body-types/{btID}/translation/{locale}`
                .replace(`{${"btID"}}`, encodeURIComponent(String(btID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all body types
         * @summary get all body types
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/body-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update body type
         * @summary save or update body type
         * @param {DefsBodyTypeData} defsBodyTypeData Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesPost: async (defsBodyTypeData: DefsBodyTypeData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsBodyTypeData' is not null or undefined
            assertParamExists('bodyTypesPost', 'defsBodyTypeData', defsBodyTypeData)
            const localVarPath = `/body-types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsBodyTypeData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BodyTypesApi - functional programming interface
 * @export
 */
export const BodyTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BodyTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete body type by id
         * @summary delete body type
         * @param {string} btID btID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bodyTypesBtIDDelete(btID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bodyTypesBtIDDelete(btID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BodyTypesApi.bodyTypesBtIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get body type by id
         * @summary get body type
         * @param {string} btID btID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bodyTypesBtIDGet(btID: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsBodyTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bodyTypesBtIDGet(btID, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BodyTypesApi.bodyTypesBtIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update body type
         * @summary save or update body type
         * @param {string} btID btID
         * @param {DefsBodyTypeData} defsBodyTypeData Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bodyTypesBtIDPut(btID: string, defsBodyTypeData: DefsBodyTypeData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bodyTypesBtIDPut(btID, defsBodyTypeData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BodyTypesApi.bodyTypesBtIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to body type
         * @param {string} btID Body Type ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersBodyTypeTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bodyTypesBtIDTranslationLocalePost(btID: string, locale: string, translation: HandlersBodyTypeTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bodyTypesBtIDTranslationLocalePost(btID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BodyTypesApi.bodyTypesBtIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all body types
         * @summary get all body types
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bodyTypesGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsBodyTypeListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bodyTypesGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BodyTypesApi.bodyTypesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update body type
         * @summary save or update body type
         * @param {DefsBodyTypeData} defsBodyTypeData Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bodyTypesPost(defsBodyTypeData: DefsBodyTypeData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bodyTypesPost(defsBodyTypeData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BodyTypesApi.bodyTypesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BodyTypesApi - factory interface
 * @export
 */
export const BodyTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BodyTypesApiFp(configuration)
    return {
        /**
         * Delete body type by id
         * @summary delete body type
         * @param {string} btID btID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesBtIDDelete(btID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.bodyTypesBtIDDelete(btID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get body type by id
         * @summary get body type
         * @param {string} btID btID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesBtIDGet(btID: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsBodyTypeResponse> {
            return localVarFp.bodyTypesBtIDGet(btID, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update body type
         * @summary save or update body type
         * @param {string} btID btID
         * @param {DefsBodyTypeData} defsBodyTypeData Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesBtIDPut(btID: string, defsBodyTypeData: DefsBodyTypeData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.bodyTypesBtIDPut(btID, defsBodyTypeData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to body type
         * @param {string} btID Body Type ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersBodyTypeTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesBtIDTranslationLocalePost(btID: string, locale: string, translation: HandlersBodyTypeTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.bodyTypesBtIDTranslationLocalePost(btID, locale, translation, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all body types
         * @summary get all body types
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsBodyTypeListResponse> {
            return localVarFp.bodyTypesGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update body type
         * @summary save or update body type
         * @param {DefsBodyTypeData} defsBodyTypeData Type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bodyTypesPost(defsBodyTypeData: DefsBodyTypeData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.bodyTypesPost(defsBodyTypeData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BodyTypesApi - object-oriented interface
 * @export
 * @class BodyTypesApi
 * @extends {BaseAPI}
 */
export class BodyTypesApi extends BaseAPI {
    /**
     * Delete body type by id
     * @summary delete body type
     * @param {string} btID btID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodyTypesApi
     */
    public bodyTypesBtIDDelete(btID: string, options?: RawAxiosRequestConfig) {
        return BodyTypesApiFp(this.configuration).bodyTypesBtIDDelete(btID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get body type by id
     * @summary get body type
     * @param {string} btID btID
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodyTypesApi
     */
    public bodyTypesBtIDGet(btID: string, locale?: string, options?: RawAxiosRequestConfig) {
        return BodyTypesApiFp(this.configuration).bodyTypesBtIDGet(btID, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update body type
     * @summary save or update body type
     * @param {string} btID btID
     * @param {DefsBodyTypeData} defsBodyTypeData Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodyTypesApi
     */
    public bodyTypesBtIDPut(btID: string, defsBodyTypeData: DefsBodyTypeData, options?: RawAxiosRequestConfig) {
        return BodyTypesApiFp(this.configuration).bodyTypesBtIDPut(btID, defsBodyTypeData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to body type
     * @param {string} btID Body Type ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersBodyTypeTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodyTypesApi
     */
    public bodyTypesBtIDTranslationLocalePost(btID: string, locale: string, translation: HandlersBodyTypeTranslationRequest, options?: RawAxiosRequestConfig) {
        return BodyTypesApiFp(this.configuration).bodyTypesBtIDTranslationLocalePost(btID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all body types
     * @summary get all body types
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodyTypesApi
     */
    public bodyTypesGet(locale?: string, options?: RawAxiosRequestConfig) {
        return BodyTypesApiFp(this.configuration).bodyTypesGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update body type
     * @summary save or update body type
     * @param {DefsBodyTypeData} defsBodyTypeData Type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BodyTypesApi
     */
    public bodyTypesPost(defsBodyTypeData: DefsBodyTypeData, options?: RawAxiosRequestConfig) {
        return BodyTypesApiFp(this.configuration).bodyTypesPost(defsBodyTypeData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ChatApi - axios parameter creator
 * @export
 */
export const ChatApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * generate UUID
         * @summary generate UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGenIdGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/chat/gen-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get if success - update messages status to 3001(Received)
         * @summary Get and update chat messages
         * @param {string} currentUserID Current User ID
         * @param {string} chatPartnerID Chat Partner ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAndUpdateChatMessages: async (currentUserID: string, chatPartnerID: string, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currentUserID' is not null or undefined
            assertParamExists('getAndUpdateChatMessages', 'currentUserID', currentUserID)
            // verify required parameter 'chatPartnerID' is not null or undefined
            assertParamExists('getAndUpdateChatMessages', 'chatPartnerID', chatPartnerID)
            const localVarPath = `/chat/messages/update/{currentUserID}/{chatPartnerID}`
                .replace(`{${"currentUserID"}}`, encodeURIComponent(String(currentUserID)))
                .replace(`{${"chatPartnerID"}}`, encodeURIComponent(String(chatPartnerID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chat messages, msgType can be 2999(All), 3000(Sent), 3001(Received), 3002(Read), 3003(Error)
         * @summary Get chat messages
         * @param {string} currentUserID Current User ID
         * @param {string} chatPartnerID Chat Partner ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {GetChatMessagesMsgTypeEnum} [msgType] Message type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatMessages: async (currentUserID: string, chatPartnerID: string, page?: number, pageSize?: number, msgType?: GetChatMessagesMsgTypeEnum, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currentUserID' is not null or undefined
            assertParamExists('getChatMessages', 'currentUserID', currentUserID)
            // verify required parameter 'chatPartnerID' is not null or undefined
            assertParamExists('getChatMessages', 'chatPartnerID', chatPartnerID)
            const localVarPath = `/chat/messages/{currentUserID}/{chatPartnerID}`
                .replace(`{${"currentUserID"}}`, encodeURIComponent(String(currentUserID)))
                .replace(`{${"chatPartnerID"}}`, encodeURIComponent(String(chatPartnerID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (msgType !== undefined) {
                localVarQueryParameter['msgType'] = msgType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chat scores
         * @summary Get chat scores
         * @param {string} chatID Chat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatScores: async (chatID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chatID' is not null or undefined
            assertParamExists('getChatScores', 'chatID', chatID)
            const localVarPath = `/chat/{chatID}/scores`
                .replace(`{${"chatID"}}`, encodeURIComponent(String(chatID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get chat scores for current user and matched user
         * @summary Get chat scores by user ids
         * @param {string} currentUserID Current User ID
         * @param {string} matchedUserID Matched User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatScoresByUserIds: async (currentUserID: string, matchedUserID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'currentUserID' is not null or undefined
            assertParamExists('getChatScoresByUserIds', 'currentUserID', currentUserID)
            // verify required parameter 'matchedUserID' is not null or undefined
            assertParamExists('getChatScoresByUserIds', 'matchedUserID', matchedUserID)
            const localVarPath = `/chat/scores/{currentUserID}/{matchedUserID}`
                .replace(`{${"currentUserID"}}`, encodeURIComponent(String(currentUserID)))
                .replace(`{${"matchedUserID"}}`, encodeURIComponent(String(matchedUserID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user chats
         * @summary Get user chats
         * @param {string} userID User ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserChats: async (userID: string, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('getUserChats', 'userID', userID)
            const localVarPath = `/chat/list/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user paginated chats
         * @summary Get user paginated chats
         * @param {string} userID User ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPaginatedChats: async (userID: string, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('getUserPaginatedChats', 'userID', userID)
            const localVarPath = `/chat/paginated-list/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Establish a websocket connection for user chat
         * @summary User chat websocket
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        websocketChat: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('websocketChat', 'userID', userID)
            const localVarPath = `/ws/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ChatApi - functional programming interface
 * @export
 */
export const ChatApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ChatApiAxiosParamCreator(configuration)
    return {
        /**
         * generate UUID
         * @summary generate UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async chatGenIdGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlersGenIDResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.chatGenIdGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.chatGenIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get if success - update messages status to 3001(Received)
         * @summary Get and update chat messages
         * @param {string} currentUserID Current User ID
         * @param {string} chatPartnerID Chat Partner ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAndUpdateChatMessages(currentUserID: string, chatPartnerID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsChatMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAndUpdateChatMessages(currentUserID, chatPartnerID, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getAndUpdateChatMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get chat messages, msgType can be 2999(All), 3000(Sent), 3001(Received), 3002(Read), 3003(Error)
         * @summary Get chat messages
         * @param {string} currentUserID Current User ID
         * @param {string} chatPartnerID Chat Partner ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {GetChatMessagesMsgTypeEnum} [msgType] Message type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatMessages(currentUserID: string, chatPartnerID: string, page?: number, pageSize?: number, msgType?: GetChatMessagesMsgTypeEnum, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsChatMessageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatMessages(currentUserID, chatPartnerID, page, pageSize, msgType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getChatMessages']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get chat scores
         * @summary Get chat scores
         * @param {string} chatID Chat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatScores(chatID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsChatScoreData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatScores(chatID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getChatScores']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get chat scores for current user and matched user
         * @summary Get chat scores by user ids
         * @param {string} currentUserID Current User ID
         * @param {string} matchedUserID Matched User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatScoresByUserIds(currentUserID: string, matchedUserID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsGetScoresResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatScoresByUserIds(currentUserID, matchedUserID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getChatScoresByUserIds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user chats
         * @summary Get user chats
         * @param {string} userID User ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserChats(userID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DefsChatPartnerData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserChats(userID, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getUserChats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user paginated chats
         * @summary Get user paginated chats
         * @param {string} userID User ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserPaginatedChats(userID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsChatPartnerPaginatedResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPaginatedChats(userID, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.getUserPaginatedChats']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Establish a websocket connection for user chat
         * @summary User chat websocket
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async websocketChat(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.websocketChat(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ChatApi.websocketChat']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ChatApi - factory interface
 * @export
 */
export const ChatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ChatApiFp(configuration)
    return {
        /**
         * generate UUID
         * @summary generate UUID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        chatGenIdGet(options?: RawAxiosRequestConfig): AxiosPromise<HandlersGenIDResponse> {
            return localVarFp.chatGenIdGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get if success - update messages status to 3001(Received)
         * @summary Get and update chat messages
         * @param {string} currentUserID Current User ID
         * @param {string} chatPartnerID Chat Partner ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAndUpdateChatMessages(currentUserID: string, chatPartnerID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsChatMessageResponse> {
            return localVarFp.getAndUpdateChatMessages(currentUserID, chatPartnerID, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Get chat messages, msgType can be 2999(All), 3000(Sent), 3001(Received), 3002(Read), 3003(Error)
         * @summary Get chat messages
         * @param {string} currentUserID Current User ID
         * @param {string} chatPartnerID Chat Partner ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {GetChatMessagesMsgTypeEnum} [msgType] Message type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatMessages(currentUserID: string, chatPartnerID: string, page?: number, pageSize?: number, msgType?: GetChatMessagesMsgTypeEnum, options?: RawAxiosRequestConfig): AxiosPromise<DefsChatMessageResponse> {
            return localVarFp.getChatMessages(currentUserID, chatPartnerID, page, pageSize, msgType, options).then((request) => request(axios, basePath));
        },
        /**
         * Get chat scores
         * @summary Get chat scores
         * @param {string} chatID Chat ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatScores(chatID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsChatScoreData> {
            return localVarFp.getChatScores(chatID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get chat scores for current user and matched user
         * @summary Get chat scores by user ids
         * @param {string} currentUserID Current User ID
         * @param {string} matchedUserID Matched User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatScoresByUserIds(currentUserID: string, matchedUserID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsGetScoresResponse> {
            return localVarFp.getChatScoresByUserIds(currentUserID, matchedUserID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user chats
         * @summary Get user chats
         * @param {string} userID User ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserChats(userID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<DefsChatPartnerData>> {
            return localVarFp.getUserChats(userID, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user paginated chats
         * @summary Get user paginated chats
         * @param {string} userID User ID
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserPaginatedChats(userID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsChatPartnerPaginatedResponse> {
            return localVarFp.getUserPaginatedChats(userID, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Establish a websocket connection for user chat
         * @summary User chat websocket
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        websocketChat(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.websocketChat(userID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ChatApi - object-oriented interface
 * @export
 * @class ChatApi
 * @extends {BaseAPI}
 */
export class ChatApi extends BaseAPI {
    /**
     * generate UUID
     * @summary generate UUID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public chatGenIdGet(options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).chatGenIdGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get if success - update messages status to 3001(Received)
     * @summary Get and update chat messages
     * @param {string} currentUserID Current User ID
     * @param {string} chatPartnerID Chat Partner ID
     * @param {number} [page] Page number for pagination
     * @param {number} [pageSize] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getAndUpdateChatMessages(currentUserID: string, chatPartnerID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getAndUpdateChatMessages(currentUserID, chatPartnerID, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get chat messages, msgType can be 2999(All), 3000(Sent), 3001(Received), 3002(Read), 3003(Error)
     * @summary Get chat messages
     * @param {string} currentUserID Current User ID
     * @param {string} chatPartnerID Chat Partner ID
     * @param {number} [page] Page number for pagination
     * @param {number} [pageSize] Number of items per page
     * @param {GetChatMessagesMsgTypeEnum} [msgType] Message type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatMessages(currentUserID: string, chatPartnerID: string, page?: number, pageSize?: number, msgType?: GetChatMessagesMsgTypeEnum, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatMessages(currentUserID, chatPartnerID, page, pageSize, msgType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get chat scores
     * @summary Get chat scores
     * @param {string} chatID Chat ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatScores(chatID: string, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatScores(chatID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get chat scores for current user and matched user
     * @summary Get chat scores by user ids
     * @param {string} currentUserID Current User ID
     * @param {string} matchedUserID Matched User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getChatScoresByUserIds(currentUserID: string, matchedUserID: string, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getChatScoresByUserIds(currentUserID, matchedUserID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user chats
     * @summary Get user chats
     * @param {string} userID User ID
     * @param {number} [page] Page number for pagination
     * @param {number} [pageSize] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getUserChats(userID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getUserChats(userID, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user paginated chats
     * @summary Get user paginated chats
     * @param {string} userID User ID
     * @param {number} [page] Page number for pagination
     * @param {number} [pageSize] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public getUserPaginatedChats(userID: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).getUserPaginatedChats(userID, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Establish a websocket connection for user chat
     * @summary User chat websocket
     * @param {string} userID User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ChatApi
     */
    public websocketChat(userID: string, options?: RawAxiosRequestConfig) {
        return ChatApiFp(this.configuration).websocketChat(userID, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetChatMessagesMsgTypeEnum = {
    NUMBER_2999: 2999,
    NUMBER_3000: 3000,
    NUMBER_3001: 3001,
    NUMBER_3002: 3002,
    NUMBER_3003: 3003
} as const;
export type GetChatMessagesMsgTypeEnum = typeof GetChatMessagesMsgTypeEnum[keyof typeof GetChatMessagesMsgTypeEnum];


/**
 * CompatibilityApi - axios parameter creator
 * @export
 */
export const CompatibilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Check compatibility
         * @summary check compatibility
         * @param {DefsCompatibilityReq} defsCompatibilityReq CompatibilityReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compatibilityPost: async (defsCompatibilityReq: DefsCompatibilityReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsCompatibilityReq' is not null or undefined
            assertParamExists('compatibilityPost', 'defsCompatibilityReq', defsCompatibilityReq)
            const localVarPath = `/compatibility`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsCompatibilityReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompatibilityApi - functional programming interface
 * @export
 */
export const CompatibilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompatibilityApiAxiosParamCreator(configuration)
    return {
        /**
         * Check compatibility
         * @summary check compatibility
         * @param {DefsCompatibilityReq} defsCompatibilityReq CompatibilityReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async compatibilityPost(defsCompatibilityReq: DefsCompatibilityReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsCompatibilityResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.compatibilityPost(defsCompatibilityReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompatibilityApi.compatibilityPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompatibilityApi - factory interface
 * @export
 */
export const CompatibilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompatibilityApiFp(configuration)
    return {
        /**
         * Check compatibility
         * @summary check compatibility
         * @param {DefsCompatibilityReq} defsCompatibilityReq CompatibilityReq
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        compatibilityPost(defsCompatibilityReq: DefsCompatibilityReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsCompatibilityResponse> {
            return localVarFp.compatibilityPost(defsCompatibilityReq, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompatibilityApi - object-oriented interface
 * @export
 * @class CompatibilityApi
 * @extends {BaseAPI}
 */
export class CompatibilityApi extends BaseAPI {
    /**
     * Check compatibility
     * @summary check compatibility
     * @param {DefsCompatibilityReq} defsCompatibilityReq CompatibilityReq
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompatibilityApi
     */
    public compatibilityPost(defsCompatibilityReq: DefsCompatibilityReq, options?: RawAxiosRequestConfig) {
        return CompatibilityApiFp(this.configuration).compatibilityPost(defsCompatibilityReq, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeviceInfoApi - axios parameter creator
 * @export
 */
export const DeviceInfoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete device info by user id
         * @summary delete device info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfoUserIDDelete: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('deviceInfoUserIDDelete', 'userID', userID)
            const localVarPath = `/device-info/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get device info by user id
         * @summary get device info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfoUserIDGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('deviceInfoUserIDGet', 'userID', userID)
            const localVarPath = `/device-info/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update device info
         * @summary save or update device info
         * @param {string} userID userID
         * @param {DefsDeviceInfoData} defsDeviceInfoData DeviceInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfoUserIDPost: async (userID: string, defsDeviceInfoData: DefsDeviceInfoData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('deviceInfoUserIDPost', 'userID', userID)
            // verify required parameter 'defsDeviceInfoData' is not null or undefined
            assertParamExists('deviceInfoUserIDPost', 'defsDeviceInfoData', defsDeviceInfoData)
            const localVarPath = `/device-info/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsDeviceInfoData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update device info
         * @summary save or update device info
         * @param {string} userID userID
         * @param {DefsDeviceInfoData} defsDeviceInfoData DeviceInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfoUserIDPut: async (userID: string, defsDeviceInfoData: DefsDeviceInfoData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('deviceInfoUserIDPut', 'userID', userID)
            // verify required parameter 'defsDeviceInfoData' is not null or undefined
            assertParamExists('deviceInfoUserIDPut', 'defsDeviceInfoData', defsDeviceInfoData)
            const localVarPath = `/device-info/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsDeviceInfoData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceInfoApi - functional programming interface
 * @export
 */
export const DeviceInfoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceInfoApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete device info by user id
         * @summary delete device info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceInfoUserIDDelete(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceInfoUserIDDelete(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceInfoApi.deviceInfoUserIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get device info by user id
         * @summary get device info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceInfoUserIDGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsDeviceInfoData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceInfoUserIDGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceInfoApi.deviceInfoUserIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update device info
         * @summary save or update device info
         * @param {string} userID userID
         * @param {DefsDeviceInfoData} defsDeviceInfoData DeviceInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceInfoUserIDPost(userID: string, defsDeviceInfoData: DefsDeviceInfoData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceInfoUserIDPost(userID, defsDeviceInfoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceInfoApi.deviceInfoUserIDPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update device info
         * @summary save or update device info
         * @param {string} userID userID
         * @param {DefsDeviceInfoData} defsDeviceInfoData DeviceInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deviceInfoUserIDPut(userID: string, defsDeviceInfoData: DefsDeviceInfoData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deviceInfoUserIDPut(userID, defsDeviceInfoData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeviceInfoApi.deviceInfoUserIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeviceInfoApi - factory interface
 * @export
 */
export const DeviceInfoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceInfoApiFp(configuration)
    return {
        /**
         * Delete device info by user id
         * @summary delete device info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfoUserIDDelete(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.deviceInfoUserIDDelete(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get device info by user id
         * @summary get device info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfoUserIDGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsDeviceInfoData> {
            return localVarFp.deviceInfoUserIDGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update device info
         * @summary save or update device info
         * @param {string} userID userID
         * @param {DefsDeviceInfoData} defsDeviceInfoData DeviceInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfoUserIDPost(userID: string, defsDeviceInfoData: DefsDeviceInfoData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.deviceInfoUserIDPost(userID, defsDeviceInfoData, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update device info
         * @summary save or update device info
         * @param {string} userID userID
         * @param {DefsDeviceInfoData} defsDeviceInfoData DeviceInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deviceInfoUserIDPut(userID: string, defsDeviceInfoData: DefsDeviceInfoData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.deviceInfoUserIDPut(userID, defsDeviceInfoData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceInfoApi - object-oriented interface
 * @export
 * @class DeviceInfoApi
 * @extends {BaseAPI}
 */
export class DeviceInfoApi extends BaseAPI {
    /**
     * Delete device info by user id
     * @summary delete device info
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceInfoApi
     */
    public deviceInfoUserIDDelete(userID: string, options?: RawAxiosRequestConfig) {
        return DeviceInfoApiFp(this.configuration).deviceInfoUserIDDelete(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get device info by user id
     * @summary get device info
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceInfoApi
     */
    public deviceInfoUserIDGet(userID: string, options?: RawAxiosRequestConfig) {
        return DeviceInfoApiFp(this.configuration).deviceInfoUserIDGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update device info
     * @summary save or update device info
     * @param {string} userID userID
     * @param {DefsDeviceInfoData} defsDeviceInfoData DeviceInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceInfoApi
     */
    public deviceInfoUserIDPost(userID: string, defsDeviceInfoData: DefsDeviceInfoData, options?: RawAxiosRequestConfig) {
        return DeviceInfoApiFp(this.configuration).deviceInfoUserIDPost(userID, defsDeviceInfoData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update device info
     * @summary save or update device info
     * @param {string} userID userID
     * @param {DefsDeviceInfoData} defsDeviceInfoData DeviceInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceInfoApi
     */
    public deviceInfoUserIDPut(userID: string, defsDeviceInfoData: DefsDeviceInfoData, options?: RawAxiosRequestConfig) {
        return DeviceInfoApiFp(this.configuration).deviceInfoUserIDPut(userID, defsDeviceInfoData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EducationsApi - axios parameter creator
 * @export
 */
export const EducationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete education by id
         * @summary delete education
         * @param {string} eID eID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsEIDDelete: async (eID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eID' is not null or undefined
            assertParamExists('educationsEIDDelete', 'eID', eID)
            const localVarPath = `/educations/{eID}`
                .replace(`{${"eID"}}`, encodeURIComponent(String(eID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get education by id
         * @summary get education
         * @param {string} eID eID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsEIDGet: async (eID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eID' is not null or undefined
            assertParamExists('educationsEIDGet', 'eID', eID)
            const localVarPath = `/educations/{eID}`
                .replace(`{${"eID"}}`, encodeURIComponent(String(eID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update education
         * @summary save or update education
         * @param {string} eID eID
         * @param {DefsEducationData} defsEducationData Education
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsEIDPut: async (eID: string, defsEducationData: DefsEducationData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eID' is not null or undefined
            assertParamExists('educationsEIDPut', 'eID', eID)
            // verify required parameter 'defsEducationData' is not null or undefined
            assertParamExists('educationsEIDPut', 'defsEducationData', defsEducationData)
            const localVarPath = `/educations/{eID}`
                .replace(`{${"eID"}}`, encodeURIComponent(String(eID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsEducationData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to education
         * @param {string} eID Education ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersEducationTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsEIDTranslationLocalePost: async (eID: string, locale: string, translation: HandlersEducationTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eID' is not null or undefined
            assertParamExists('educationsEIDTranslationLocalePost', 'eID', eID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('educationsEIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('educationsEIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/educations/{eID}/translation/{locale}`
                .replace(`{${"eID"}}`, encodeURIComponent(String(eID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all educations
         * @summary get all educations
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/educations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update education
         * @summary save or update education
         * @param {DefsEducationData} defsEducationData Education
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsPost: async (defsEducationData: DefsEducationData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsEducationData' is not null or undefined
            assertParamExists('educationsPost', 'defsEducationData', defsEducationData)
            const localVarPath = `/educations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsEducationData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EducationsApi - functional programming interface
 * @export
 */
export const EducationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EducationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete education by id
         * @summary delete education
         * @param {string} eID eID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async educationsEIDDelete(eID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.educationsEIDDelete(eID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EducationsApi.educationsEIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get education by id
         * @summary get education
         * @param {string} eID eID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async educationsEIDGet(eID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEducationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.educationsEIDGet(eID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EducationsApi.educationsEIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update education
         * @summary save or update education
         * @param {string} eID eID
         * @param {DefsEducationData} defsEducationData Education
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async educationsEIDPut(eID: string, defsEducationData: DefsEducationData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.educationsEIDPut(eID, defsEducationData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EducationsApi.educationsEIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to education
         * @param {string} eID Education ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersEducationTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async educationsEIDTranslationLocalePost(eID: string, locale: string, translation: HandlersEducationTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.educationsEIDTranslationLocalePost(eID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EducationsApi.educationsEIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all educations
         * @summary get all educations
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async educationsGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEducationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.educationsGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EducationsApi.educationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update education
         * @summary save or update education
         * @param {DefsEducationData} defsEducationData Education
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async educationsPost(defsEducationData: DefsEducationData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.educationsPost(defsEducationData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EducationsApi.educationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EducationsApi - factory interface
 * @export
 */
export const EducationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EducationsApiFp(configuration)
    return {
        /**
         * Delete education by id
         * @summary delete education
         * @param {string} eID eID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsEIDDelete(eID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.educationsEIDDelete(eID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get education by id
         * @summary get education
         * @param {string} eID eID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsEIDGet(eID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEducationResponse> {
            return localVarFp.educationsEIDGet(eID, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update education
         * @summary save or update education
         * @param {string} eID eID
         * @param {DefsEducationData} defsEducationData Education
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsEIDPut(eID: string, defsEducationData: DefsEducationData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.educationsEIDPut(eID, defsEducationData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to education
         * @param {string} eID Education ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersEducationTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsEIDTranslationLocalePost(eID: string, locale: string, translation: HandlersEducationTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.educationsEIDTranslationLocalePost(eID, locale, translation, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all educations
         * @summary get all educations
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEducationListResponse> {
            return localVarFp.educationsGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update education
         * @summary save or update education
         * @param {DefsEducationData} defsEducationData Education
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        educationsPost(defsEducationData: DefsEducationData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.educationsPost(defsEducationData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EducationsApi - object-oriented interface
 * @export
 * @class EducationsApi
 * @extends {BaseAPI}
 */
export class EducationsApi extends BaseAPI {
    /**
     * Delete education by id
     * @summary delete education
     * @param {string} eID eID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationsApi
     */
    public educationsEIDDelete(eID: string, options?: RawAxiosRequestConfig) {
        return EducationsApiFp(this.configuration).educationsEIDDelete(eID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get education by id
     * @summary get education
     * @param {string} eID eID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationsApi
     */
    public educationsEIDGet(eID: string, options?: RawAxiosRequestConfig) {
        return EducationsApiFp(this.configuration).educationsEIDGet(eID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update education
     * @summary save or update education
     * @param {string} eID eID
     * @param {DefsEducationData} defsEducationData Education
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationsApi
     */
    public educationsEIDPut(eID: string, defsEducationData: DefsEducationData, options?: RawAxiosRequestConfig) {
        return EducationsApiFp(this.configuration).educationsEIDPut(eID, defsEducationData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to education
     * @param {string} eID Education ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersEducationTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationsApi
     */
    public educationsEIDTranslationLocalePost(eID: string, locale: string, translation: HandlersEducationTranslationRequest, options?: RawAxiosRequestConfig) {
        return EducationsApiFp(this.configuration).educationsEIDTranslationLocalePost(eID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all educations
     * @summary get all educations
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationsApi
     */
    public educationsGet(locale?: string, options?: RawAxiosRequestConfig) {
        return EducationsApiFp(this.configuration).educationsGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update education
     * @summary save or update education
     * @param {DefsEducationData} defsEducationData Education
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EducationsApi
     */
    public educationsPost(defsEducationData: DefsEducationData, options?: RawAxiosRequestConfig) {
        return EducationsApiFp(this.configuration).educationsPost(defsEducationData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EntitiesApi - axios parameter creator
 * @export
 */
export const EntitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all entities
         * @summary get all entities
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entitiesGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/entities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntitiesApi - functional programming interface
 * @export
 */
export const EntitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all entities
         * @summary get all entities
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async entitiesGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEntitiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.entitiesGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EntitiesApi.entitiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EntitiesApi - factory interface
 * @export
 */
export const EntitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntitiesApiFp(configuration)
    return {
        /**
         * Get all entities
         * @summary get all entities
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        entitiesGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEntitiesResponse> {
            return localVarFp.entitiesGet(locale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntitiesApi - object-oriented interface
 * @export
 * @class EntitiesApi
 * @extends {BaseAPI}
 */
export class EntitiesApi extends BaseAPI {
    /**
     * Get all entities
     * @summary get all entities
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntitiesApi
     */
    public entitiesGet(locale?: string, options?: RawAxiosRequestConfig) {
        return EntitiesApiFp(this.configuration).entitiesGet(locale, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EyeColorsApi - axios parameter creator
 * @export
 */
export const EyeColorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete eye color by id
         * @summary delete eye color
         * @param {string} ecID ecID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyeColorsEcIDDelete: async (ecID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ecID' is not null or undefined
            assertParamExists('eyeColorsEcIDDelete', 'ecID', ecID)
            const localVarPath = `/eye-colors/{ecID}`
                .replace(`{${"ecID"}}`, encodeURIComponent(String(ecID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EyeColorsApi - functional programming interface
 * @export
 */
export const EyeColorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EyeColorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete eye color by id
         * @summary delete eye color
         * @param {string} ecID ecID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eyeColorsEcIDDelete(ecID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eyeColorsEcIDDelete(ecID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EyeColorsApi.eyeColorsEcIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EyeColorsApi - factory interface
 * @export
 */
export const EyeColorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EyeColorsApiFp(configuration)
    return {
        /**
         * Delete eye color by id
         * @summary delete eye color
         * @param {string} ecID ecID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyeColorsEcIDDelete(ecID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.eyeColorsEcIDDelete(ecID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EyeColorsApi - object-oriented interface
 * @export
 * @class EyeColorsApi
 * @extends {BaseAPI}
 */
export class EyeColorsApi extends BaseAPI {
    /**
     * Delete eye color by id
     * @summary delete eye color
     * @param {string} ecID ecID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EyeColorsApi
     */
    public eyeColorsEcIDDelete(ecID: string, options?: RawAxiosRequestConfig) {
        return EyeColorsApiFp(this.configuration).eyeColorsEcIDDelete(ecID, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EyesColorsApi - axios parameter creator
 * @export
 */
export const EyesColorsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get eyes color by id
         * @summary get eyes color
         * @param {string} ecID ecID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsEcIDGet: async (ecID: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ecID' is not null or undefined
            assertParamExists('eyesColorsEcIDGet', 'ecID', ecID)
            const localVarPath = `/eyes-colors/{ecID}`
                .replace(`{${"ecID"}}`, encodeURIComponent(String(ecID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update eyes color
         * @summary save or update eyes color
         * @param {string} ecID ecID
         * @param {DefsEyesColorData} defsEyesColorData Eyes Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsEcIDPut: async (ecID: string, defsEyesColorData: DefsEyesColorData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ecID' is not null or undefined
            assertParamExists('eyesColorsEcIDPut', 'ecID', ecID)
            // verify required parameter 'defsEyesColorData' is not null or undefined
            assertParamExists('eyesColorsEcIDPut', 'defsEyesColorData', defsEyesColorData)
            const localVarPath = `/eyes-colors/{ecID}`
                .replace(`{${"ecID"}}`, encodeURIComponent(String(ecID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsEyesColorData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to eyes color
         * @param {string} ecID Eyes Color ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersEyesColorTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsEcIDTranslationLocalePost: async (ecID: string, locale: string, translation: HandlersEyesColorTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ecID' is not null or undefined
            assertParamExists('eyesColorsEcIDTranslationLocalePost', 'ecID', ecID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('eyesColorsEcIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('eyesColorsEcIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/eyes-colors/{ecID}/translation/{locale}`
                .replace(`{${"ecID"}}`, encodeURIComponent(String(ecID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all eyes colors
         * @summary get all eyes colors
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/eyes-colors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update eyes color
         * @summary save or update eyes color
         * @param {DefsEyesColorData} defsEyesColorData Eyes Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsPost: async (defsEyesColorData: DefsEyesColorData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsEyesColorData' is not null or undefined
            assertParamExists('eyesColorsPost', 'defsEyesColorData', defsEyesColorData)
            const localVarPath = `/eyes-colors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsEyesColorData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EyesColorsApi - functional programming interface
 * @export
 */
export const EyesColorsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EyesColorsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get eyes color by id
         * @summary get eyes color
         * @param {string} ecID ecID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eyesColorsEcIDGet(ecID: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEyesColorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eyesColorsEcIDGet(ecID, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EyesColorsApi.eyesColorsEcIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update eyes color
         * @summary save or update eyes color
         * @param {string} ecID ecID
         * @param {DefsEyesColorData} defsEyesColorData Eyes Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eyesColorsEcIDPut(ecID: string, defsEyesColorData: DefsEyesColorData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eyesColorsEcIDPut(ecID, defsEyesColorData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EyesColorsApi.eyesColorsEcIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to eyes color
         * @param {string} ecID Eyes Color ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersEyesColorTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eyesColorsEcIDTranslationLocalePost(ecID: string, locale: string, translation: HandlersEyesColorTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eyesColorsEcIDTranslationLocalePost(ecID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EyesColorsApi.eyesColorsEcIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all eyes colors
         * @summary get all eyes colors
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eyesColorsGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEyesColorListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eyesColorsGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EyesColorsApi.eyesColorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update eyes color
         * @summary save or update eyes color
         * @param {DefsEyesColorData} defsEyesColorData Eyes Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eyesColorsPost(defsEyesColorData: DefsEyesColorData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eyesColorsPost(defsEyesColorData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EyesColorsApi.eyesColorsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EyesColorsApi - factory interface
 * @export
 */
export const EyesColorsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EyesColorsApiFp(configuration)
    return {
        /**
         * Get eyes color by id
         * @summary get eyes color
         * @param {string} ecID ecID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsEcIDGet(ecID: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEyesColorResponse> {
            return localVarFp.eyesColorsEcIDGet(ecID, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update eyes color
         * @summary save or update eyes color
         * @param {string} ecID ecID
         * @param {DefsEyesColorData} defsEyesColorData Eyes Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsEcIDPut(ecID: string, defsEyesColorData: DefsEyesColorData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.eyesColorsEcIDPut(ecID, defsEyesColorData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to eyes color
         * @param {string} ecID Eyes Color ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersEyesColorTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsEcIDTranslationLocalePost(ecID: string, locale: string, translation: HandlersEyesColorTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.eyesColorsEcIDTranslationLocalePost(ecID, locale, translation, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all eyes colors
         * @summary get all eyes colors
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEyesColorListResponse> {
            return localVarFp.eyesColorsGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update eyes color
         * @summary save or update eyes color
         * @param {DefsEyesColorData} defsEyesColorData Eyes Color
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eyesColorsPost(defsEyesColorData: DefsEyesColorData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.eyesColorsPost(defsEyesColorData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EyesColorsApi - object-oriented interface
 * @export
 * @class EyesColorsApi
 * @extends {BaseAPI}
 */
export class EyesColorsApi extends BaseAPI {
    /**
     * Get eyes color by id
     * @summary get eyes color
     * @param {string} ecID ecID
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EyesColorsApi
     */
    public eyesColorsEcIDGet(ecID: string, locale?: string, options?: RawAxiosRequestConfig) {
        return EyesColorsApiFp(this.configuration).eyesColorsEcIDGet(ecID, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update eyes color
     * @summary save or update eyes color
     * @param {string} ecID ecID
     * @param {DefsEyesColorData} defsEyesColorData Eyes Color
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EyesColorsApi
     */
    public eyesColorsEcIDPut(ecID: string, defsEyesColorData: DefsEyesColorData, options?: RawAxiosRequestConfig) {
        return EyesColorsApiFp(this.configuration).eyesColorsEcIDPut(ecID, defsEyesColorData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to eyes color
     * @param {string} ecID Eyes Color ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersEyesColorTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EyesColorsApi
     */
    public eyesColorsEcIDTranslationLocalePost(ecID: string, locale: string, translation: HandlersEyesColorTranslationRequest, options?: RawAxiosRequestConfig) {
        return EyesColorsApiFp(this.configuration).eyesColorsEcIDTranslationLocalePost(ecID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all eyes colors
     * @summary get all eyes colors
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EyesColorsApi
     */
    public eyesColorsGet(locale?: string, options?: RawAxiosRequestConfig) {
        return EyesColorsApiFp(this.configuration).eyesColorsGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update eyes color
     * @summary save or update eyes color
     * @param {DefsEyesColorData} defsEyesColorData Eyes Color
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EyesColorsApi
     */
    public eyesColorsPost(defsEyesColorData: DefsEyesColorData, options?: RawAxiosRequestConfig) {
        return EyesColorsApiFp(this.configuration).eyesColorsPost(defsEyesColorData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GendersApi - axios parameter creator
 * @export
 */
export const GendersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete gender by id
         * @summary delete gender
         * @param {string} gID gID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGIDDelete: async (gID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gID' is not null or undefined
            assertParamExists('gendersGIDDelete', 'gID', gID)
            const localVarPath = `/genders/{gID}`
                .replace(`{${"gID"}}`, encodeURIComponent(String(gID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get gender by id
         * @summary get gender
         * @param {string} gID gID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGIDGet: async (gID: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gID' is not null or undefined
            assertParamExists('gendersGIDGet', 'gID', gID)
            const localVarPath = `/genders/{gID}`
                .replace(`{${"gID"}}`, encodeURIComponent(String(gID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update gender
         * @summary save or update gender
         * @param {string} gID gID
         * @param {DefsGenderData} defsGenderData Gender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGIDPut: async (gID: string, defsGenderData: DefsGenderData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gID' is not null or undefined
            assertParamExists('gendersGIDPut', 'gID', gID)
            // verify required parameter 'defsGenderData' is not null or undefined
            assertParamExists('gendersGIDPut', 'defsGenderData', defsGenderData)
            const localVarPath = `/genders/{gID}`
                .replace(`{${"gID"}}`, encodeURIComponent(String(gID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsGenderData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to gender
         * @param {string} gID Gender ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersGenderTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGIDTranslationLocalePost: async (gID: string, locale: string, translation: HandlersGenderTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gID' is not null or undefined
            assertParamExists('gendersGIDTranslationLocalePost', 'gID', gID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('gendersGIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('gendersGIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/genders/{gID}/translation/{locale}`
                .replace(`{${"gID"}}`, encodeURIComponent(String(gID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all genders
         * @summary get all genders
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/genders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update gender
         * @summary save or update gender
         * @param {DefsGenderData} defsGenderData Gender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersPost: async (defsGenderData: DefsGenderData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsGenderData' is not null or undefined
            assertParamExists('gendersPost', 'defsGenderData', defsGenderData)
            const localVarPath = `/genders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsGenderData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GendersApi - functional programming interface
 * @export
 */
export const GendersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GendersApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete gender by id
         * @summary delete gender
         * @param {string} gID gID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gendersGIDDelete(gID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gendersGIDDelete(gID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GendersApi.gendersGIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get gender by id
         * @summary get gender
         * @param {string} gID gID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gendersGIDGet(gID: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsGenderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gendersGIDGet(gID, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GendersApi.gendersGIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update gender
         * @summary save or update gender
         * @param {string} gID gID
         * @param {DefsGenderData} defsGenderData Gender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gendersGIDPut(gID: string, defsGenderData: DefsGenderData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gendersGIDPut(gID, defsGenderData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GendersApi.gendersGIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to gender
         * @param {string} gID Gender ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersGenderTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gendersGIDTranslationLocalePost(gID: string, locale: string, translation: HandlersGenderTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gendersGIDTranslationLocalePost(gID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GendersApi.gendersGIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all genders
         * @summary get all genders
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gendersGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsGenderListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gendersGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GendersApi.gendersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update gender
         * @summary save or update gender
         * @param {DefsGenderData} defsGenderData Gender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gendersPost(defsGenderData: DefsGenderData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gendersPost(defsGenderData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GendersApi.gendersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GendersApi - factory interface
 * @export
 */
export const GendersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GendersApiFp(configuration)
    return {
        /**
         * Delete gender by id
         * @summary delete gender
         * @param {string} gID gID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGIDDelete(gID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.gendersGIDDelete(gID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get gender by id
         * @summary get gender
         * @param {string} gID gID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGIDGet(gID: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsGenderResponse> {
            return localVarFp.gendersGIDGet(gID, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update gender
         * @summary save or update gender
         * @param {string} gID gID
         * @param {DefsGenderData} defsGenderData Gender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGIDPut(gID: string, defsGenderData: DefsGenderData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.gendersGIDPut(gID, defsGenderData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to gender
         * @param {string} gID Gender ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersGenderTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGIDTranslationLocalePost(gID: string, locale: string, translation: HandlersGenderTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.gendersGIDTranslationLocalePost(gID, locale, translation, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all genders
         * @summary get all genders
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsGenderListResponse> {
            return localVarFp.gendersGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update gender
         * @summary save or update gender
         * @param {DefsGenderData} defsGenderData Gender
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gendersPost(defsGenderData: DefsGenderData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.gendersPost(defsGenderData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GendersApi - object-oriented interface
 * @export
 * @class GendersApi
 * @extends {BaseAPI}
 */
export class GendersApi extends BaseAPI {
    /**
     * Delete gender by id
     * @summary delete gender
     * @param {string} gID gID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GendersApi
     */
    public gendersGIDDelete(gID: string, options?: RawAxiosRequestConfig) {
        return GendersApiFp(this.configuration).gendersGIDDelete(gID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get gender by id
     * @summary get gender
     * @param {string} gID gID
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GendersApi
     */
    public gendersGIDGet(gID: string, locale?: string, options?: RawAxiosRequestConfig) {
        return GendersApiFp(this.configuration).gendersGIDGet(gID, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update gender
     * @summary save or update gender
     * @param {string} gID gID
     * @param {DefsGenderData} defsGenderData Gender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GendersApi
     */
    public gendersGIDPut(gID: string, defsGenderData: DefsGenderData, options?: RawAxiosRequestConfig) {
        return GendersApiFp(this.configuration).gendersGIDPut(gID, defsGenderData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to gender
     * @param {string} gID Gender ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersGenderTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GendersApi
     */
    public gendersGIDTranslationLocalePost(gID: string, locale: string, translation: HandlersGenderTranslationRequest, options?: RawAxiosRequestConfig) {
        return GendersApiFp(this.configuration).gendersGIDTranslationLocalePost(gID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all genders
     * @summary get all genders
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GendersApi
     */
    public gendersGet(locale?: string, options?: RawAxiosRequestConfig) {
        return GendersApiFp(this.configuration).gendersGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update gender
     * @summary save or update gender
     * @param {DefsGenderData} defsGenderData Gender
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GendersApi
     */
    public gendersPost(defsGenderData: DefsGenderData, options?: RawAxiosRequestConfig) {
        return GendersApiFp(this.configuration).gendersPost(defsGenderData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HobbiesApi - axios parameter creator
 * @export
 */
export const HobbiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all hobbies
         * @summary get all hobbies
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/hobbies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete hobby by id
         * @summary delete hobby
         * @param {string} hID hID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesHIDDelete: async (hID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hID' is not null or undefined
            assertParamExists('hobbiesHIDDelete', 'hID', hID)
            const localVarPath = `/hobbies/{hID}`
                .replace(`{${"hID"}}`, encodeURIComponent(String(hID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get hobby by id
         * @summary get hobby
         * @param {string} hID hID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesHIDGet: async (hID: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hID' is not null or undefined
            assertParamExists('hobbiesHIDGet', 'hID', hID)
            const localVarPath = `/hobbies/{hID}`
                .replace(`{${"hID"}}`, encodeURIComponent(String(hID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update hobby
         * @summary save or update hobby
         * @param {string} hID hID
         * @param {DefsHobbyData} defsHobbyData Hobby
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesHIDPut: async (hID: string, defsHobbyData: DefsHobbyData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hID' is not null or undefined
            assertParamExists('hobbiesHIDPut', 'hID', hID)
            // verify required parameter 'defsHobbyData' is not null or undefined
            assertParamExists('hobbiesHIDPut', 'defsHobbyData', defsHobbyData)
            const localVarPath = `/hobbies/{hID}`
                .replace(`{${"hID"}}`, encodeURIComponent(String(hID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsHobbyData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to hobby
         * @param {string} hID Hobby ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersHobbyTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesHIDTranslationLocalePost: async (hID: string, locale: string, translation: HandlersHobbyTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hID' is not null or undefined
            assertParamExists('hobbiesHIDTranslationLocalePost', 'hID', hID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('hobbiesHIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('hobbiesHIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/hobbies/{hID}/translation/{locale}`
                .replace(`{${"hID"}}`, encodeURIComponent(String(hID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update hobby
         * @summary save or update hobby
         * @param {DefsHobbyData} defsHobbyData Hobby
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesPost: async (defsHobbyData: DefsHobbyData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsHobbyData' is not null or undefined
            assertParamExists('hobbiesPost', 'defsHobbyData', defsHobbyData)
            const localVarPath = `/hobbies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsHobbyData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HobbiesApi - functional programming interface
 * @export
 */
export const HobbiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HobbiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all hobbies
         * @summary get all hobbies
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbiesGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsHobbyListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbiesGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HobbiesApi.hobbiesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete hobby by id
         * @summary delete hobby
         * @param {string} hID hID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbiesHIDDelete(hID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbiesHIDDelete(hID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HobbiesApi.hobbiesHIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get hobby by id
         * @summary get hobby
         * @param {string} hID hID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbiesHIDGet(hID: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsHobbyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbiesHIDGet(hID, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HobbiesApi.hobbiesHIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update hobby
         * @summary save or update hobby
         * @param {string} hID hID
         * @param {DefsHobbyData} defsHobbyData Hobby
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbiesHIDPut(hID: string, defsHobbyData: DefsHobbyData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbiesHIDPut(hID, defsHobbyData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HobbiesApi.hobbiesHIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to hobby
         * @param {string} hID Hobby ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersHobbyTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbiesHIDTranslationLocalePost(hID: string, locale: string, translation: HandlersHobbyTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbiesHIDTranslationLocalePost(hID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HobbiesApi.hobbiesHIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update hobby
         * @summary save or update hobby
         * @param {DefsHobbyData} defsHobbyData Hobby
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async hobbiesPost(defsHobbyData: DefsHobbyData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.hobbiesPost(defsHobbyData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HobbiesApi.hobbiesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HobbiesApi - factory interface
 * @export
 */
export const HobbiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HobbiesApiFp(configuration)
    return {
        /**
         * Get all hobbies
         * @summary get all hobbies
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsHobbyListResponse> {
            return localVarFp.hobbiesGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete hobby by id
         * @summary delete hobby
         * @param {string} hID hID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesHIDDelete(hID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.hobbiesHIDDelete(hID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get hobby by id
         * @summary get hobby
         * @param {string} hID hID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesHIDGet(hID: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsHobbyResponse> {
            return localVarFp.hobbiesHIDGet(hID, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update hobby
         * @summary save or update hobby
         * @param {string} hID hID
         * @param {DefsHobbyData} defsHobbyData Hobby
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesHIDPut(hID: string, defsHobbyData: DefsHobbyData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.hobbiesHIDPut(hID, defsHobbyData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to hobby
         * @param {string} hID Hobby ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersHobbyTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesHIDTranslationLocalePost(hID: string, locale: string, translation: HandlersHobbyTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.hobbiesHIDTranslationLocalePost(hID, locale, translation, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update hobby
         * @summary save or update hobby
         * @param {DefsHobbyData} defsHobbyData Hobby
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hobbiesPost(defsHobbyData: DefsHobbyData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.hobbiesPost(defsHobbyData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HobbiesApi - object-oriented interface
 * @export
 * @class HobbiesApi
 * @extends {BaseAPI}
 */
export class HobbiesApi extends BaseAPI {
    /**
     * Get all hobbies
     * @summary get all hobbies
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbiesApi
     */
    public hobbiesGet(locale?: string, options?: RawAxiosRequestConfig) {
        return HobbiesApiFp(this.configuration).hobbiesGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete hobby by id
     * @summary delete hobby
     * @param {string} hID hID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbiesApi
     */
    public hobbiesHIDDelete(hID: string, options?: RawAxiosRequestConfig) {
        return HobbiesApiFp(this.configuration).hobbiesHIDDelete(hID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get hobby by id
     * @summary get hobby
     * @param {string} hID hID
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbiesApi
     */
    public hobbiesHIDGet(hID: string, locale?: string, options?: RawAxiosRequestConfig) {
        return HobbiesApiFp(this.configuration).hobbiesHIDGet(hID, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update hobby
     * @summary save or update hobby
     * @param {string} hID hID
     * @param {DefsHobbyData} defsHobbyData Hobby
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbiesApi
     */
    public hobbiesHIDPut(hID: string, defsHobbyData: DefsHobbyData, options?: RawAxiosRequestConfig) {
        return HobbiesApiFp(this.configuration).hobbiesHIDPut(hID, defsHobbyData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to hobby
     * @param {string} hID Hobby ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersHobbyTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbiesApi
     */
    public hobbiesHIDTranslationLocalePost(hID: string, locale: string, translation: HandlersHobbyTranslationRequest, options?: RawAxiosRequestConfig) {
        return HobbiesApiFp(this.configuration).hobbiesHIDTranslationLocalePost(hID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update hobby
     * @summary save or update hobby
     * @param {DefsHobbyData} defsHobbyData Hobby
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HobbiesApi
     */
    public hobbiesPost(defsHobbyData: DefsHobbyData, options?: RawAxiosRequestConfig) {
        return HobbiesApiFp(this.configuration).hobbiesPost(defsHobbyData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImagesApi - axios parameter creator
 * @export
 */
export const ImagesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all profile pictures original and jiggled
         * @summary Get all profile pictures
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesGet', 'userID', userID)
            const localVarPath = `/image-upload/{userID}/profile-pictures`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload new picture with size and type validation
         * @summary imageUploader new picture with enhanced validation and error handling
         * @param {string} userID userID
         * @param {File} imageInput image_input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesPost: async (userID: string, imageInput: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesPost', 'userID', userID)
            // verify required parameter 'imageInput' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesPost', 'imageInput', imageInput)
            const localVarPath = `/image-upload/{userID}/profile-pictures`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


            if (imageInput !== undefined) { 
                localVarFormParams.append('image_input', imageInput as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete picture profile
         * @summary Delete picture profile
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesProfilePicIDDelete: async (userID: string, profilePicID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDDelete', 'userID', userID)
            // verify required parameter 'profilePicID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDDelete', 'profilePicID', profilePicID)
            const localVarPath = `/image-upload/{userID}/profile-pictures/{profilePicID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)))
                .replace(`{${"profilePicID"}}`, encodeURIComponent(String(profilePicID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete duplicate picture profile
         * @summary Delete duplicate picture profile
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesProfilePicIDDupDelete: async (userID: string, profilePicID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDDupDelete', 'userID', userID)
            // verify required parameter 'profilePicID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDDupDelete', 'profilePicID', profilePicID)
            const localVarPath = `/image-upload/{userID}/profile-pictures/{profilePicID}/dup`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)))
                .replace(`{${"profilePicID"}}`, encodeURIComponent(String(profilePicID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get profile pictures, original and jiggled based on profilePicID
         * @summary Get profile pictures
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesProfilePicIDGet: async (userID: string, profilePicID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDGet', 'userID', userID)
            // verify required parameter 'profilePicID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDGet', 'profilePicID', profilePicID)
            const localVarPath = `/image-upload/{userID}/profile-pictures/{profilePicID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)))
                .replace(`{${"profilePicID"}}`, encodeURIComponent(String(profilePicID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload edit by user-id and profile-pic-id
         * @summary imageUploader edit
         * @param {string} userID userID
         * @param {string} profilePicID profilePicID
         * @param {File} imageInput image_input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesProfilePicIDPut: async (userID: string, profilePicID: string, imageInput: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDPut', 'userID', userID)
            // verify required parameter 'profilePicID' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDPut', 'profilePicID', profilePicID)
            // verify required parameter 'imageInput' is not null or undefined
            assertParamExists('imageUploadUserIDProfilePicturesProfilePicIDPut', 'imageInput', imageInput)
            const localVarPath = `/image-upload/{userID}/profile-pictures/{profilePicID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)))
                .replace(`{${"profilePicID"}}`, encodeURIComponent(String(profilePicID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


            if (imageInput !== undefined) { 
                localVarFormParams.append('image_input', imageInput as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImagesApi - functional programming interface
 * @export
 */
export const ImagesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImagesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all profile pictures original and jiggled
         * @summary Get all profile pictures
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUploadUserIDProfilePicturesGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsProfileImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUploadUserIDProfilePicturesGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImagesApi.imageUploadUserIDProfilePicturesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload new picture with size and type validation
         * @summary imageUploader new picture with enhanced validation and error handling
         * @param {string} userID userID
         * @param {File} imageInput image_input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUploadUserIDProfilePicturesPost(userID: string, imageInput: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsProfileImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUploadUserIDProfilePicturesPost(userID, imageInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImagesApi.imageUploadUserIDProfilePicturesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete picture profile
         * @summary Delete picture profile
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUploadUserIDProfilePicturesProfilePicIDDelete(userID: string, profilePicID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUploadUserIDProfilePicturesProfilePicIDDelete(userID, profilePicID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImagesApi.imageUploadUserIDProfilePicturesProfilePicIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete duplicate picture profile
         * @summary Delete duplicate picture profile
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUploadUserIDProfilePicturesProfilePicIDDupDelete(userID: string, profilePicID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUploadUserIDProfilePicturesProfilePicIDDupDelete(userID, profilePicID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImagesApi.imageUploadUserIDProfilePicturesProfilePicIDDupDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get profile pictures, original and jiggled based on profilePicID
         * @summary Get profile pictures
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUploadUserIDProfilePicturesProfilePicIDGet(userID: string, profilePicID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsProfileImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUploadUserIDProfilePicturesProfilePicIDGet(userID, profilePicID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImagesApi.imageUploadUserIDProfilePicturesProfilePicIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload edit by user-id and profile-pic-id
         * @summary imageUploader edit
         * @param {string} userID userID
         * @param {string} profilePicID profilePicID
         * @param {File} imageInput image_input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async imageUploadUserIDProfilePicturesProfilePicIDPut(userID: string, profilePicID: string, imageInput: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsProfileImageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.imageUploadUserIDProfilePicturesProfilePicIDPut(userID, profilePicID, imageInput, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImagesApi.imageUploadUserIDProfilePicturesProfilePicIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ImagesApi - factory interface
 * @export
 */
export const ImagesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImagesApiFp(configuration)
    return {
        /**
         * Get all profile pictures original and jiggled
         * @summary Get all profile pictures
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsProfileImageResponse> {
            return localVarFp.imageUploadUserIDProfilePicturesGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload new picture with size and type validation
         * @summary imageUploader new picture with enhanced validation and error handling
         * @param {string} userID userID
         * @param {File} imageInput image_input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesPost(userID: string, imageInput: File, options?: RawAxiosRequestConfig): AxiosPromise<DefsProfileImageResponse> {
            return localVarFp.imageUploadUserIDProfilePicturesPost(userID, imageInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete picture profile
         * @summary Delete picture profile
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesProfilePicIDDelete(userID: string, profilePicID: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.imageUploadUserIDProfilePicturesProfilePicIDDelete(userID, profilePicID, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete duplicate picture profile
         * @summary Delete duplicate picture profile
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesProfilePicIDDupDelete(userID: string, profilePicID: string, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.imageUploadUserIDProfilePicturesProfilePicIDDupDelete(userID, profilePicID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get profile pictures, original and jiggled based on profilePicID
         * @summary Get profile pictures
         * @param {string} userID User ID
         * @param {string} profilePicID Profile Picture ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesProfilePicIDGet(userID: string, profilePicID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsProfileImageResponse> {
            return localVarFp.imageUploadUserIDProfilePicturesProfilePicIDGet(userID, profilePicID, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload edit by user-id and profile-pic-id
         * @summary imageUploader edit
         * @param {string} userID userID
         * @param {string} profilePicID profilePicID
         * @param {File} imageInput image_input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        imageUploadUserIDProfilePicturesProfilePicIDPut(userID: string, profilePicID: string, imageInput: File, options?: RawAxiosRequestConfig): AxiosPromise<DefsProfileImageResponse> {
            return localVarFp.imageUploadUserIDProfilePicturesProfilePicIDPut(userID, profilePicID, imageInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImagesApi - object-oriented interface
 * @export
 * @class ImagesApi
 * @extends {BaseAPI}
 */
export class ImagesApi extends BaseAPI {
    /**
     * Get all profile pictures original and jiggled
     * @summary Get all profile pictures
     * @param {string} userID User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imageUploadUserIDProfilePicturesGet(userID: string, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imageUploadUserIDProfilePicturesGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload new picture with size and type validation
     * @summary imageUploader new picture with enhanced validation and error handling
     * @param {string} userID userID
     * @param {File} imageInput image_input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imageUploadUserIDProfilePicturesPost(userID: string, imageInput: File, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imageUploadUserIDProfilePicturesPost(userID, imageInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete picture profile
     * @summary Delete picture profile
     * @param {string} userID User ID
     * @param {string} profilePicID Profile Picture ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imageUploadUserIDProfilePicturesProfilePicIDDelete(userID: string, profilePicID: string, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imageUploadUserIDProfilePicturesProfilePicIDDelete(userID, profilePicID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete duplicate picture profile
     * @summary Delete duplicate picture profile
     * @param {string} userID User ID
     * @param {string} profilePicID Profile Picture ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imageUploadUserIDProfilePicturesProfilePicIDDupDelete(userID: string, profilePicID: string, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imageUploadUserIDProfilePicturesProfilePicIDDupDelete(userID, profilePicID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get profile pictures, original and jiggled based on profilePicID
     * @summary Get profile pictures
     * @param {string} userID User ID
     * @param {string} profilePicID Profile Picture ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imageUploadUserIDProfilePicturesProfilePicIDGet(userID: string, profilePicID: string, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imageUploadUserIDProfilePicturesProfilePicIDGet(userID, profilePicID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload edit by user-id and profile-pic-id
     * @summary imageUploader edit
     * @param {string} userID userID
     * @param {string} profilePicID profilePicID
     * @param {File} imageInput image_input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImagesApi
     */
    public imageUploadUserIDProfilePicturesProfilePicIDPut(userID: string, profilePicID: string, imageInput: File, options?: RawAxiosRequestConfig) {
        return ImagesApiFp(this.configuration).imageUploadUserIDProfilePicturesProfilePicIDPut(userID, profilePicID, imageInput, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InterestsApi - axios parameter creator
 * @export
 */
export const InterestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all interests
         * @summary get all interests
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/interests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete interest by id
         * @summary delete interest
         * @param {string} iID iID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsIIDDelete: async (iID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iID' is not null or undefined
            assertParamExists('interestsIIDDelete', 'iID', iID)
            const localVarPath = `/interests/{iID}`
                .replace(`{${"iID"}}`, encodeURIComponent(String(iID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get interest by id
         * @summary get interest
         * @param {string} iID iID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsIIDGet: async (iID: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iID' is not null or undefined
            assertParamExists('interestsIIDGet', 'iID', iID)
            const localVarPath = `/interests/{iID}`
                .replace(`{${"iID"}}`, encodeURIComponent(String(iID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update interest
         * @summary save or update interest
         * @param {string} iID iID
         * @param {DefsInterestsData} defsInterestsData Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsIIDPut: async (iID: string, defsInterestsData: DefsInterestsData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iID' is not null or undefined
            assertParamExists('interestsIIDPut', 'iID', iID)
            // verify required parameter 'defsInterestsData' is not null or undefined
            assertParamExists('interestsIIDPut', 'defsInterestsData', defsInterestsData)
            const localVarPath = `/interests/{iID}`
                .replace(`{${"iID"}}`, encodeURIComponent(String(iID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsInterestsData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to interest
         * @param {string} iID Interest ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersInterestTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsIIDTranslationLocalePost: async (iID: string, locale: string, translation: HandlersInterestTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'iID' is not null or undefined
            assertParamExists('interestsIIDTranslationLocalePost', 'iID', iID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('interestsIIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('interestsIIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/interests/{iID}/translation/{locale}`
                .replace(`{${"iID"}}`, encodeURIComponent(String(iID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update interest
         * @summary save or update interest
         * @param {DefsInterestsData} defsInterestsData Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsPost: async (defsInterestsData: DefsInterestsData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsInterestsData' is not null or undefined
            assertParamExists('interestsPost', 'defsInterestsData', defsInterestsData)
            const localVarPath = `/interests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsInterestsData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterestsApi - functional programming interface
 * @export
 */
export const InterestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterestsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all interests
         * @summary get all interests
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestsGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsInterestsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interestsGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestsApi.interestsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete interest by id
         * @summary delete interest
         * @param {string} iID iID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestsIIDDelete(iID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interestsIIDDelete(iID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestsApi.interestsIIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get interest by id
         * @summary get interest
         * @param {string} iID iID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestsIIDGet(iID: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsInterestsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interestsIIDGet(iID, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestsApi.interestsIIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update interest
         * @summary save or update interest
         * @param {string} iID iID
         * @param {DefsInterestsData} defsInterestsData Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestsIIDPut(iID: string, defsInterestsData: DefsInterestsData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interestsIIDPut(iID, defsInterestsData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestsApi.interestsIIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to interest
         * @param {string} iID Interest ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersInterestTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestsIIDTranslationLocalePost(iID: string, locale: string, translation: HandlersInterestTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interestsIIDTranslationLocalePost(iID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestsApi.interestsIIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update interest
         * @summary save or update interest
         * @param {DefsInterestsData} defsInterestsData Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async interestsPost(defsInterestsData: DefsInterestsData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.interestsPost(defsInterestsData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InterestsApi.interestsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InterestsApi - factory interface
 * @export
 */
export const InterestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterestsApiFp(configuration)
    return {
        /**
         * Get all interests
         * @summary get all interests
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsInterestsListResponse> {
            return localVarFp.interestsGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete interest by id
         * @summary delete interest
         * @param {string} iID iID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsIIDDelete(iID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.interestsIIDDelete(iID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get interest by id
         * @summary get interest
         * @param {string} iID iID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsIIDGet(iID: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsInterestsResponse> {
            return localVarFp.interestsIIDGet(iID, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update interest
         * @summary save or update interest
         * @param {string} iID iID
         * @param {DefsInterestsData} defsInterestsData Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsIIDPut(iID: string, defsInterestsData: DefsInterestsData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.interestsIIDPut(iID, defsInterestsData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to interest
         * @param {string} iID Interest ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersInterestTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsIIDTranslationLocalePost(iID: string, locale: string, translation: HandlersInterestTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.interestsIIDTranslationLocalePost(iID, locale, translation, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update interest
         * @summary save or update interest
         * @param {DefsInterestsData} defsInterestsData Interest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        interestsPost(defsInterestsData: DefsInterestsData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.interestsPost(defsInterestsData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterestsApi - object-oriented interface
 * @export
 * @class InterestsApi
 * @extends {BaseAPI}
 */
export class InterestsApi extends BaseAPI {
    /**
     * Get all interests
     * @summary get all interests
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestsApi
     */
    public interestsGet(locale?: string, options?: RawAxiosRequestConfig) {
        return InterestsApiFp(this.configuration).interestsGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete interest by id
     * @summary delete interest
     * @param {string} iID iID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestsApi
     */
    public interestsIIDDelete(iID: string, options?: RawAxiosRequestConfig) {
        return InterestsApiFp(this.configuration).interestsIIDDelete(iID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get interest by id
     * @summary get interest
     * @param {string} iID iID
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestsApi
     */
    public interestsIIDGet(iID: string, locale?: string, options?: RawAxiosRequestConfig) {
        return InterestsApiFp(this.configuration).interestsIIDGet(iID, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update interest
     * @summary save or update interest
     * @param {string} iID iID
     * @param {DefsInterestsData} defsInterestsData Interest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestsApi
     */
    public interestsIIDPut(iID: string, defsInterestsData: DefsInterestsData, options?: RawAxiosRequestConfig) {
        return InterestsApiFp(this.configuration).interestsIIDPut(iID, defsInterestsData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to interest
     * @param {string} iID Interest ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersInterestTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestsApi
     */
    public interestsIIDTranslationLocalePost(iID: string, locale: string, translation: HandlersInterestTranslationRequest, options?: RawAxiosRequestConfig) {
        return InterestsApiFp(this.configuration).interestsIIDTranslationLocalePost(iID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update interest
     * @summary save or update interest
     * @param {DefsInterestsData} defsInterestsData Interest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterestsApi
     */
    public interestsPost(defsInterestsData: DefsInterestsData, options?: RawAxiosRequestConfig) {
        return InterestsApiFp(this.configuration).interestsPost(defsInterestsData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InvitesApi - axios parameter creator
 * @export
 */
export const InvitesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create or update an invite link for a user and add new contacts to the invite
         * @summary Send invites to contacts
         * @param {string} userID User ID
         * @param {DefsSendInvitesRequest} request Invite request containing user name and contacts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDInvitesPost: async (userID: string, request: DefsSendInvitesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDInvitesPost', 'userID', userID)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('usersUserIDInvitesPost', 'request', request)
            const localVarPath = `/users/{userID}/invites`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InvitesApi - functional programming interface
 * @export
 */
export const InvitesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InvitesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create or update an invite link for a user and add new contacts to the invite
         * @summary Send invites to contacts
         * @param {string} userID User ID
         * @param {DefsSendInvitesRequest} request Invite request containing user name and contacts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDInvitesPost(userID: string, request: DefsSendInvitesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsSendInvitesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDInvitesPost(userID, request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['InvitesApi.usersUserIDInvitesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * InvitesApi - factory interface
 * @export
 */
export const InvitesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InvitesApiFp(configuration)
    return {
        /**
         * Create or update an invite link for a user and add new contacts to the invite
         * @summary Send invites to contacts
         * @param {string} userID User ID
         * @param {DefsSendInvitesRequest} request Invite request containing user name and contacts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDInvitesPost(userID: string, request: DefsSendInvitesRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsSendInvitesResponse> {
            return localVarFp.usersUserIDInvitesPost(userID, request, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InvitesApi - object-oriented interface
 * @export
 * @class InvitesApi
 * @extends {BaseAPI}
 */
export class InvitesApi extends BaseAPI {
    /**
     * Create or update an invite link for a user and add new contacts to the invite
     * @summary Send invites to contacts
     * @param {string} userID User ID
     * @param {DefsSendInvitesRequest} request Invite request containing user name and contacts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InvitesApi
     */
    public usersUserIDInvitesPost(userID: string, request: DefsSendInvitesRequest, options?: RawAxiosRequestConfig) {
        return InvitesApiFp(this.configuration).usersUserIDInvitesPost(userID, request, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MbtiApi - axios parameter creator
 * @export
 */
export const MbtiApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get common traits by given pair must be XXXX_YYYY where X and Y are E, I, S, N, T, F, J, or P
         * @summary get common traits by given pair
         * @param {string} pair MBTI Pair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mbtiCommonTraitsGet: async (pair: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pair' is not null or undefined
            assertParamExists('mbtiCommonTraitsGet', 'pair', pair)
            const localVarPath = `/mbti-common-traits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (pair !== undefined) {
                localVarQueryParameter['pair'] = pair;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MbtiApi - functional programming interface
 * @export
 */
export const MbtiApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MbtiApiAxiosParamCreator(configuration)
    return {
        /**
         * Get common traits by given pair must be XXXX_YYYY where X and Y are E, I, S, N, T, F, J, or P
         * @summary get common traits by given pair
         * @param {string} pair MBTI Pair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mbtiCommonTraitsGet(pair: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsCommonTraitsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mbtiCommonTraitsGet(pair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['MbtiApi.mbtiCommonTraitsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * MbtiApi - factory interface
 * @export
 */
export const MbtiApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MbtiApiFp(configuration)
    return {
        /**
         * Get common traits by given pair must be XXXX_YYYY where X and Y are E, I, S, N, T, F, J, or P
         * @summary get common traits by given pair
         * @param {string} pair MBTI Pair
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mbtiCommonTraitsGet(pair: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsCommonTraitsResponse> {
            return localVarFp.mbtiCommonTraitsGet(pair, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MbtiApi - object-oriented interface
 * @export
 * @class MbtiApi
 * @extends {BaseAPI}
 */
export class MbtiApi extends BaseAPI {
    /**
     * Get common traits by given pair must be XXXX_YYYY where X and Y are E, I, S, N, T, F, J, or P
     * @summary get common traits by given pair
     * @param {string} pair MBTI Pair
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MbtiApi
     */
    public mbtiCommonTraitsGet(pair: string, options?: RawAxiosRequestConfig) {
        return MbtiApiFp(this.configuration).mbtiCommonTraitsGet(pair, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PreferencesApi - axios parameter creator
 * @export
 */
export const PreferencesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get user preferences by user-id
         * @summary get user preferences
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPreferencesDelete: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDPreferencesDelete', 'userID', userID)
            const localVarPath = `/users/{userID}/preferences`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user preferences by user-id
         * @summary get user preferences
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPreferencesGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDPreferencesGet', 'userID', userID)
            const localVarPath = `/users/{userID}/preferences`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add or update user preferences
         * @summary add or update user preferences by userID
         * @param {string} userID userID
         * @param {DefsUserPreferencesData} defsUserPreferencesData UserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPreferencesPost: async (userID: string, defsUserPreferencesData: DefsUserPreferencesData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDPreferencesPost', 'userID', userID)
            // verify required parameter 'defsUserPreferencesData' is not null or undefined
            assertParamExists('usersUserIDPreferencesPost', 'defsUserPreferencesData', defsUserPreferencesData)
            const localVarPath = `/users/{userID}/preferences`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUserPreferencesData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add or update user preferences
         * @summary add or update user preferences by userID
         * @param {string} userID userID
         * @param {DefsUserPreferencesData} defsUserPreferencesData UserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPreferencesPut: async (userID: string, defsUserPreferencesData: DefsUserPreferencesData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDPreferencesPut', 'userID', userID)
            // verify required parameter 'defsUserPreferencesData' is not null or undefined
            assertParamExists('usersUserIDPreferencesPut', 'defsUserPreferencesData', defsUserPreferencesData)
            const localVarPath = `/users/{userID}/preferences`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUserPreferencesData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreferencesApi - functional programming interface
 * @export
 */
export const PreferencesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreferencesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get user preferences by user-id
         * @summary get user preferences
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDPreferencesDelete(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDPreferencesDelete(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PreferencesApi.usersUserIDPreferencesDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user preferences by user-id
         * @summary get user preferences
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDPreferencesGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserPreferencesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDPreferencesGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PreferencesApi.usersUserIDPreferencesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add or update user preferences
         * @summary add or update user preferences by userID
         * @param {string} userID userID
         * @param {DefsUserPreferencesData} defsUserPreferencesData UserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDPreferencesPost(userID: string, defsUserPreferencesData: DefsUserPreferencesData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDPreferencesPost(userID, defsUserPreferencesData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PreferencesApi.usersUserIDPreferencesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add or update user preferences
         * @summary add or update user preferences by userID
         * @param {string} userID userID
         * @param {DefsUserPreferencesData} defsUserPreferencesData UserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDPreferencesPut(userID: string, defsUserPreferencesData: DefsUserPreferencesData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDPreferencesPut(userID, defsUserPreferencesData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PreferencesApi.usersUserIDPreferencesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PreferencesApi - factory interface
 * @export
 */
export const PreferencesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreferencesApiFp(configuration)
    return {
        /**
         * Get user preferences by user-id
         * @summary get user preferences
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPreferencesDelete(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDPreferencesDelete(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user preferences by user-id
         * @summary get user preferences
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPreferencesGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserPreferencesResponse> {
            return localVarFp.usersUserIDPreferencesGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Add or update user preferences
         * @summary add or update user preferences by userID
         * @param {string} userID userID
         * @param {DefsUserPreferencesData} defsUserPreferencesData UserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPreferencesPost(userID: string, defsUserPreferencesData: DefsUserPreferencesData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.usersUserIDPreferencesPost(userID, defsUserPreferencesData, options).then((request) => request(axios, basePath));
        },
        /**
         * Add or update user preferences
         * @summary add or update user preferences by userID
         * @param {string} userID userID
         * @param {DefsUserPreferencesData} defsUserPreferencesData UserPreferences
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPreferencesPut(userID: string, defsUserPreferencesData: DefsUserPreferencesData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.usersUserIDPreferencesPut(userID, defsUserPreferencesData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PreferencesApi - object-oriented interface
 * @export
 * @class PreferencesApi
 * @extends {BaseAPI}
 */
export class PreferencesApi extends BaseAPI {
    /**
     * Get user preferences by user-id
     * @summary get user preferences
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public usersUserIDPreferencesDelete(userID: string, options?: RawAxiosRequestConfig) {
        return PreferencesApiFp(this.configuration).usersUserIDPreferencesDelete(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user preferences by user-id
     * @summary get user preferences
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public usersUserIDPreferencesGet(userID: string, options?: RawAxiosRequestConfig) {
        return PreferencesApiFp(this.configuration).usersUserIDPreferencesGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add or update user preferences
     * @summary add or update user preferences by userID
     * @param {string} userID userID
     * @param {DefsUserPreferencesData} defsUserPreferencesData UserPreferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public usersUserIDPreferencesPost(userID: string, defsUserPreferencesData: DefsUserPreferencesData, options?: RawAxiosRequestConfig) {
        return PreferencesApiFp(this.configuration).usersUserIDPreferencesPost(userID, defsUserPreferencesData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add or update user preferences
     * @summary add or update user preferences by userID
     * @param {string} userID userID
     * @param {DefsUserPreferencesData} defsUserPreferencesData UserPreferences
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreferencesApi
     */
    public usersUserIDPreferencesPut(userID: string, defsUserPreferencesData: DefsUserPreferencesData, options?: RawAxiosRequestConfig) {
        return PreferencesApiFp(this.configuration).usersUserIDPreferencesPut(userID, defsUserPreferencesData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProfessionsApi - axios parameter creator
 * @export
 */
export const ProfessionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all professions
         * @summary get all professions
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/professions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete profession by id
         * @summary delete profession
         * @param {string} pID pID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPIDDelete: async (pID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pID' is not null or undefined
            assertParamExists('professionsPIDDelete', 'pID', pID)
            const localVarPath = `/professions/{pID}`
                .replace(`{${"pID"}}`, encodeURIComponent(String(pID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get profession by id
         * @summary get profession
         * @param {string} pID pID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPIDGet: async (pID: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pID' is not null or undefined
            assertParamExists('professionsPIDGet', 'pID', pID)
            const localVarPath = `/professions/{pID}`
                .replace(`{${"pID"}}`, encodeURIComponent(String(pID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update profession
         * @summary save or update profession
         * @param {string} pID pID
         * @param {DefsProfessionData} defsProfessionData Profession data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPIDPut: async (pID: string, defsProfessionData: DefsProfessionData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pID' is not null or undefined
            assertParamExists('professionsPIDPut', 'pID', pID)
            // verify required parameter 'defsProfessionData' is not null or undefined
            assertParamExists('professionsPIDPut', 'defsProfessionData', defsProfessionData)
            const localVarPath = `/professions/{pID}`
                .replace(`{${"pID"}}`, encodeURIComponent(String(pID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsProfessionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to profession
         * @param {string} pID Profession ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersProfessionTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPIDTranslationLocalePost: async (pID: string, locale: string, translation: HandlersProfessionTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pID' is not null or undefined
            assertParamExists('professionsPIDTranslationLocalePost', 'pID', pID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('professionsPIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('professionsPIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/professions/{pID}/translation/{locale}`
                .replace(`{${"pID"}}`, encodeURIComponent(String(pID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update profession
         * @summary save or update profession
         * @param {DefsProfessionData} defsProfessionData Profession data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPost: async (defsProfessionData: DefsProfessionData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsProfessionData' is not null or undefined
            assertParamExists('professionsPost', 'defsProfessionData', defsProfessionData)
            const localVarPath = `/professions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsProfessionData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfessionsApi - functional programming interface
 * @export
 */
export const ProfessionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfessionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all professions
         * @summary get all professions
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async professionsGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsProfessionListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.professionsGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfessionsApi.professionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete profession by id
         * @summary delete profession
         * @param {string} pID pID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async professionsPIDDelete(pID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.professionsPIDDelete(pID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfessionsApi.professionsPIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get profession by id
         * @summary get profession
         * @param {string} pID pID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async professionsPIDGet(pID: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsProfessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.professionsPIDGet(pID, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfessionsApi.professionsPIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update profession
         * @summary save or update profession
         * @param {string} pID pID
         * @param {DefsProfessionData} defsProfessionData Profession data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async professionsPIDPut(pID: string, defsProfessionData: DefsProfessionData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.professionsPIDPut(pID, defsProfessionData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfessionsApi.professionsPIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to profession
         * @param {string} pID Profession ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersProfessionTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async professionsPIDTranslationLocalePost(pID: string, locale: string, translation: HandlersProfessionTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.professionsPIDTranslationLocalePost(pID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfessionsApi.professionsPIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update profession
         * @summary save or update profession
         * @param {DefsProfessionData} defsProfessionData Profession data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async professionsPost(defsProfessionData: DefsProfessionData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.professionsPost(defsProfessionData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProfessionsApi.professionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProfessionsApi - factory interface
 * @export
 */
export const ProfessionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfessionsApiFp(configuration)
    return {
        /**
         * Get all professions
         * @summary get all professions
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsProfessionListResponse> {
            return localVarFp.professionsGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete profession by id
         * @summary delete profession
         * @param {string} pID pID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPIDDelete(pID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.professionsPIDDelete(pID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get profession by id
         * @summary get profession
         * @param {string} pID pID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPIDGet(pID: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsProfessionResponse> {
            return localVarFp.professionsPIDGet(pID, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update profession
         * @summary save or update profession
         * @param {string} pID pID
         * @param {DefsProfessionData} defsProfessionData Profession data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPIDPut(pID: string, defsProfessionData: DefsProfessionData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.professionsPIDPut(pID, defsProfessionData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to profession
         * @param {string} pID Profession ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersProfessionTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPIDTranslationLocalePost(pID: string, locale: string, translation: HandlersProfessionTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.professionsPIDTranslationLocalePost(pID, locale, translation, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update profession
         * @summary save or update profession
         * @param {DefsProfessionData} defsProfessionData Profession data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        professionsPost(defsProfessionData: DefsProfessionData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.professionsPost(defsProfessionData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfessionsApi - object-oriented interface
 * @export
 * @class ProfessionsApi
 * @extends {BaseAPI}
 */
export class ProfessionsApi extends BaseAPI {
    /**
     * Get all professions
     * @summary get all professions
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionsApi
     */
    public professionsGet(locale?: string, options?: RawAxiosRequestConfig) {
        return ProfessionsApiFp(this.configuration).professionsGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete profession by id
     * @summary delete profession
     * @param {string} pID pID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionsApi
     */
    public professionsPIDDelete(pID: string, options?: RawAxiosRequestConfig) {
        return ProfessionsApiFp(this.configuration).professionsPIDDelete(pID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get profession by id
     * @summary get profession
     * @param {string} pID pID
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionsApi
     */
    public professionsPIDGet(pID: string, locale?: string, options?: RawAxiosRequestConfig) {
        return ProfessionsApiFp(this.configuration).professionsPIDGet(pID, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update profession
     * @summary save or update profession
     * @param {string} pID pID
     * @param {DefsProfessionData} defsProfessionData Profession data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionsApi
     */
    public professionsPIDPut(pID: string, defsProfessionData: DefsProfessionData, options?: RawAxiosRequestConfig) {
        return ProfessionsApiFp(this.configuration).professionsPIDPut(pID, defsProfessionData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to profession
     * @param {string} pID Profession ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersProfessionTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionsApi
     */
    public professionsPIDTranslationLocalePost(pID: string, locale: string, translation: HandlersProfessionTranslationRequest, options?: RawAxiosRequestConfig) {
        return ProfessionsApiFp(this.configuration).professionsPIDTranslationLocalePost(pID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update profession
     * @summary save or update profession
     * @param {DefsProfessionData} defsProfessionData Profession data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfessionsApi
     */
    public professionsPost(defsProfessionData: DefsProfessionData, options?: RawAxiosRequestConfig) {
        return ProfessionsApiFp(this.configuration).professionsPost(defsProfessionData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PurchasesApi - axios parameter creator
 * @export
 */
export const PurchasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create or update a package
         * @summary Create or update a package
         * @param {DefsPackage} request Package Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesCreatePackagePost: async (request: DefsPackage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('purchasesCreatePackagePost', 'request', request)
            const localVarPath = `/purchases/create-package`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or update a package
         * @summary Create or update a package
         * @param {DefsPackage} request Package Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesCreatePackagePut: async (request: DefsPackage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('purchasesCreatePackagePut', 'request', request)
            const localVarPath = `/purchases/create-package`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a package
         * @summary Delete a package
         * @param {string} id ID of the package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesDeletePackageIdDelete: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('purchasesDeletePackageIdDelete', 'id', id)
            const localVarPath = `/purchases/delete-package/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reveal a user\'s photo using available views
         * @summary Reveal photo
         * @param {string} targetUserId Target User ID
         * @param {DefsRevealPhotoRequest} request Reveal Photo Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesTargetUserIdRevealPhotosPost: async (targetUserId: string, request: DefsRevealPhotoRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'targetUserId' is not null or undefined
            assertParamExists('purchasesTargetUserIdRevealPhotosPost', 'targetUserId', targetUserId)
            // verify required parameter 'request' is not null or undefined
            assertParamExists('purchasesTargetUserIdRevealPhotosPost', 'request', request)
            const localVarPath = `/purchases/{target_user_id}/reveal-photos`
                .replace(`{${"target_user_id"}}`, encodeURIComponent(String(targetUserId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify purchase from Apple/Google and add views to user account
         * @summary Verify purchase and add views
         * @param {DefsVerifyApplePurchaseRequest} request Verify Purchase Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesVerifyApplePost: async (request: DefsVerifyApplePurchaseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('purchasesVerifyApplePost', 'request', request)
            const localVarPath = `/purchases/verify/apple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Verify purchase from Apple/Google and add views to user account
         * @summary Verify purchase and add views
         * @param {DefsVerifyGooglePurchaseRequest} request Verify Purchase Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesVerifyGooglePost: async (request: DefsVerifyGooglePurchaseRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            assertParamExists('purchasesVerifyGooglePost', 'request', request)
            const localVarPath = `/purchases/verify/google`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(request, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all available view packages for purchase
         * @summary Get available packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesViewPackagesGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/purchases/view-packages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchasesApi - functional programming interface
 * @export
 */
export const PurchasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchasesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create or update a package
         * @summary Create or update a package
         * @param {DefsPackage} request Package Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchasesCreatePackagePost(request: DefsPackage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsPackage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchasesCreatePackagePost(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.purchasesCreatePackagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create or update a package
         * @summary Create or update a package
         * @param {DefsPackage} request Package Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchasesCreatePackagePut(request: DefsPackage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsPackage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchasesCreatePackagePut(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.purchasesCreatePackagePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete a package
         * @summary Delete a package
         * @param {string} id ID of the package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchasesDeletePackageIdDelete(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchasesDeletePackageIdDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.purchasesDeletePackageIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reveal a user\'s photo using available views
         * @summary Reveal photo
         * @param {string} targetUserId Target User ID
         * @param {DefsRevealPhotoRequest} request Reveal Photo Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchasesTargetUserIdRevealPhotosPost(targetUserId: string, request: DefsRevealPhotoRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsRevealPhotoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchasesTargetUserIdRevealPhotosPost(targetUserId, request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.purchasesTargetUserIdRevealPhotosPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verify purchase from Apple/Google and add views to user account
         * @summary Verify purchase and add views
         * @param {DefsVerifyApplePurchaseRequest} request Verify Purchase Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchasesVerifyApplePost(request: DefsVerifyApplePurchaseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsVerifyPurchaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchasesVerifyApplePost(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.purchasesVerifyApplePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Verify purchase from Apple/Google and add views to user account
         * @summary Verify purchase and add views
         * @param {DefsVerifyGooglePurchaseRequest} request Verify Purchase Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchasesVerifyGooglePost(request: DefsVerifyGooglePurchaseRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsVerifyPurchaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchasesVerifyGooglePost(request, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.purchasesVerifyGooglePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all available view packages for purchase
         * @summary Get available packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchasesViewPackagesGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DefsPackage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchasesViewPackagesGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PurchasesApi.purchasesViewPackagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PurchasesApi - factory interface
 * @export
 */
export const PurchasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchasesApiFp(configuration)
    return {
        /**
         * Create or update a package
         * @summary Create or update a package
         * @param {DefsPackage} request Package Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesCreatePackagePost(request: DefsPackage, options?: RawAxiosRequestConfig): AxiosPromise<DefsPackage> {
            return localVarFp.purchasesCreatePackagePost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or update a package
         * @summary Create or update a package
         * @param {DefsPackage} request Package Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesCreatePackagePut(request: DefsPackage, options?: RawAxiosRequestConfig): AxiosPromise<DefsPackage> {
            return localVarFp.purchasesCreatePackagePut(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a package
         * @summary Delete a package
         * @param {string} id ID of the package
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesDeletePackageIdDelete(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.purchasesDeletePackageIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Reveal a user\'s photo using available views
         * @summary Reveal photo
         * @param {string} targetUserId Target User ID
         * @param {DefsRevealPhotoRequest} request Reveal Photo Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesTargetUserIdRevealPhotosPost(targetUserId: string, request: DefsRevealPhotoRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsRevealPhotoResponse> {
            return localVarFp.purchasesTargetUserIdRevealPhotosPost(targetUserId, request, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify purchase from Apple/Google and add views to user account
         * @summary Verify purchase and add views
         * @param {DefsVerifyApplePurchaseRequest} request Verify Purchase Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesVerifyApplePost(request: DefsVerifyApplePurchaseRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsVerifyPurchaseResponse> {
            return localVarFp.purchasesVerifyApplePost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Verify purchase from Apple/Google and add views to user account
         * @summary Verify purchase and add views
         * @param {DefsVerifyGooglePurchaseRequest} request Verify Purchase Request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesVerifyGooglePost(request: DefsVerifyGooglePurchaseRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsVerifyPurchaseResponse> {
            return localVarFp.purchasesVerifyGooglePost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all available view packages for purchase
         * @summary Get available packages
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchasesViewPackagesGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DefsPackage>> {
            return localVarFp.purchasesViewPackagesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchasesApi - object-oriented interface
 * @export
 * @class PurchasesApi
 * @extends {BaseAPI}
 */
export class PurchasesApi extends BaseAPI {
    /**
     * Create or update a package
     * @summary Create or update a package
     * @param {DefsPackage} request Package Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public purchasesCreatePackagePost(request: DefsPackage, options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).purchasesCreatePackagePost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or update a package
     * @summary Create or update a package
     * @param {DefsPackage} request Package Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public purchasesCreatePackagePut(request: DefsPackage, options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).purchasesCreatePackagePut(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a package
     * @summary Delete a package
     * @param {string} id ID of the package
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public purchasesDeletePackageIdDelete(id: string, options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).purchasesDeletePackageIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reveal a user\'s photo using available views
     * @summary Reveal photo
     * @param {string} targetUserId Target User ID
     * @param {DefsRevealPhotoRequest} request Reveal Photo Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public purchasesTargetUserIdRevealPhotosPost(targetUserId: string, request: DefsRevealPhotoRequest, options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).purchasesTargetUserIdRevealPhotosPost(targetUserId, request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify purchase from Apple/Google and add views to user account
     * @summary Verify purchase and add views
     * @param {DefsVerifyApplePurchaseRequest} request Verify Purchase Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public purchasesVerifyApplePost(request: DefsVerifyApplePurchaseRequest, options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).purchasesVerifyApplePost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Verify purchase from Apple/Google and add views to user account
     * @summary Verify purchase and add views
     * @param {DefsVerifyGooglePurchaseRequest} request Verify Purchase Request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public purchasesVerifyGooglePost(request: DefsVerifyGooglePurchaseRequest, options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).purchasesVerifyGooglePost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all available view packages for purchase
     * @summary Get available packages
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchasesApi
     */
    public purchasesViewPackagesGet(options?: RawAxiosRequestConfig) {
        return PurchasesApiFp(this.configuration).purchasesViewPackagesGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QuestionApi - axios parameter creator
 * @export
 */
export const QuestionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add question
         * @summary add question
         * @param {DefsQuestionReq} defsQuestionReq Question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionPost: async (defsQuestionReq: DefsQuestionReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsQuestionReq' is not null or undefined
            assertParamExists('questionPost', 'defsQuestionReq', defsQuestionReq)
            const localVarPath = `/question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsQuestionReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete question by id
         * @summary delete question
         * @param {string} qID qID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDDelete: async (qID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'qID' is not null or undefined
            assertParamExists('questionQIDDelete', 'qID', qID)
            const localVarPath = `/question/{qID}`
                .replace(`{${"qID"}}`, encodeURIComponent(String(qID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get question
         * @summary get question
         * @param {string} qID qID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDGet: async (qID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'qID' is not null or undefined
            assertParamExists('questionQIDGet', 'qID', qID)
            const localVarPath = `/question/{qID}`
                .replace(`{${"qID"}}`, encodeURIComponent(String(qID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add image to a specific question
         * @summary Add image to question
         * @param {string} qID Question ID
         * @param {File} pictureOne Image file one
         * @param {File} pictureTwo Image file two
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDImagePost: async (qID: string, pictureOne: File, pictureTwo: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'qID' is not null or undefined
            assertParamExists('questionQIDImagePost', 'qID', qID)
            // verify required parameter 'pictureOne' is not null or undefined
            assertParamExists('questionQIDImagePost', 'pictureOne', pictureOne)
            // verify required parameter 'pictureTwo' is not null or undefined
            assertParamExists('questionQIDImagePost', 'pictureTwo', pictureTwo)
            const localVarPath = `/question/{qID}/image`
                .replace(`{${"qID"}}`, encodeURIComponent(String(qID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


            if (pictureOne !== undefined) { 
                localVarFormParams.append('picture_one', pictureOne as any);
            }
    
            if (pictureTwo !== undefined) { 
                localVarFormParams.append('picture_two', pictureTwo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update question
         * @summary update question
         * @param {string} qID qID
         * @param {DefsQuestionReq} defsQuestionReq Question request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDPut: async (qID: string, defsQuestionReq: DefsQuestionReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'qID' is not null or undefined
            assertParamExists('questionQIDPut', 'qID', qID)
            // verify required parameter 'defsQuestionReq' is not null or undefined
            assertParamExists('questionQIDPut', 'defsQuestionReq', defsQuestionReq)
            const localVarPath = `/question/{qID}`
                .replace(`{${"qID"}}`, encodeURIComponent(String(qID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsQuestionReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to question
         * @param {string} qID Question ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDTranslationLocalePost: async (qID: string, locale: string, translation: HandlersTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'qID' is not null or undefined
            assertParamExists('questionQIDTranslationLocalePost', 'qID', qID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('questionQIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('questionQIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/question/{qID}/translation/{locale}`
                .replace(`{${"qID"}}`, encodeURIComponent(String(qID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionApi - functional programming interface
 * @export
 */
export const QuestionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionApiAxiosParamCreator(configuration)
    return {
        /**
         * Add question
         * @summary add question
         * @param {DefsQuestionReq} defsQuestionReq Question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionPost(defsQuestionReq: DefsQuestionReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionPost(defsQuestionReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.questionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete question by id
         * @summary delete question
         * @param {string} qID qID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionQIDDelete(qID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionQIDDelete(qID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.questionQIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get question
         * @summary get question
         * @param {string} qID qID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionQIDGet(qID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsQuestionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionQIDGet(qID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.questionQIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add image to a specific question
         * @summary Add image to question
         * @param {string} qID Question ID
         * @param {File} pictureOne Image file one
         * @param {File} pictureTwo Image file two
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionQIDImagePost(qID: string, pictureOne: File, pictureTwo: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsQuestionPicturesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionQIDImagePost(qID, pictureOne, pictureTwo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.questionQIDImagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update question
         * @summary update question
         * @param {string} qID qID
         * @param {DefsQuestionReq} defsQuestionReq Question request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionQIDPut(qID: string, defsQuestionReq: DefsQuestionReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionQIDPut(qID, defsQuestionReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.questionQIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to question
         * @param {string} qID Question ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionQIDTranslationLocalePost(qID: string, locale: string, translation: HandlersTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionQIDTranslationLocalePost(qID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionApi.questionQIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuestionApi - factory interface
 * @export
 */
export const QuestionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionApiFp(configuration)
    return {
        /**
         * Add question
         * @summary add question
         * @param {DefsQuestionReq} defsQuestionReq Question
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionPost(defsQuestionReq: DefsQuestionReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.questionPost(defsQuestionReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete question by id
         * @summary delete question
         * @param {string} qID qID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDDelete(qID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.questionQIDDelete(qID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get question
         * @summary get question
         * @param {string} qID qID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDGet(qID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsQuestionResponse> {
            return localVarFp.questionQIDGet(qID, options).then((request) => request(axios, basePath));
        },
        /**
         * Add image to a specific question
         * @summary Add image to question
         * @param {string} qID Question ID
         * @param {File} pictureOne Image file one
         * @param {File} pictureTwo Image file two
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDImagePost(qID: string, pictureOne: File, pictureTwo: File, options?: RawAxiosRequestConfig): AxiosPromise<DefsQuestionPicturesResponse> {
            return localVarFp.questionQIDImagePost(qID, pictureOne, pictureTwo, options).then((request) => request(axios, basePath));
        },
        /**
         * Update question
         * @summary update question
         * @param {string} qID qID
         * @param {DefsQuestionReq} defsQuestionReq Question request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDPut(qID: string, defsQuestionReq: DefsQuestionReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.questionQIDPut(qID, defsQuestionReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to question
         * @param {string} qID Question ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionQIDTranslationLocalePost(qID: string, locale: string, translation: HandlersTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.questionQIDTranslationLocalePost(qID, locale, translation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionApi - object-oriented interface
 * @export
 * @class QuestionApi
 * @extends {BaseAPI}
 */
export class QuestionApi extends BaseAPI {
    /**
     * Add question
     * @summary add question
     * @param {DefsQuestionReq} defsQuestionReq Question
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionPost(defsQuestionReq: DefsQuestionReq, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionPost(defsQuestionReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete question by id
     * @summary delete question
     * @param {string} qID qID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionQIDDelete(qID: string, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionQIDDelete(qID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get question
     * @summary get question
     * @param {string} qID qID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionQIDGet(qID: string, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionQIDGet(qID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add image to a specific question
     * @summary Add image to question
     * @param {string} qID Question ID
     * @param {File} pictureOne Image file one
     * @param {File} pictureTwo Image file two
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionQIDImagePost(qID: string, pictureOne: File, pictureTwo: File, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionQIDImagePost(qID, pictureOne, pictureTwo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update question
     * @summary update question
     * @param {string} qID qID
     * @param {DefsQuestionReq} defsQuestionReq Question request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionQIDPut(qID: string, defsQuestionReq: DefsQuestionReq, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionQIDPut(qID, defsQuestionReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to question
     * @param {string} qID Question ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionApi
     */
    public questionQIDTranslationLocalePost(qID: string, locale: string, translation: HandlersTranslationRequest, options?: RawAxiosRequestConfig) {
        return QuestionApiFp(this.configuration).questionQIDTranslationLocalePost(qID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * QuestionsApi - axios parameter creator
 * @export
 */
export const QuestionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete all questions
         * @summary delete all questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all questions
         * @summary get all questions
         * @param {string} [locale] Language code (e.g., bg, de)
         * @param {string} [s] search term
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsGet: async (locale?: string, s?: string, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update questions
         * @summary save of update questions
         * @param {DefsQuestionsData} defsQuestionsData Questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsPost: async (defsQuestionsData: DefsQuestionsData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsQuestionsData' is not null or undefined
            assertParamExists('questionsPost', 'defsQuestionsData', defsQuestionsData)
            const localVarPath = `/questions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsQuestionsData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get questions by type
         * @summary get questions by type
         * @param {string} qType question type
         * @param {string} [locale] Language code (e.g., bg, de)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsQTypeGet: async (qType: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'qType' is not null or undefined
            assertParamExists('questionsQTypeGet', 'qType', qType)
            const localVarPath = `/questions/{qType}`
                .replace(`{${"qType"}}`, encodeURIComponent(String(qType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * QuestionsApi - functional programming interface
 * @export
 */
export const QuestionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = QuestionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete all questions
         * @summary delete all questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionsApi.questionsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all questions
         * @summary get all questions
         * @param {string} [locale] Language code (e.g., bg, de)
         * @param {string} [s] search term
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsGet(locale?: string, s?: string, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsQuestionLisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsGet(locale, s, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionsApi.questionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update questions
         * @summary save of update questions
         * @param {DefsQuestionsData} defsQuestionsData Questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsPost(defsQuestionsData: DefsQuestionsData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsPost(defsQuestionsData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionsApi.questionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get questions by type
         * @summary get questions by type
         * @param {string} qType question type
         * @param {string} [locale] Language code (e.g., bg, de)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async questionsQTypeGet(qType: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsQuestionLisResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.questionsQTypeGet(qType, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['QuestionsApi.questionsQTypeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * QuestionsApi - factory interface
 * @export
 */
export const QuestionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = QuestionsApiFp(configuration)
    return {
        /**
         * Delete all questions
         * @summary delete all questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsDelete(options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.questionsDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all questions
         * @summary get all questions
         * @param {string} [locale] Language code (e.g., bg, de)
         * @param {string} [s] search term
         * @param {number} [limit] limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsGet(locale?: string, s?: string, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsQuestionLisResponse> {
            return localVarFp.questionsGet(locale, s, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update questions
         * @summary save of update questions
         * @param {DefsQuestionsData} defsQuestionsData Questions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsPost(defsQuestionsData: DefsQuestionsData, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.questionsPost(defsQuestionsData, options).then((request) => request(axios, basePath));
        },
        /**
         * Get questions by type
         * @summary get questions by type
         * @param {string} qType question type
         * @param {string} [locale] Language code (e.g., bg, de)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        questionsQTypeGet(qType: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsQuestionLisResponse> {
            return localVarFp.questionsQTypeGet(qType, locale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * QuestionsApi - object-oriented interface
 * @export
 * @class QuestionsApi
 * @extends {BaseAPI}
 */
export class QuestionsApi extends BaseAPI {
    /**
     * Delete all questions
     * @summary delete all questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsDelete(options?: RawAxiosRequestConfig) {
        return QuestionsApiFp(this.configuration).questionsDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all questions
     * @summary get all questions
     * @param {string} [locale] Language code (e.g., bg, de)
     * @param {string} [s] search term
     * @param {number} [limit] limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsGet(locale?: string, s?: string, limit?: number, options?: RawAxiosRequestConfig) {
        return QuestionsApiFp(this.configuration).questionsGet(locale, s, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update questions
     * @summary save of update questions
     * @param {DefsQuestionsData} defsQuestionsData Questions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsPost(defsQuestionsData: DefsQuestionsData, options?: RawAxiosRequestConfig) {
        return QuestionsApiFp(this.configuration).questionsPost(defsQuestionsData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get questions by type
     * @summary get questions by type
     * @param {string} qType question type
     * @param {string} [locale] Language code (e.g., bg, de)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof QuestionsApi
     */
    public questionsQTypeGet(qType: string, locale?: string, options?: RawAxiosRequestConfig) {
        return QuestionsApiFp(this.configuration).questionsQTypeGet(qType, locale, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RacesApi - axios parameter creator
 * @export
 */
export const RacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all races
         * @summary get all races
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/races`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update race
         * @summary save or update race
         * @param {DefsRaceData} defsRaceData Race
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesPost: async (defsRaceData: DefsRaceData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsRaceData' is not null or undefined
            assertParamExists('racesPost', 'defsRaceData', defsRaceData)
            const localVarPath = `/races`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsRaceData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete race by id
         * @summary delete race
         * @param {string} rID rID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesRIDDelete: async (rID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rID' is not null or undefined
            assertParamExists('racesRIDDelete', 'rID', rID)
            const localVarPath = `/races/{rID}`
                .replace(`{${"rID"}}`, encodeURIComponent(String(rID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get race by id
         * @summary get race
         * @param {string} rID rID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesRIDGet: async (rID: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rID' is not null or undefined
            assertParamExists('racesRIDGet', 'rID', rID)
            const localVarPath = `/races/{rID}`
                .replace(`{${"rID"}}`, encodeURIComponent(String(rID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update race
         * @summary save or update race
         * @param {string} rID rID
         * @param {DefsRaceData} defsRaceData Race
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesRIDPut: async (rID: string, defsRaceData: DefsRaceData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rID' is not null or undefined
            assertParamExists('racesRIDPut', 'rID', rID)
            // verify required parameter 'defsRaceData' is not null or undefined
            assertParamExists('racesRIDPut', 'defsRaceData', defsRaceData)
            const localVarPath = `/races/{rID}`
                .replace(`{${"rID"}}`, encodeURIComponent(String(rID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsRaceData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to race
         * @param {string} rID Race ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersRaceTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesRIDTranslationLocalePost: async (rID: string, locale: string, translation: HandlersRaceTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rID' is not null or undefined
            assertParamExists('racesRIDTranslationLocalePost', 'rID', rID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('racesRIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('racesRIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/races/{rID}/translation/{locale}`
                .replace(`{${"rID"}}`, encodeURIComponent(String(rID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RacesApi - functional programming interface
 * @export
 */
export const RacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RacesApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all races
         * @summary get all races
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racesGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsRaceListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racesGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RacesApi.racesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update race
         * @summary save or update race
         * @param {DefsRaceData} defsRaceData Race
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racesPost(defsRaceData: DefsRaceData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racesPost(defsRaceData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RacesApi.racesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete race by id
         * @summary delete race
         * @param {string} rID rID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racesRIDDelete(rID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racesRIDDelete(rID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RacesApi.racesRIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get race by id
         * @summary get race
         * @param {string} rID rID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racesRIDGet(rID: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsRaceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racesRIDGet(rID, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RacesApi.racesRIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update race
         * @summary save or update race
         * @param {string} rID rID
         * @param {DefsRaceData} defsRaceData Race
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racesRIDPut(rID: string, defsRaceData: DefsRaceData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racesRIDPut(rID, defsRaceData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RacesApi.racesRIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to race
         * @param {string} rID Race ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersRaceTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async racesRIDTranslationLocalePost(rID: string, locale: string, translation: HandlersRaceTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.racesRIDTranslationLocalePost(rID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RacesApi.racesRIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RacesApi - factory interface
 * @export
 */
export const RacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RacesApiFp(configuration)
    return {
        /**
         * Get all races
         * @summary get all races
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsRaceListResponse> {
            return localVarFp.racesGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update race
         * @summary save or update race
         * @param {DefsRaceData} defsRaceData Race
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesPost(defsRaceData: DefsRaceData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.racesPost(defsRaceData, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete race by id
         * @summary delete race
         * @param {string} rID rID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesRIDDelete(rID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.racesRIDDelete(rID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get race by id
         * @summary get race
         * @param {string} rID rID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesRIDGet(rID: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsRaceResponse> {
            return localVarFp.racesRIDGet(rID, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update race
         * @summary save or update race
         * @param {string} rID rID
         * @param {DefsRaceData} defsRaceData Race
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesRIDPut(rID: string, defsRaceData: DefsRaceData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.racesRIDPut(rID, defsRaceData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to race
         * @param {string} rID Race ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersRaceTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        racesRIDTranslationLocalePost(rID: string, locale: string, translation: HandlersRaceTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.racesRIDTranslationLocalePost(rID, locale, translation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RacesApi - object-oriented interface
 * @export
 * @class RacesApi
 * @extends {BaseAPI}
 */
export class RacesApi extends BaseAPI {
    /**
     * Get all races
     * @summary get all races
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacesApi
     */
    public racesGet(locale?: string, options?: RawAxiosRequestConfig) {
        return RacesApiFp(this.configuration).racesGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update race
     * @summary save or update race
     * @param {DefsRaceData} defsRaceData Race
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacesApi
     */
    public racesPost(defsRaceData: DefsRaceData, options?: RawAxiosRequestConfig) {
        return RacesApiFp(this.configuration).racesPost(defsRaceData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete race by id
     * @summary delete race
     * @param {string} rID rID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacesApi
     */
    public racesRIDDelete(rID: string, options?: RawAxiosRequestConfig) {
        return RacesApiFp(this.configuration).racesRIDDelete(rID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get race by id
     * @summary get race
     * @param {string} rID rID
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacesApi
     */
    public racesRIDGet(rID: string, locale?: string, options?: RawAxiosRequestConfig) {
        return RacesApiFp(this.configuration).racesRIDGet(rID, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update race
     * @summary save or update race
     * @param {string} rID rID
     * @param {DefsRaceData} defsRaceData Race
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacesApi
     */
    public racesRIDPut(rID: string, defsRaceData: DefsRaceData, options?: RawAxiosRequestConfig) {
        return RacesApiFp(this.configuration).racesRIDPut(rID, defsRaceData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to race
     * @param {string} rID Race ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersRaceTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RacesApi
     */
    public racesRIDTranslationLocalePost(rID: string, locale: string, translation: HandlersRaceTranslationRequest, options?: RawAxiosRequestConfig) {
        return RacesApiFp(this.configuration).racesRIDTranslationLocalePost(rID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete user settings by user-id
         * @summary delete user settings
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSettingsDelete: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDSettingsDelete', 'userID', userID)
            const localVarPath = `/users/{userID}/settings`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user settings by user-id
         * @summary get user settings
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSettingsGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDSettingsGet', 'userID', userID)
            const localVarPath = `/users/{userID}/settings`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add or update user settings by userID
         * @summary save or update user settings
         * @param {string} userID userID
         * @param {DefsUserSettingsData} defsUserSettingsData UserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSettingsPost: async (userID: string, defsUserSettingsData: DefsUserSettingsData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDSettingsPost', 'userID', userID)
            // verify required parameter 'defsUserSettingsData' is not null or undefined
            assertParamExists('usersUserIDSettingsPost', 'defsUserSettingsData', defsUserSettingsData)
            const localVarPath = `/users/{userID}/settings`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUserSettingsData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add or update user settings by userID
         * @summary save or update user settings
         * @param {string} userID userID
         * @param {DefsUserSettingsData} defsUserSettingsData UserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSettingsPut: async (userID: string, defsUserSettingsData: DefsUserSettingsData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDSettingsPut', 'userID', userID)
            // verify required parameter 'defsUserSettingsData' is not null or undefined
            assertParamExists('usersUserIDSettingsPut', 'defsUserSettingsData', defsUserSettingsData)
            const localVarPath = `/users/{userID}/settings`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUserSettingsData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete user settings by user-id
         * @summary delete user settings
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDSettingsDelete(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDSettingsDelete(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.usersUserIDSettingsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user settings by user-id
         * @summary get user settings
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDSettingsGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserSettingsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDSettingsGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.usersUserIDSettingsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add or update user settings by userID
         * @summary save or update user settings
         * @param {string} userID userID
         * @param {DefsUserSettingsData} defsUserSettingsData UserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDSettingsPost(userID: string, defsUserSettingsData: DefsUserSettingsData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDSettingsPost(userID, defsUserSettingsData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.usersUserIDSettingsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add or update user settings by userID
         * @summary save or update user settings
         * @param {string} userID userID
         * @param {DefsUserSettingsData} defsUserSettingsData UserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDSettingsPut(userID: string, defsUserSettingsData: DefsUserSettingsData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDSettingsPut(userID, defsUserSettingsData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SettingsApi.usersUserIDSettingsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * Delete user settings by user-id
         * @summary delete user settings
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSettingsDelete(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDSettingsDelete(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user settings by user-id
         * @summary get user settings
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSettingsGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserSettingsResponse> {
            return localVarFp.usersUserIDSettingsGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Add or update user settings by userID
         * @summary save or update user settings
         * @param {string} userID userID
         * @param {DefsUserSettingsData} defsUserSettingsData UserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSettingsPost(userID: string, defsUserSettingsData: DefsUserSettingsData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.usersUserIDSettingsPost(userID, defsUserSettingsData, options).then((request) => request(axios, basePath));
        },
        /**
         * Add or update user settings by userID
         * @summary save or update user settings
         * @param {string} userID userID
         * @param {DefsUserSettingsData} defsUserSettingsData UserSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSettingsPut(userID: string, defsUserSettingsData: DefsUserSettingsData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.usersUserIDSettingsPut(userID, defsUserSettingsData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * Delete user settings by user-id
     * @summary delete user settings
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public usersUserIDSettingsDelete(userID: string, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).usersUserIDSettingsDelete(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user settings by user-id
     * @summary get user settings
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public usersUserIDSettingsGet(userID: string, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).usersUserIDSettingsGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add or update user settings by userID
     * @summary save or update user settings
     * @param {string} userID userID
     * @param {DefsUserSettingsData} defsUserSettingsData UserSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public usersUserIDSettingsPost(userID: string, defsUserSettingsData: DefsUserSettingsData, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).usersUserIDSettingsPost(userID, defsUserSettingsData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add or update user settings by userID
     * @summary save or update user settings
     * @param {string} userID userID
     * @param {DefsUserSettingsData} defsUserSettingsData UserSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public usersUserIDSettingsPut(userID: string, defsUserSettingsData: DefsUserSettingsData, options?: RawAxiosRequestConfig) {
        return SettingsApiFp(this.configuration).usersUserIDSettingsPut(userID, defsUserSettingsData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SexualOrientationsApi - axios parameter creator
 * @export
 */
export const SexualOrientationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all sexual orientations
         * @summary get all sexual orientations
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsGet: async (locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sexual-orientations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update sexual orientation
         * @summary save or update sexual orientation
         * @param {DefsSexualOrientationData} defsSexualOrientationData Sexual Orientation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsPost: async (defsSexualOrientationData: DefsSexualOrientationData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsSexualOrientationData' is not null or undefined
            assertParamExists('sexualOrientationsPost', 'defsSexualOrientationData', defsSexualOrientationData)
            const localVarPath = `/sexual-orientations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsSexualOrientationData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete sexual orientation by id
         * @summary delete sexual orientation
         * @param {string} soID soID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsSoIDDelete: async (soID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'soID' is not null or undefined
            assertParamExists('sexualOrientationsSoIDDelete', 'soID', soID)
            const localVarPath = `/sexual-orientations/{soID}`
                .replace(`{${"soID"}}`, encodeURIComponent(String(soID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sexual orientation by id
         * @summary get sexual orientation
         * @param {string} soID soID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsSoIDGet: async (soID: string, locale?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'soID' is not null or undefined
            assertParamExists('sexualOrientationsSoIDGet', 'soID', soID)
            const localVarPath = `/sexual-orientations/{soID}`
                .replace(`{${"soID"}}`, encodeURIComponent(String(soID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (locale !== undefined) {
                localVarQueryParameter['locale'] = locale;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save or update sexual orientation
         * @summary save or update sexual orientation
         * @param {string} soID soID
         * @param {DefsSexualOrientationData} defsSexualOrientationData Sexual Orientation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsSoIDPut: async (soID: string, defsSexualOrientationData: DefsSexualOrientationData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'soID' is not null or undefined
            assertParamExists('sexualOrientationsSoIDPut', 'soID', soID)
            // verify required parameter 'defsSexualOrientationData' is not null or undefined
            assertParamExists('sexualOrientationsSoIDPut', 'defsSexualOrientationData', defsSexualOrientationData)
            const localVarPath = `/sexual-orientations/{soID}`
                .replace(`{${"soID"}}`, encodeURIComponent(String(soID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsSexualOrientationData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add translation to sexual orientation
         * @param {string} soID Sexual Orientation ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersSexualOrientationTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsSoIDTranslationLocalePost: async (soID: string, locale: string, translation: HandlersSexualOrientationTranslationRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'soID' is not null or undefined
            assertParamExists('sexualOrientationsSoIDTranslationLocalePost', 'soID', soID)
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('sexualOrientationsSoIDTranslationLocalePost', 'locale', locale)
            // verify required parameter 'translation' is not null or undefined
            assertParamExists('sexualOrientationsSoIDTranslationLocalePost', 'translation', translation)
            const localVarPath = `/sexual-orientations/{soID}/translation/{locale}`
                .replace(`{${"soID"}}`, encodeURIComponent(String(soID)))
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(translation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SexualOrientationsApi - functional programming interface
 * @export
 */
export const SexualOrientationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SexualOrientationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all sexual orientations
         * @summary get all sexual orientations
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sexualOrientationsGet(locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsSexualOrientationListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sexualOrientationsGet(locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SexualOrientationsApi.sexualOrientationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update sexual orientation
         * @summary save or update sexual orientation
         * @param {DefsSexualOrientationData} defsSexualOrientationData Sexual Orientation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sexualOrientationsPost(defsSexualOrientationData: DefsSexualOrientationData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sexualOrientationsPost(defsSexualOrientationData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SexualOrientationsApi.sexualOrientationsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete sexual orientation by id
         * @summary delete sexual orientation
         * @param {string} soID soID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sexualOrientationsSoIDDelete(soID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sexualOrientationsSoIDDelete(soID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SexualOrientationsApi.sexualOrientationsSoIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get sexual orientation by id
         * @summary get sexual orientation
         * @param {string} soID soID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sexualOrientationsSoIDGet(soID: string, locale?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsSexualOrientationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sexualOrientationsSoIDGet(soID, locale, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SexualOrientationsApi.sexualOrientationsSoIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save or update sexual orientation
         * @summary save or update sexual orientation
         * @param {string} soID soID
         * @param {DefsSexualOrientationData} defsSexualOrientationData Sexual Orientation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sexualOrientationsSoIDPut(soID: string, defsSexualOrientationData: DefsSexualOrientationData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sexualOrientationsSoIDPut(soID, defsSexualOrientationData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SexualOrientationsApi.sexualOrientationsSoIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Add translation to sexual orientation
         * @param {string} soID Sexual Orientation ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersSexualOrientationTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sexualOrientationsSoIDTranslationLocalePost(soID: string, locale: string, translation: HandlersSexualOrientationTranslationRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sexualOrientationsSoIDTranslationLocalePost(soID, locale, translation, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SexualOrientationsApi.sexualOrientationsSoIDTranslationLocalePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SexualOrientationsApi - factory interface
 * @export
 */
export const SexualOrientationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SexualOrientationsApiFp(configuration)
    return {
        /**
         * Get all sexual orientations
         * @summary get all sexual orientations
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsGet(locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsSexualOrientationListResponse> {
            return localVarFp.sexualOrientationsGet(locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update sexual orientation
         * @summary save or update sexual orientation
         * @param {DefsSexualOrientationData} defsSexualOrientationData Sexual Orientation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsPost(defsSexualOrientationData: DefsSexualOrientationData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.sexualOrientationsPost(defsSexualOrientationData, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete sexual orientation by id
         * @summary delete sexual orientation
         * @param {string} soID soID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsSoIDDelete(soID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.sexualOrientationsSoIDDelete(soID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get sexual orientation by id
         * @summary get sexual orientation
         * @param {string} soID soID
         * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsSoIDGet(soID: string, locale?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsSexualOrientationResponse> {
            return localVarFp.sexualOrientationsSoIDGet(soID, locale, options).then((request) => request(axios, basePath));
        },
        /**
         * Save or update sexual orientation
         * @summary save or update sexual orientation
         * @param {string} soID soID
         * @param {DefsSexualOrientationData} defsSexualOrientationData Sexual Orientation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsSoIDPut(soID: string, defsSexualOrientationData: DefsSexualOrientationData, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.sexualOrientationsSoIDPut(soID, defsSexualOrientationData, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Add translation to sexual orientation
         * @param {string} soID Sexual Orientation ID
         * @param {string} locale Language code (e.g., bg, de)
         * @param {HandlersSexualOrientationTranslationRequest} translation Translation data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sexualOrientationsSoIDTranslationLocalePost(soID: string, locale: string, translation: HandlersSexualOrientationTranslationRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.sexualOrientationsSoIDTranslationLocalePost(soID, locale, translation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SexualOrientationsApi - object-oriented interface
 * @export
 * @class SexualOrientationsApi
 * @extends {BaseAPI}
 */
export class SexualOrientationsApi extends BaseAPI {
    /**
     * Get all sexual orientations
     * @summary get all sexual orientations
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SexualOrientationsApi
     */
    public sexualOrientationsGet(locale?: string, options?: RawAxiosRequestConfig) {
        return SexualOrientationsApiFp(this.configuration).sexualOrientationsGet(locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update sexual orientation
     * @summary save or update sexual orientation
     * @param {DefsSexualOrientationData} defsSexualOrientationData Sexual Orientation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SexualOrientationsApi
     */
    public sexualOrientationsPost(defsSexualOrientationData: DefsSexualOrientationData, options?: RawAxiosRequestConfig) {
        return SexualOrientationsApiFp(this.configuration).sexualOrientationsPost(defsSexualOrientationData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete sexual orientation by id
     * @summary delete sexual orientation
     * @param {string} soID soID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SexualOrientationsApi
     */
    public sexualOrientationsSoIDDelete(soID: string, options?: RawAxiosRequestConfig) {
        return SexualOrientationsApiFp(this.configuration).sexualOrientationsSoIDDelete(soID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sexual orientation by id
     * @summary get sexual orientation
     * @param {string} soID soID
     * @param {string} [locale] Language code (e.g., bg, de). Defaults to \&#39;en\&#39;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SexualOrientationsApi
     */
    public sexualOrientationsSoIDGet(soID: string, locale?: string, options?: RawAxiosRequestConfig) {
        return SexualOrientationsApiFp(this.configuration).sexualOrientationsSoIDGet(soID, locale, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save or update sexual orientation
     * @summary save or update sexual orientation
     * @param {string} soID soID
     * @param {DefsSexualOrientationData} defsSexualOrientationData Sexual Orientation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SexualOrientationsApi
     */
    public sexualOrientationsSoIDPut(soID: string, defsSexualOrientationData: DefsSexualOrientationData, options?: RawAxiosRequestConfig) {
        return SexualOrientationsApiFp(this.configuration).sexualOrientationsSoIDPut(soID, defsSexualOrientationData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Add translation to sexual orientation
     * @param {string} soID Sexual Orientation ID
     * @param {string} locale Language code (e.g., bg, de)
     * @param {HandlersSexualOrientationTranslationRequest} translation Translation data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SexualOrientationsApi
     */
    public sexualOrientationsSoIDTranslationLocalePost(soID: string, locale: string, translation: HandlersSexualOrientationTranslationRequest, options?: RawAxiosRequestConfig) {
        return SexualOrientationsApiFp(this.configuration).sexualOrientationsSoIDTranslationLocalePost(soID, locale, translation, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * StatsApi - axios parameter creator
 * @export
 */
export const StatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get active users
         * @summary Get active users
         * @param {number} duration Duration
         * @param {StatsActiveUsersGetUnitEnum} unit Unit
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsActiveUsersGet: async (duration: number, unit: StatsActiveUsersGetUnitEnum, search?: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'duration' is not null or undefined
            assertParamExists('statsActiveUsersGet', 'duration', duration)
            // verify required parameter 'unit' is not null or undefined
            assertParamExists('statsActiveUsersGet', 'unit', unit)
            const localVarPath = `/stats/active-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (unit !== undefined) {
                localVarQueryParameter['unit'] = unit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Filter user by criteria
         * @summary Filter user by criteria
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {number} [ageMin] Minimum age
         * @param {number} [ageMax] Maximum age
         * @param {number} [heightMin] Minimum height
         * @param {number} [heightMax] Maximum height
         * @param {number} [incomeMin] Minimum income
         * @param {number} [incomeMax] Maximum income
         * @param {boolean} [smoking] Smoking
         * @param {boolean} [drinking] Drinking
         * @param {boolean} [haveChildren] Have children
         * @param {boolean} [wantChildren] Want children
         * @param {boolean} [likePets] Like pets
         * @param {boolean} [havePets] Have pets
         * @param {string} [educationId] Education ID
         * @param {string} [genderId] Gender ID
         * @param {string} [zodiacSign] Zodiac sign
         * @param {string} [bodyTypeId] Body type ID
         * @param {string} [sexOrientationId] Sex orientation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsFilterUserByCriteriaGet: async (page?: number, limit?: number, ageMin?: number, ageMax?: number, heightMin?: number, heightMax?: number, incomeMin?: number, incomeMax?: number, smoking?: boolean, drinking?: boolean, haveChildren?: boolean, wantChildren?: boolean, likePets?: boolean, havePets?: boolean, educationId?: string, genderId?: string, zodiacSign?: string, bodyTypeId?: string, sexOrientationId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/filter-user-by-criteria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (ageMin !== undefined) {
                localVarQueryParameter['age_min'] = ageMin;
            }

            if (ageMax !== undefined) {
                localVarQueryParameter['age_max'] = ageMax;
            }

            if (heightMin !== undefined) {
                localVarQueryParameter['height_min'] = heightMin;
            }

            if (heightMax !== undefined) {
                localVarQueryParameter['height_max'] = heightMax;
            }

            if (incomeMin !== undefined) {
                localVarQueryParameter['income_min'] = incomeMin;
            }

            if (incomeMax !== undefined) {
                localVarQueryParameter['income_max'] = incomeMax;
            }

            if (smoking !== undefined) {
                localVarQueryParameter['smoking'] = smoking;
            }

            if (drinking !== undefined) {
                localVarQueryParameter['drinking'] = drinking;
            }

            if (haveChildren !== undefined) {
                localVarQueryParameter['have_children'] = haveChildren;
            }

            if (wantChildren !== undefined) {
                localVarQueryParameter['want_children'] = wantChildren;
            }

            if (likePets !== undefined) {
                localVarQueryParameter['like_pets'] = likePets;
            }

            if (havePets !== undefined) {
                localVarQueryParameter['have_pets'] = havePets;
            }

            if (educationId !== undefined) {
                localVarQueryParameter['education_id'] = educationId;
            }

            if (genderId !== undefined) {
                localVarQueryParameter['gender_id'] = genderId;
            }

            if (zodiacSign !== undefined) {
                localVarQueryParameter['zodiac_sign'] = zodiacSign;
            }

            if (bodyTypeId !== undefined) {
                localVarQueryParameter['body_type_id'] = bodyTypeId;
            }

            if (sexOrientationId !== undefined) {
                localVarQueryParameter['sex_orientation_id'] = sexOrientationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get inactive users
         * @summary Get inactive users
         * @param {number} duration Duration
         * @param {StatsInactiveUsersGetUnitEnum} unit Unit
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsInactiveUsersGet: async (duration: number, unit: StatsInactiveUsersGetUnitEnum, search?: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'duration' is not null or undefined
            assertParamExists('statsInactiveUsersGet', 'duration', duration)
            // verify required parameter 'unit' is not null or undefined
            assertParamExists('statsInactiveUsersGet', 'unit', unit)
            const localVarPath = `/stats/inactive-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (unit !== undefined) {
                localVarQueryParameter['unit'] = unit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get last active date
         * @summary Get last active date
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsLastActiveDateUserIDGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('statsLastActiveDateUserIDGet', 'userID', userID)
            const localVarPath = `/stats/last-active-date/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get open chats for user
         * @summary Get open chats for user
         * @param {string} userID User ID
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsOpenChatsUserIDGet: async (userID: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('statsOpenChatsUserIDGet', 'userID', userID)
            const localVarPath = `/stats/open-chats/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get recent activities
         * @summary Get recent activities
         * @param {string} userID User ID
         * @param {number} duration Duration
         * @param {StatsRecentActivitiesUserIDGetUnitEnum} unit Unit
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsRecentActivitiesUserIDGet: async (userID: string, duration: number, unit: StatsRecentActivitiesUserIDGetUnitEnum, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('statsRecentActivitiesUserIDGet', 'userID', userID)
            // verify required parameter 'duration' is not null or undefined
            assertParamExists('statsRecentActivitiesUserIDGet', 'duration', duration)
            // verify required parameter 'unit' is not null or undefined
            assertParamExists('statsRecentActivitiesUserIDGet', 'unit', unit)
            const localVarPath = `/stats/recent-activities/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (unit !== undefined) {
                localVarQueryParameter['unit'] = unit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get top chatters
         * @summary Get top chatters
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsTopChattersGet: async (search?: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/top-chatters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get top receivers
         * @summary Get top receivers
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsTopReceiversGet: async (search?: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/top-receivers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users in location
         * @summary Get users in location
         * @param {number} lat Latitude
         * @param {number} _long Longitude
         * @param {number} [km] Kilometers
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersInLocationGet: async (lat: number, _long: number, km?: number, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lat' is not null or undefined
            assertParamExists('statsUsersInLocationGet', 'lat', lat)
            // verify required parameter '_long' is not null or undefined
            assertParamExists('statsUsersInLocationGet', '_long', _long)
            const localVarPath = `/stats/users-in-location`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (lat !== undefined) {
                localVarQueryParameter['lat'] = lat;
            }

            if (_long !== undefined) {
                localVarQueryParameter['long'] = _long;
            }

            if (km !== undefined) {
                localVarQueryParameter['km'] = km;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users matches
         * @summary Get users matches
         * @param {string} [search] Search
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersMatchesGet: async (search?: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/users-matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users with full profile
         * @summary Get users with full profile
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersWithFullProfileGet: async (search?: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/users-with-full-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users without profile
         * @summary Get users without profile
         * @param {string} [search] Search by: first name, last name, email, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersWithoutProfileGet: async (search?: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/users-without-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get users without test result
         * @summary Get users without test result
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersWithoutTestResultGet: async (search?: string, page?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats/users-without-test-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatsApi - functional programming interface
 * @export
 */
export const StatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get active users
         * @summary Get active users
         * @param {number} duration Duration
         * @param {StatsActiveUsersGetUnitEnum} unit Unit
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsActiveUsersGet(duration: number, unit: StatsActiveUsersGetUnitEnum, search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserActiveListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsActiveUsersGet(duration, unit, search, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsActiveUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Filter user by criteria
         * @summary Filter user by criteria
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {number} [ageMin] Minimum age
         * @param {number} [ageMax] Maximum age
         * @param {number} [heightMin] Minimum height
         * @param {number} [heightMax] Maximum height
         * @param {number} [incomeMin] Minimum income
         * @param {number} [incomeMax] Maximum income
         * @param {boolean} [smoking] Smoking
         * @param {boolean} [drinking] Drinking
         * @param {boolean} [haveChildren] Have children
         * @param {boolean} [wantChildren] Want children
         * @param {boolean} [likePets] Like pets
         * @param {boolean} [havePets] Have pets
         * @param {string} [educationId] Education ID
         * @param {string} [genderId] Gender ID
         * @param {string} [zodiacSign] Zodiac sign
         * @param {string} [bodyTypeId] Body type ID
         * @param {string} [sexOrientationId] Sex orientation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsFilterUserByCriteriaGet(page?: number, limit?: number, ageMin?: number, ageMax?: number, heightMin?: number, heightMax?: number, incomeMin?: number, incomeMax?: number, smoking?: boolean, drinking?: boolean, haveChildren?: boolean, wantChildren?: boolean, likePets?: boolean, havePets?: boolean, educationId?: string, genderId?: string, zodiacSign?: string, bodyTypeId?: string, sexOrientationId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsProfileListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsFilterUserByCriteriaGet(page, limit, ageMin, ageMax, heightMin, heightMax, incomeMin, incomeMax, smoking, drinking, haveChildren, wantChildren, likePets, havePets, educationId, genderId, zodiacSign, bodyTypeId, sexOrientationId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsFilterUserByCriteriaGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get inactive users
         * @summary Get inactive users
         * @param {number} duration Duration
         * @param {StatsInactiveUsersGetUnitEnum} unit Unit
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsInactiveUsersGet(duration: number, unit: StatsInactiveUsersGetUnitEnum, search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsInactiveUsersGet(duration, unit, search, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsInactiveUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get last active date
         * @summary Get last active date
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsLastActiveDateUserIDGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsLastActiveUserData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsLastActiveDateUserIDGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsLastActiveDateUserIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get open chats for user
         * @summary Get open chats for user
         * @param {string} userID User ID
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsOpenChatsUserIDGet(userID: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsChatListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsOpenChatsUserIDGet(userID, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsOpenChatsUserIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get recent activities
         * @summary Get recent activities
         * @param {string} userID User ID
         * @param {number} duration Duration
         * @param {StatsRecentActivitiesUserIDGetUnitEnum} unit Unit
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsRecentActivitiesUserIDGet(userID: string, duration: number, unit: StatsRecentActivitiesUserIDGetUnitEnum, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserActivityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsRecentActivitiesUserIDGet(userID, duration, unit, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsRecentActivitiesUserIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get top chatters
         * @summary Get top chatters
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsTopChattersGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsChatListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsTopChattersGet(search, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsTopChattersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get top receivers
         * @summary Get top receivers
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsTopReceiversGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsChatListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsTopReceiversGet(search, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsTopReceiversGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get users in location
         * @summary Get users in location
         * @param {number} lat Latitude
         * @param {number} _long Longitude
         * @param {number} [km] Kilometers
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsUsersInLocationGet(lat: number, _long: number, km?: number, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsUsersInLocationGet(lat, _long, km, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsUsersInLocationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get users matches
         * @summary Get users matches
         * @param {string} [search] Search
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsUsersMatchesGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsSimpleMatchListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsUsersMatchesGet(search, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsUsersMatchesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get users with full profile
         * @summary Get users with full profile
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsUsersWithFullProfileGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsUsersWithFullProfileGet(search, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsUsersWithFullProfileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get users without profile
         * @summary Get users without profile
         * @param {string} [search] Search by: first name, last name, email, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsUsersWithoutProfileGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsUsersWithoutProfileGet(search, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsUsersWithoutProfileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get users without test result
         * @summary Get users without test result
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statsUsersWithoutTestResultGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statsUsersWithoutTestResultGet(search, page, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StatsApi.statsUsersWithoutTestResultGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StatsApi - factory interface
 * @export
 */
export const StatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatsApiFp(configuration)
    return {
        /**
         * Get active users
         * @summary Get active users
         * @param {number} duration Duration
         * @param {StatsActiveUsersGetUnitEnum} unit Unit
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsActiveUsersGet(duration: number, unit: StatsActiveUsersGetUnitEnum, search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserActiveListResponse> {
            return localVarFp.statsActiveUsersGet(duration, unit, search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Filter user by criteria
         * @summary Filter user by criteria
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {number} [ageMin] Minimum age
         * @param {number} [ageMax] Maximum age
         * @param {number} [heightMin] Minimum height
         * @param {number} [heightMax] Maximum height
         * @param {number} [incomeMin] Minimum income
         * @param {number} [incomeMax] Maximum income
         * @param {boolean} [smoking] Smoking
         * @param {boolean} [drinking] Drinking
         * @param {boolean} [haveChildren] Have children
         * @param {boolean} [wantChildren] Want children
         * @param {boolean} [likePets] Like pets
         * @param {boolean} [havePets] Have pets
         * @param {string} [educationId] Education ID
         * @param {string} [genderId] Gender ID
         * @param {string} [zodiacSign] Zodiac sign
         * @param {string} [bodyTypeId] Body type ID
         * @param {string} [sexOrientationId] Sex orientation ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsFilterUserByCriteriaGet(page?: number, limit?: number, ageMin?: number, ageMax?: number, heightMin?: number, heightMax?: number, incomeMin?: number, incomeMax?: number, smoking?: boolean, drinking?: boolean, haveChildren?: boolean, wantChildren?: boolean, likePets?: boolean, havePets?: boolean, educationId?: string, genderId?: string, zodiacSign?: string, bodyTypeId?: string, sexOrientationId?: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsProfileListResponse> {
            return localVarFp.statsFilterUserByCriteriaGet(page, limit, ageMin, ageMax, heightMin, heightMax, incomeMin, incomeMax, smoking, drinking, haveChildren, wantChildren, likePets, havePets, educationId, genderId, zodiacSign, bodyTypeId, sexOrientationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get inactive users
         * @summary Get inactive users
         * @param {number} duration Duration
         * @param {StatsInactiveUsersGetUnitEnum} unit Unit
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsInactiveUsersGet(duration: number, unit: StatsInactiveUsersGetUnitEnum, search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserListResponse> {
            return localVarFp.statsInactiveUsersGet(duration, unit, search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get last active date
         * @summary Get last active date
         * @param {string} userID User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsLastActiveDateUserIDGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsLastActiveUserData> {
            return localVarFp.statsLastActiveDateUserIDGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get open chats for user
         * @summary Get open chats for user
         * @param {string} userID User ID
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsOpenChatsUserIDGet(userID: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsChatListResponse> {
            return localVarFp.statsOpenChatsUserIDGet(userID, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get recent activities
         * @summary Get recent activities
         * @param {string} userID User ID
         * @param {number} duration Duration
         * @param {StatsRecentActivitiesUserIDGetUnitEnum} unit Unit
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsRecentActivitiesUserIDGet(userID: string, duration: number, unit: StatsRecentActivitiesUserIDGetUnitEnum, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserActivityListResponse> {
            return localVarFp.statsRecentActivitiesUserIDGet(userID, duration, unit, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get top chatters
         * @summary Get top chatters
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsTopChattersGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsChatListResponse> {
            return localVarFp.statsTopChattersGet(search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get top receivers
         * @summary Get top receivers
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsTopReceiversGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsChatListResponse> {
            return localVarFp.statsTopReceiversGet(search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users in location
         * @summary Get users in location
         * @param {number} lat Latitude
         * @param {number} _long Longitude
         * @param {number} [km] Kilometers
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersInLocationGet(lat: number, _long: number, km?: number, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserListResponse> {
            return localVarFp.statsUsersInLocationGet(lat, _long, km, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users matches
         * @summary Get users matches
         * @param {string} [search] Search
         * @param {number} [page] Page
         * @param {number} [limit] Limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersMatchesGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsSimpleMatchListResponse> {
            return localVarFp.statsUsersMatchesGet(search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users with full profile
         * @summary Get users with full profile
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersWithFullProfileGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserListResponse> {
            return localVarFp.statsUsersWithFullProfileGet(search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users without profile
         * @summary Get users without profile
         * @param {string} [search] Search by: first name, last name, email, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersWithoutProfileGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserListResponse> {
            return localVarFp.statsUsersWithoutProfileGet(search, page, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Get users without test result
         * @summary Get users without test result
         * @param {string} [search] Search by: first name, last name, email, phone, or id
         * @param {number} [page] Page number
         * @param {number} [limit] Limit per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statsUsersWithoutTestResultGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserListResponse> {
            return localVarFp.statsUsersWithoutTestResultGet(search, page, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatsApi - object-oriented interface
 * @export
 * @class StatsApi
 * @extends {BaseAPI}
 */
export class StatsApi extends BaseAPI {
    /**
     * Get active users
     * @summary Get active users
     * @param {number} duration Duration
     * @param {StatsActiveUsersGetUnitEnum} unit Unit
     * @param {string} [search] Search by: first name, last name, email, phone, or id
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsActiveUsersGet(duration: number, unit: StatsActiveUsersGetUnitEnum, search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsActiveUsersGet(duration, unit, search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Filter user by criteria
     * @summary Filter user by criteria
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {number} [ageMin] Minimum age
     * @param {number} [ageMax] Maximum age
     * @param {number} [heightMin] Minimum height
     * @param {number} [heightMax] Maximum height
     * @param {number} [incomeMin] Minimum income
     * @param {number} [incomeMax] Maximum income
     * @param {boolean} [smoking] Smoking
     * @param {boolean} [drinking] Drinking
     * @param {boolean} [haveChildren] Have children
     * @param {boolean} [wantChildren] Want children
     * @param {boolean} [likePets] Like pets
     * @param {boolean} [havePets] Have pets
     * @param {string} [educationId] Education ID
     * @param {string} [genderId] Gender ID
     * @param {string} [zodiacSign] Zodiac sign
     * @param {string} [bodyTypeId] Body type ID
     * @param {string} [sexOrientationId] Sex orientation ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsFilterUserByCriteriaGet(page?: number, limit?: number, ageMin?: number, ageMax?: number, heightMin?: number, heightMax?: number, incomeMin?: number, incomeMax?: number, smoking?: boolean, drinking?: boolean, haveChildren?: boolean, wantChildren?: boolean, likePets?: boolean, havePets?: boolean, educationId?: string, genderId?: string, zodiacSign?: string, bodyTypeId?: string, sexOrientationId?: string, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsFilterUserByCriteriaGet(page, limit, ageMin, ageMax, heightMin, heightMax, incomeMin, incomeMax, smoking, drinking, haveChildren, wantChildren, likePets, havePets, educationId, genderId, zodiacSign, bodyTypeId, sexOrientationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get inactive users
     * @summary Get inactive users
     * @param {number} duration Duration
     * @param {StatsInactiveUsersGetUnitEnum} unit Unit
     * @param {string} [search] Search by: first name, last name, email, phone, or id
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsInactiveUsersGet(duration: number, unit: StatsInactiveUsersGetUnitEnum, search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsInactiveUsersGet(duration, unit, search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get last active date
     * @summary Get last active date
     * @param {string} userID User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsLastActiveDateUserIDGet(userID: string, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsLastActiveDateUserIDGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get open chats for user
     * @summary Get open chats for user
     * @param {string} userID User ID
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsOpenChatsUserIDGet(userID: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsOpenChatsUserIDGet(userID, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get recent activities
     * @summary Get recent activities
     * @param {string} userID User ID
     * @param {number} duration Duration
     * @param {StatsRecentActivitiesUserIDGetUnitEnum} unit Unit
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsRecentActivitiesUserIDGet(userID: string, duration: number, unit: StatsRecentActivitiesUserIDGetUnitEnum, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsRecentActivitiesUserIDGet(userID, duration, unit, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get top chatters
     * @summary Get top chatters
     * @param {string} [search] Search by: first name, last name, email, phone, or id
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsTopChattersGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsTopChattersGet(search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get top receivers
     * @summary Get top receivers
     * @param {string} [search] Search by: first name, last name, email, phone, or id
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsTopReceiversGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsTopReceiversGet(search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users in location
     * @summary Get users in location
     * @param {number} lat Latitude
     * @param {number} _long Longitude
     * @param {number} [km] Kilometers
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsUsersInLocationGet(lat: number, _long: number, km?: number, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsUsersInLocationGet(lat, _long, km, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users matches
     * @summary Get users matches
     * @param {string} [search] Search
     * @param {number} [page] Page
     * @param {number} [limit] Limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsUsersMatchesGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsUsersMatchesGet(search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users with full profile
     * @summary Get users with full profile
     * @param {string} [search] Search by: first name, last name, email, phone, or id
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsUsersWithFullProfileGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsUsersWithFullProfileGet(search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users without profile
     * @summary Get users without profile
     * @param {string} [search] Search by: first name, last name, email, or id
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsUsersWithoutProfileGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsUsersWithoutProfileGet(search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get users without test result
     * @summary Get users without test result
     * @param {string} [search] Search by: first name, last name, email, phone, or id
     * @param {number} [page] Page number
     * @param {number} [limit] Limit per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public statsUsersWithoutTestResultGet(search?: string, page?: number, limit?: number, options?: RawAxiosRequestConfig) {
        return StatsApiFp(this.configuration).statsUsersWithoutTestResultGet(search, page, limit, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const StatsActiveUsersGetUnitEnum = {
    Minutes: 'MINUTES',
    Hours: 'HOURS',
    Days: 'DAYS'
} as const;
export type StatsActiveUsersGetUnitEnum = typeof StatsActiveUsersGetUnitEnum[keyof typeof StatsActiveUsersGetUnitEnum];
/**
 * @export
 */
export const StatsInactiveUsersGetUnitEnum = {
    Minutes: 'MINUTES',
    Hours: 'HOURS',
    Days: 'DAYS'
} as const;
export type StatsInactiveUsersGetUnitEnum = typeof StatsInactiveUsersGetUnitEnum[keyof typeof StatsInactiveUsersGetUnitEnum];
/**
 * @export
 */
export const StatsRecentActivitiesUserIDGetUnitEnum = {
    Minutes: 'MINUTES',
    Hours: 'HOURS',
    Days: 'DAYS'
} as const;
export type StatsRecentActivitiesUserIDGetUnitEnum = typeof StatsRecentActivitiesUserIDGetUnitEnum[keyof typeof StatsRecentActivitiesUserIDGetUnitEnum];


/**
 * UserLocationApi - axios parameter creator
 * @export
 */
export const UserLocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get distance between two users in meters
         * @summary Get distance between two users in meters
         * @param {string} userOneId User One ID
         * @param {string} userTwoId User Two ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDistanceGet: async (userOneId: string, userTwoId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userOneId' is not null or undefined
            assertParamExists('usersDistanceGet', 'userOneId', userOneId)
            // verify required parameter 'userTwoId' is not null or undefined
            assertParamExists('usersDistanceGet', 'userTwoId', userTwoId)
            const localVarPath = `/users/distance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (userOneId !== undefined) {
                localVarQueryParameter['user_one_id'] = userOneId;
            }

            if (userTwoId !== undefined) {
                localVarQueryParameter['user_two_id'] = userTwoId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get distance between user and given location in meters
         * @summary Get distance between user and given location in meters
         * @param {string} userID userID
         * @param {number} latitude Latitude
         * @param {number} longitude Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDDistanceGet: async (userID: string, latitude: number, longitude: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDDistanceGet', 'userID', userID)
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('usersUserIDDistanceGet', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('usersUserIDDistanceGet', 'longitude', longitude)
            const localVarPath = `/users/{userID}/distance`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get last location of user
         * @summary Get last location of user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDLastLocationGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDLastLocationGet', 'userID', userID)
            const localVarPath = `/users/{userID}/last-location`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add location of user
         * @summary Add location of user
         * @param {string} userID userID
         * @param {DefsUserLocationData} defsUserLocationData User Location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDLocationPost: async (userID: string, defsUserLocationData: DefsUserLocationData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDLocationPost', 'userID', userID)
            // verify required parameter 'defsUserLocationData' is not null or undefined
            assertParamExists('usersUserIDLocationPost', 'defsUserLocationData', defsUserLocationData)
            const localVarPath = `/users/{userID}/location`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUserLocationData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserLocationApi - functional programming interface
 * @export
 */
export const UserLocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserLocationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get distance between two users in meters
         * @summary Get distance between two users in meters
         * @param {string} userOneId User One ID
         * @param {string} userTwoId User Two ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersDistanceGet(userOneId: string, userTwoId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsDistanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersDistanceGet(userOneId, userTwoId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserLocationApi.usersDistanceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get distance between user and given location in meters
         * @summary Get distance between user and given location in meters
         * @param {string} userID userID
         * @param {number} latitude Latitude
         * @param {number} longitude Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDDistanceGet(userID: string, latitude: number, longitude: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsDistanceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDDistanceGet(userID, latitude, longitude, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserLocationApi.usersUserIDDistanceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get last location of user
         * @summary Get last location of user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDLastLocationGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserLocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDLastLocationGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserLocationApi.usersUserIDLastLocationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add location of user
         * @summary Add location of user
         * @param {string} userID userID
         * @param {DefsUserLocationData} defsUserLocationData User Location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDLocationPost(userID: string, defsUserLocationData: DefsUserLocationData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDLocationPost(userID, defsUserLocationData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserLocationApi.usersUserIDLocationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserLocationApi - factory interface
 * @export
 */
export const UserLocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserLocationApiFp(configuration)
    return {
        /**
         * Get distance between two users in meters
         * @summary Get distance between two users in meters
         * @param {string} userOneId User One ID
         * @param {string} userTwoId User Two ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersDistanceGet(userOneId: string, userTwoId: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsDistanceResponse> {
            return localVarFp.usersDistanceGet(userOneId, userTwoId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get distance between user and given location in meters
         * @summary Get distance between user and given location in meters
         * @param {string} userID userID
         * @param {number} latitude Latitude
         * @param {number} longitude Longitude
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDDistanceGet(userID: string, latitude: number, longitude: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsDistanceResponse> {
            return localVarFp.usersUserIDDistanceGet(userID, latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * Get last location of user
         * @summary Get last location of user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDLastLocationGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserLocationResponse> {
            return localVarFp.usersUserIDLastLocationGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Add location of user
         * @summary Add location of user
         * @param {string} userID userID
         * @param {DefsUserLocationData} defsUserLocationData User Location
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDLocationPost(userID: string, defsUserLocationData: DefsUserLocationData, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDLocationPost(userID, defsUserLocationData, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserLocationApi - object-oriented interface
 * @export
 * @class UserLocationApi
 * @extends {BaseAPI}
 */
export class UserLocationApi extends BaseAPI {
    /**
     * Get distance between two users in meters
     * @summary Get distance between two users in meters
     * @param {string} userOneId User One ID
     * @param {string} userTwoId User Two ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLocationApi
     */
    public usersDistanceGet(userOneId: string, userTwoId: string, options?: RawAxiosRequestConfig) {
        return UserLocationApiFp(this.configuration).usersDistanceGet(userOneId, userTwoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get distance between user and given location in meters
     * @summary Get distance between user and given location in meters
     * @param {string} userID userID
     * @param {number} latitude Latitude
     * @param {number} longitude Longitude
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLocationApi
     */
    public usersUserIDDistanceGet(userID: string, latitude: number, longitude: number, options?: RawAxiosRequestConfig) {
        return UserLocationApiFp(this.configuration).usersUserIDDistanceGet(userID, latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get last location of user
     * @summary Get last location of user
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLocationApi
     */
    public usersUserIDLastLocationGet(userID: string, options?: RawAxiosRequestConfig) {
        return UserLocationApiFp(this.configuration).usersUserIDLastLocationGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add location of user
     * @summary Add location of user
     * @param {string} userID userID
     * @param {DefsUserLocationData} defsUserLocationData User Location
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLocationApi
     */
    public usersUserIDLocationPost(userID: string, defsUserLocationData: DefsUserLocationData, options?: RawAxiosRequestConfig) {
        return UserLocationApiFp(this.configuration).usersUserIDLocationPost(userID, defsUserLocationData, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all users
         * @summary get all users
         * @param {string} [s] search term
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet: async (s?: string, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (s !== undefined) {
                localVarQueryParameter['s'] = s;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create and edit user
         * @summary create and edit user
         * @param {DefsUserData} defsUserData User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (defsUserData: DefsUserData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'defsUserData' is not null or undefined
            assertParamExists('usersPost', 'defsUserData', defsUserData)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUserData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user by email
         * @summary get user by email
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRecoveryEmailGet: async (email: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersRecoveryEmailGet', 'email', email)
            const localVarPath = `/users/recovery/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user by phone
         * @summary get user by phone
         * @param {string} phone phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSearchPhoneGet: async (phone: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phone' is not null or undefined
            assertParamExists('usersSearchPhoneGet', 'phone', phone)
            const localVarPath = `/users/search/{phone}`
                .replace(`{${"phone"}}`, encodeURIComponent(String(phone)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user answers
         * @summary delete user answers
         * @param {string} userID userID
         * @param {string} answersID answersID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDAnswersAnswersIDDelete: async (userID: string, answersID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDAnswersAnswersIDDelete', 'userID', userID)
            // verify required parameter 'answersID' is not null or undefined
            assertParamExists('usersUserIDAnswersAnswersIDDelete', 'answersID', answersID)
            const localVarPath = `/users/{userID}/answers/{answersID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)))
                .replace(`{${"answersID"}}`, encodeURIComponent(String(answersID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user answers
         * @summary get user answers
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDAnswersGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDAnswersGet', 'userID', userID)
            const localVarPath = `/users/{userID}/answers`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save user answers
         * @summary save user answers
         * @param {string} userID userID
         * @param {DefsUserAnswersReq} defsUserAnswersReq TestAnswer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDAnswersPost: async (userID: string, defsUserAnswersReq: DefsUserAnswersReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDAnswersPost', 'userID', userID)
            // verify required parameter 'defsUserAnswersReq' is not null or undefined
            assertParamExists('usersUserIDAnswersPost', 'defsUserAnswersReq', defsUserAnswersReq)
            const localVarPath = `/users/{userID}/answers`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUserAnswersReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Test results by user
         * @summary test results by user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDAnswersResultsGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDAnswersResultsGet', 'userID', userID)
            const localVarPath = `/users/{userID}/answers/results`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete user by userID with all related data and images
         * @summary delete user with relations by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDDelete: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDDelete', 'userID', userID)
            const localVarPath = `/users/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get user gamification info by userID
         * @summary get user gamification info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDGamificationGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDGamificationGet', 'userID', userID)
            const localVarPath = `/users/{userID}/gamification`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user by userID
         * @summary get user by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDGet', 'userID', userID)
            const localVarPath = `/users/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get matches since the last updated time (optional) with pagination
         * @summary get matches
         * @param {string} userID userID
         * @param {string} [lastUpdated] Last updated timestamp in RFC3339 format
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDMatchesGet: async (userID: string, lastUpdated?: string, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDMatchesGet', 'userID', userID)
            const localVarPath = `/users/{userID}/matches`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)

            if (lastUpdated !== undefined) {
                localVarQueryParameter['lastUpdated'] = lastUpdated;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user password
         * @summary update user password
         * @param {string} userID userID
         * @param {DefsUpdatePasswordRequest} defsUpdatePasswordRequest UpdatePassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPasswordPut: async (userID: string, defsUpdatePasswordRequest: DefsUpdatePasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDPasswordPut', 'userID', userID)
            // verify required parameter 'defsUpdatePasswordRequest' is not null or undefined
            assertParamExists('usersUserIDPasswordPut', 'defsUpdatePasswordRequest', defsUpdatePasswordRequest)
            const localVarPath = `/users/{userID}/password`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUpdatePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete all matches for a user and process new matches
         * @summary Processes matches for a user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProcessMatchesPost: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDProcessMatchesPost', 'userID', userID)
            const localVarPath = `/users/{userID}/process-matches`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user profile by userID
         * @summary get user profile by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProfilesGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDProfilesGet', 'userID', userID)
            const localVarPath = `/users/{userID}/profiles`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create or update user-profile by userID
         * @summary create or update user-profile by userID
         * @param {string} userID userID
         * @param {DefsUserProfileReq} userProfileReq UserProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProfilesPost: async (userID: string, userProfileReq: DefsUserProfileReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDProfilesPost', 'userID', userID)
            // verify required parameter 'userProfileReq' is not null or undefined
            assertParamExists('usersUserIDProfilesPost', 'userProfileReq', userProfileReq)
            const localVarPath = `/users/{userID}/profiles`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * create or update user-profile by userID
         * @summary create or update user-profile by userID
         * @param {string} userID userID
         * @param {DefsUserProfileReq} userProfileReq UserProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProfilesPut: async (userID: string, userProfileReq: DefsUserProfileReq, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDProfilesPut', 'userID', userID)
            // verify required parameter 'userProfileReq' is not null or undefined
            assertParamExists('usersUserIDProfilesPut', 'userProfileReq', userProfileReq)
            const localVarPath = `/users/{userID}/profiles`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userProfileReq, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete user profile by userID and upID
         * @summary delete user profile by userID and upID
         * @param {string} userID userID
         * @param {string} upID upID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProfilesUpIDDelete: async (userID: string, upID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDProfilesUpIDDelete', 'userID', userID)
            // verify required parameter 'upID' is not null or undefined
            assertParamExists('usersUserIDProfilesUpIDDelete', 'upID', upID)
            const localVarPath = `/users/{userID}/profiles/{upID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)))
                .replace(`{${"upID"}}`, encodeURIComponent(String(upID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create and edit user
         * @summary create and edit user
         * @param {string} userID userID
         * @param {DefsUserData} defsUserData User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPut: async (userID: string, defsUserData: DefsUserData, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDPut', 'userID', userID)
            // verify required parameter 'defsUserData' is not null or undefined
            assertParamExists('usersUserIDPut', 'defsUserData', defsUserData)
            const localVarPath = `/users/{userID}`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUserData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user suspend status
         * @summary update user suspend status
         * @param {string} userID userID
         * @param {DefsUpdateSuspendRequest} defsUpdateSuspendRequest UpdateSuspend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSuspendPut: async (userID: string, defsUpdateSuspendRequest: DefsUpdateSuspendRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDSuspendPut', 'userID', userID)
            // verify required parameter 'defsUpdateSuspendRequest' is not null or undefined
            assertParamExists('usersUserIDSuspendPut', 'defsUpdateSuspendRequest', defsUpdateSuspendRequest)
            const localVarPath = `/users/{userID}/suspend`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(defsUpdateSuspendRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get user state
         * @summary get user state
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDUserStateGet: async (userID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userID' is not null or undefined
            assertParamExists('usersUserIDUserStateGet', 'userID', userID)
            const localVarPath = `/users/{userID}/user-state`
                .replace(`{${"userID"}}`, encodeURIComponent(String(userID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication ApiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "x-auth", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all users
         * @summary get all users
         * @param {string} [s] search term
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersGet(s?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersGet(s, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create and edit user
         * @summary create and edit user
         * @param {DefsUserData} defsUserData User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(defsUserData: DefsUserData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(defsUserData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user by email
         * @summary get user by email
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRecoveryEmailGet(email: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRecoveryEmailGet(email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersRecoveryEmailGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user by phone
         * @summary get user by phone
         * @param {string} phone phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSearchPhoneGet(phone: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSearchPhoneGet(phone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersSearchPhoneGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete user answers
         * @summary delete user answers
         * @param {string} userID userID
         * @param {string} answersID answersID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDAnswersAnswersIDDelete(userID: string, answersID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDAnswersAnswersIDDelete(userID, answersID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDAnswersAnswersIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user answers
         * @summary get user answers
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDAnswersGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsTestAnswerResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDAnswersGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDAnswersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Save user answers
         * @summary save user answers
         * @param {string} userID userID
         * @param {DefsUserAnswersReq} defsUserAnswersReq TestAnswer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDAnswersPost(userID: string, defsUserAnswersReq: DefsUserAnswersReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDAnswersPost(userID, defsUserAnswersReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDAnswersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Test results by user
         * @summary test results by user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDAnswersResultsGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsTestTypeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDAnswersResultsGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDAnswersResultsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * delete user by userID with all related data and images
         * @summary delete user with relations by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDDelete(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDDelete(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get user gamification info by userID
         * @summary get user gamification info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDGamificationGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelsUserGamificationInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDGamificationGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDGamificationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user by userID
         * @summary get user by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get matches since the last updated time (optional) with pagination
         * @summary get matches
         * @param {string} userID userID
         * @param {string} [lastUpdated] Last updated timestamp in RFC3339 format
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDMatchesGet(userID: string, lastUpdated?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsMatchListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDMatchesGet(userID, lastUpdated, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDMatchesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update user password
         * @summary update user password
         * @param {string} userID userID
         * @param {DefsUpdatePasswordRequest} defsUpdatePasswordRequest UpdatePassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDPasswordPut(userID: string, defsUpdatePasswordRequest: DefsUpdatePasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDPasswordPut(userID, defsUpdatePasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDPasswordPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete all matches for a user and process new matches
         * @summary Processes matches for a user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDProcessMatchesPost(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDProcessMatchesPost(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDProcessMatchesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get user profile by userID
         * @summary get user profile by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDProfilesGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserProfileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDProfilesGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDProfilesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * create or update user-profile by userID
         * @summary create or update user-profile by userID
         * @param {string} userID userID
         * @param {DefsUserProfileReq} userProfileReq UserProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDProfilesPost(userID: string, userProfileReq: DefsUserProfileReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDProfilesPost(userID, userProfileReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDProfilesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * create or update user-profile by userID
         * @summary create or update user-profile by userID
         * @param {string} userID userID
         * @param {DefsUserProfileReq} userProfileReq UserProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDProfilesPut(userID: string, userProfileReq: DefsUserProfileReq, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsOKWithID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDProfilesPut(userID, userProfileReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDProfilesPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete user profile by userID and upID
         * @summary delete user profile by userID and upID
         * @param {string} userID userID
         * @param {string} upID upID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDProfilesUpIDDelete(userID: string, upID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDProfilesUpIDDelete(userID, upID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDProfilesUpIDDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create and edit user
         * @summary create and edit user
         * @param {string} userID userID
         * @param {DefsUserData} defsUserData User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDPut(userID: string, defsUserData: DefsUserData, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDPut(userID, defsUserData, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update user suspend status
         * @summary update user suspend status
         * @param {string} userID userID
         * @param {DefsUpdateSuspendRequest} defsUpdateSuspendRequest UpdateSuspend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDSuspendPut(userID: string, defsUpdateSuspendRequest: DefsUpdateSuspendRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefsEResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDSuspendPut(userID, defsUpdateSuspendRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDSuspendPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * get user state
         * @summary get user state
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserIDUserStateGet(userID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandlersUserState>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserIDUserStateGet(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserIDUserStateGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Get all users
         * @summary get all users
         * @param {string} [s] search term
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersGet(s?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserListResponse> {
            return localVarFp.usersGet(s, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Create and edit user
         * @summary create and edit user
         * @param {DefsUserData} defsUserData User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(defsUserData: DefsUserData, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserResponse> {
            return localVarFp.usersPost(defsUserData, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user by email
         * @summary get user by email
         * @param {string} email email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRecoveryEmailGet(email: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserResponse> {
            return localVarFp.usersRecoveryEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user by phone
         * @summary get user by phone
         * @param {string} phone phone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSearchPhoneGet(phone: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserResponse> {
            return localVarFp.usersSearchPhoneGet(phone, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user answers
         * @summary delete user answers
         * @param {string} userID userID
         * @param {string} answersID answersID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDAnswersAnswersIDDelete(userID: string, answersID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDAnswersAnswersIDDelete(userID, answersID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user answers
         * @summary get user answers
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDAnswersGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsTestAnswerResponse> {
            return localVarFp.usersUserIDAnswersGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Save user answers
         * @summary save user answers
         * @param {string} userID userID
         * @param {DefsUserAnswersReq} defsUserAnswersReq TestAnswer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDAnswersPost(userID: string, defsUserAnswersReq: DefsUserAnswersReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.usersUserIDAnswersPost(userID, defsUserAnswersReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Test results by user
         * @summary test results by user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDAnswersResultsGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsTestTypeResponse> {
            return localVarFp.usersUserIDAnswersResultsGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * delete user by userID with all related data and images
         * @summary delete user with relations by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDDelete(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDDelete(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * get user gamification info by userID
         * @summary get user gamification info
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDGamificationGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<ModelsUserGamificationInfo> {
            return localVarFp.usersUserIDGamificationGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user by userID
         * @summary get user by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserResponse> {
            return localVarFp.usersUserIDGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * get matches since the last updated time (optional) with pagination
         * @summary get matches
         * @param {string} userID userID
         * @param {string} [lastUpdated] Last updated timestamp in RFC3339 format
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDMatchesGet(userID: string, lastUpdated?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): AxiosPromise<DefsMatchListResponse> {
            return localVarFp.usersUserIDMatchesGet(userID, lastUpdated, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user password
         * @summary update user password
         * @param {string} userID userID
         * @param {DefsUpdatePasswordRequest} defsUpdatePasswordRequest UpdatePassword
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPasswordPut(userID: string, defsUpdatePasswordRequest: DefsUpdatePasswordRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDPasswordPut(userID, defsUpdatePasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete all matches for a user and process new matches
         * @summary Processes matches for a user
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProcessMatchesPost(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDProcessMatchesPost(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user profile by userID
         * @summary get user profile by userID
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProfilesGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserProfileResponse> {
            return localVarFp.usersUserIDProfilesGet(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * create or update user-profile by userID
         * @summary create or update user-profile by userID
         * @param {string} userID userID
         * @param {DefsUserProfileReq} userProfileReq UserProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProfilesPost(userID: string, userProfileReq: DefsUserProfileReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.usersUserIDProfilesPost(userID, userProfileReq, options).then((request) => request(axios, basePath));
        },
        /**
         * create or update user-profile by userID
         * @summary create or update user-profile by userID
         * @param {string} userID userID
         * @param {DefsUserProfileReq} userProfileReq UserProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProfilesPut(userID: string, userProfileReq: DefsUserProfileReq, options?: RawAxiosRequestConfig): AxiosPromise<DefsOKWithID> {
            return localVarFp.usersUserIDProfilesPut(userID, userProfileReq, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete user profile by userID and upID
         * @summary delete user profile by userID and upID
         * @param {string} userID userID
         * @param {string} upID upID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDProfilesUpIDDelete(userID: string, upID: string, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDProfilesUpIDDelete(userID, upID, options).then((request) => request(axios, basePath));
        },
        /**
         * Create and edit user
         * @summary create and edit user
         * @param {string} userID userID
         * @param {DefsUserData} defsUserData User
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDPut(userID: string, defsUserData: DefsUserData, options?: RawAxiosRequestConfig): AxiosPromise<DefsUserResponse> {
            return localVarFp.usersUserIDPut(userID, defsUserData, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user suspend status
         * @summary update user suspend status
         * @param {string} userID userID
         * @param {DefsUpdateSuspendRequest} defsUpdateSuspendRequest UpdateSuspend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDSuspendPut(userID: string, defsUpdateSuspendRequest: DefsUpdateSuspendRequest, options?: RawAxiosRequestConfig): AxiosPromise<DefsEResponse> {
            return localVarFp.usersUserIDSuspendPut(userID, defsUpdateSuspendRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * get user state
         * @summary get user state
         * @param {string} userID userID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserIDUserStateGet(userID: string, options?: RawAxiosRequestConfig): AxiosPromise<HandlersUserState> {
            return localVarFp.usersUserIDUserStateGet(userID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Get all users
     * @summary get all users
     * @param {string} [s] search term
     * @param {number} [page] Page number for pagination
     * @param {number} [pageSize] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersGet(s?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersGet(s, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create and edit user
     * @summary create and edit user
     * @param {DefsUserData} defsUserData User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPost(defsUserData: DefsUserData, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPost(defsUserData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user by email
     * @summary get user by email
     * @param {string} email email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersRecoveryEmailGet(email: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersRecoveryEmailGet(email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user by phone
     * @summary get user by phone
     * @param {string} phone phone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSearchPhoneGet(phone: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersSearchPhoneGet(phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user answers
     * @summary delete user answers
     * @param {string} userID userID
     * @param {string} answersID answersID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDAnswersAnswersIDDelete(userID: string, answersID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDAnswersAnswersIDDelete(userID, answersID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user answers
     * @summary get user answers
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDAnswersGet(userID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDAnswersGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save user answers
     * @summary save user answers
     * @param {string} userID userID
     * @param {DefsUserAnswersReq} defsUserAnswersReq TestAnswer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDAnswersPost(userID: string, defsUserAnswersReq: DefsUserAnswersReq, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDAnswersPost(userID, defsUserAnswersReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Test results by user
     * @summary test results by user
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDAnswersResultsGet(userID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDAnswersResultsGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete user by userID with all related data and images
     * @summary delete user with relations by userID
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDDelete(userID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDDelete(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get user gamification info by userID
     * @summary get user gamification info
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDGamificationGet(userID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDGamificationGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user by userID
     * @summary get user by userID
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDGet(userID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get matches since the last updated time (optional) with pagination
     * @summary get matches
     * @param {string} userID userID
     * @param {string} [lastUpdated] Last updated timestamp in RFC3339 format
     * @param {number} [page] Page number for pagination
     * @param {number} [pageSize] Number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDMatchesGet(userID: string, lastUpdated?: string, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDMatchesGet(userID, lastUpdated, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user password
     * @summary update user password
     * @param {string} userID userID
     * @param {DefsUpdatePasswordRequest} defsUpdatePasswordRequest UpdatePassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDPasswordPut(userID: string, defsUpdatePasswordRequest: DefsUpdatePasswordRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDPasswordPut(userID, defsUpdatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete all matches for a user and process new matches
     * @summary Processes matches for a user
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDProcessMatchesPost(userID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDProcessMatchesPost(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user profile by userID
     * @summary get user profile by userID
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDProfilesGet(userID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDProfilesGet(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create or update user-profile by userID
     * @summary create or update user-profile by userID
     * @param {string} userID userID
     * @param {DefsUserProfileReq} userProfileReq UserProfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDProfilesPost(userID: string, userProfileReq: DefsUserProfileReq, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDProfilesPost(userID, userProfileReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * create or update user-profile by userID
     * @summary create or update user-profile by userID
     * @param {string} userID userID
     * @param {DefsUserProfileReq} userProfileReq UserProfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDProfilesPut(userID: string, userProfileReq: DefsUserProfileReq, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDProfilesPut(userID, userProfileReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete user profile by userID and upID
     * @summary delete user profile by userID and upID
     * @param {string} userID userID
     * @param {string} upID upID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDProfilesUpIDDelete(userID: string, upID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDProfilesUpIDDelete(userID, upID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create and edit user
     * @summary create and edit user
     * @param {string} userID userID
     * @param {DefsUserData} defsUserData User
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDPut(userID: string, defsUserData: DefsUserData, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDPut(userID, defsUserData, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user suspend status
     * @summary update user suspend status
     * @param {string} userID userID
     * @param {DefsUpdateSuspendRequest} defsUpdateSuspendRequest UpdateSuspend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDSuspendPut(userID: string, defsUpdateSuspendRequest: DefsUpdateSuspendRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDSuspendPut(userID, defsUpdateSuspendRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get user state
     * @summary get user state
     * @param {string} userID userID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserIDUserStateGet(userID: string, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserIDUserStateGet(userID, options).then((request) => request(this.axios, this.basePath));
    }
}



