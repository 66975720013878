import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, mergeProps as _mergeProps, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_chat_component = _resolveComponent("chat-component")!
  const _component_UserPreferencesDialog = _resolveComponent("UserPreferencesDialog")!
  const _component_UserSettingsDialog = _resolveComponent("UserSettingsDialog")!
  const _component_UserProfile = _resolveComponent("UserProfile")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_data_table_server, {
      headers: $setup.headers,
      items: $setup.users,
      "items-length": $setup.totalUsers,
      loading: $setup.loading,
      page: $setup.page,
      "onUpdate:page": _cache[7] || (_cache[7] = ($event: any) => (($setup.page) = $event)),
      "items-per-page": $setup.pageSize,
      "onUpdate:itemsPerPage": _cache[8] || (_cache[8] = ($event: any) => (($setup.pageSize) = $event)),
      options: $setup.options,
      "onUpdate:options": _cache[9] || (_cache[9] = ($event: any) => (($setup.options) = $event)),
      "item-key": "id",
      class: "text-start elevation-1"
    }, {
      top: _withCtx(() => [
        _createVNode(_component_v_toolbar, { flat: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_text_field, {
              modelValue: $setup.search,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
              label: "Search",
              "single-line": "",
              "hide-details": "",
              flex: "",
              variant: "solo"
            }, null, 8, ["modelValue"]),
            _createVNode(_component_v_divider, {
              class: "mx-2",
              inset: "",
              vertical: ""
            }),
            _createVNode(_component_v_dialog, {
              modelValue: $setup.dialog,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.dialog) = $event)),
              "max-width": "600px"
            }, {
              activator: _withCtx(({ props }) => [
                _createVNode(_component_v_btn, _mergeProps({
                  color: "orange-darken-2",
                  dark: "",
                  class: "mb-0"
                }, props), {
                  default: _withCtx(() => [
                    _createVNode(_component_v_icon, null, {
                      default: _withCtx(() => [
                        _createTextVNode("mdi-plus")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 2
                }, 1040)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, { color: "#aa6010" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      dark: "",
                      onClick: $setup.close
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-close")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($setup.formTitle), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_container, { class: "card" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_text_field, {
                              modelValue: $setup.editedItem.first_name,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.editedItem.first_name) = $event)),
                              label: "first name",
                              variant: "solo"
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_text_field, {
                              modelValue: $setup.editedItem.last_name,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.editedItem.last_name) = $event)),
                              label: "last name",
                              variant: "solo"
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_text_field, {
                              modelValue: $setup.editedItem.email,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.editedItem.email) = $event)),
                              label: "mail",
                              variant: "solo"
                            }, null, 8, ["modelValue"]),
                            _createVNode(_component_v_text_field, {
                              modelValue: $setup.editedItem.phone,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.editedItem.phone) = $event)),
                              label: "phone",
                              variant: "solo"
                            }, null, 8, ["modelValue"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "orange-darken-2",
                          variant: "text",
                          onClick: $setup.close
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Cancel")
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          color: "orange-darken-2",
                          variant: "text",
                          onClick: $setup.save
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Save")
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_v_dialog, {
              modelValue: $setup.dialogDelete,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.dialogDelete) = $event)),
              "max-width": "600px"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_toolbar, { color: "#aa6010" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      icon: "",
                      dark: "",
                      onClick: $setup.closeDelteDialog
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode("mdi-close")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_v_toolbar_title, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Delete User")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_card, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_card_text, null, {
                      default: _withCtx(() => [
                        _createTextVNode("Are you sure?")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_card_actions, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_spacer),
                        _createVNode(_component_v_btn, {
                          color: "orange-darken-2",
                          variant: "text",
                          onClick: $setup.closeDelteDialog
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Cancel")
                          ]),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_v_btn, {
                          color: "orange-darken-2",
                          variant: "text",
                          onClick: $setup.deleteItemConfirm
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("OK")
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      [`item.actions`]: _withCtx(({ item }) => [
        _createVNode(_component_v_icon, {
          color: "blue-darken-1",
          size: "small",
          class: "me-3",
          onClick: ($event: any) => ($setup.userPreferences(item as unknown as DefsUserData))
        }, {
          default: _withCtx(() => [
            _createTextVNode("mdi-tune")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_v_icon, {
          color: "blue-darken-1",
          size: "small",
          class: "me-3",
          onClick: ($event: any) => ($setup.userSettings(item as unknown as DefsUserData))
        }, {
          default: _withCtx(() => [
            _createTextVNode("mdi-cog")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_v_icon, {
          color: "gray-darken-1",
          size: "small",
          class: "me-3",
          onClick: ($event: any) => ($setup.profileView(item as unknown as DefsUserData))
        }, {
          default: _withCtx(() => [
            _createTextVNode("mdi-eye")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_v_icon, {
          color: "green-darken-2",
          size: "small",
          class: "me-3",
          onClick: ($event: any) => ($setup.editItem(item as unknown as DefsUserData))
        }, {
          default: _withCtx(() => [
            _createTextVNode("mdi-pencil ")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_v_icon, {
          color: "orange-darken-3",
          size: "small",
          class: "me-3",
          onClick: ($event: any) => ($setup.deleteItem(item as unknown as DefsUserData))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" mdi-delete ")
          ]),
          _: 2
        }, 1032, ["onClick"])
      ]),
      "no-data": _withCtx(() => [
        _createVNode(_component_v_btn, {
          color: "primary",
          onClick: $setup.initialize
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Reset")
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 2
    }, 1032, ["headers", "items", "items-length", "loading", "page", "items-per-page", "options"]),
    _createVNode(_component_chat_component, {
      chatDialogVisible: $setup.chatDialogVisible,
      userID: $setup.currentUserID,
      "onUpdate:chatDialogVisible": _cache[10] || (_cache[10] = value => $setup.chatDialogVisible = value)
    }, null, 8, ["chatDialogVisible", "userID"]),
    _createVNode(_component_UserPreferencesDialog, {
      dialog: $setup.preferencesDialogVisible,
      userID: $setup.currentUserID,
      "onUpdate:dialog": _cache[11] || (_cache[11] = ($event: any) => ($setup.preferencesDialogVisible = $event))
    }, null, 8, ["dialog", "userID"]),
    _createVNode(_component_UserSettingsDialog, {
      dialog: $setup.settingsDialogVisible,
      userID: $setup.currentUserID,
      "onUpdate:dialog": _cache[12] || (_cache[12] = ($event: any) => ($setup.settingsDialogVisible = $event))
    }, null, 8, ["dialog", "userID"]),
    _createVNode(_component_UserProfile, {
      dialog: $setup.userProfileDialogVisible,
      userID: $setup.currentUserID,
      mode: $setup.currentMode,
      "onUpdate:dialog": _cache[13] || (_cache[13] = ($event: any) => ($setup.userProfileDialogVisible = $event))
    }, null, 8, ["dialog", "userID", "mode"])
  ], 64))
}