import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_data_table_server = _resolveComponent("v-data-table-server")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            variant: "solo",
            modelValue: $setup.search,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
            "append-icon": "mdi-magnify",
            label: "Search",
            "single-line": "",
            "hide-details": ""
          }, null, 8, ["modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_data_table_server, {
        headers: $setup.getHeaders,
        items: $setup.getItems,
        "items-length": $setup.total,
        loading: $setup.loading,
        page: $setup.page,
        "onUpdate:page": _cache[1] || (_cache[1] = ($event: any) => (($setup.page) = $event)),
        "items-per-page": $setup.pageSize,
        "onUpdate:itemsPerPage": _cache[2] || (_cache[2] = ($event: any) => (($setup.pageSize) = $event)),
        options: $setup.options,
        "onUpdate:options": _cache[3] || (_cache[3] = ($event: any) => (($setup.options) = $event)),
        "item-key": $setup.props.endpoint === 'match-users' ? 'user_id' : 'id'
      }, null, 8, ["headers", "items", "items-length", "loading", "page", "items-per-page", "options", "item-key"])
    ]),
    _: 1
  }))
}