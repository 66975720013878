

import { ref, computed, watch, onMounted, PropType } from 'vue';
import { Header} from '@/types';
import {
  QuestionsApiFactory, QuestionApiFactory,
  Configuration, ModelsTestType, DefsQuestionReqMeaningEnum, ModelsQuestionSysType, DefsEResponse, DefsQuestionReqDimensionEnum
} from '../../api';
import { notify } from "@kyvg/vue3-notification";
import { useToken } from '../auth';

export interface Question {
  id?: string;
  type: ModelsTestType;
  system_type: ModelsQuestionSysType;
  question: string;
  dimension: string;
  direction: number;
  meaning: DefsQuestionReqMeaningEnum;
}

export interface TestQuestions {
  mbti: Question[]
  attachment: Question[]
}

export default {
  name: 'QTmpl',
  props: {
    qType: {
      type: String as PropType<string>,
      required: true,
    },
    userId: {
      type: String as PropType<string>,
      required: false,
    },
  },

  setup(props, { emit }) {
    const dialog = ref(false);
    const dialogDelete = ref(false);
    const token = useToken();
    const config = new Configuration({ apiKey: token as string });
    const qsApi = QuestionsApiFactory(config);
    const qApi = QuestionApiFactory(config);
    const questions = ref<Question[]>([]);
    const editedIndex = ref(-1);
    const search = ref('');
    const editedItem = ref<Question>({ type: "mbti", question: '', dimension: '', direction: 1, meaning: "E", system_type: "text" });
    const defaultItem: Question = { type: "mbti", question: '', dimension: '', direction: 1, meaning: "E", system_type: "text" };
    const formTitle = computed(() => editedIndex.value === -1 ? 'New Question' : 'Edit Question');

    let headers: Header[] = [
      { title: 'ID', key: 'id' },
      { title: 'SysType', key: 'system_type' },
      { title: 'Qustion', key: 'question' },
      { title: 'Dimension', key: 'dimension' },
      { title: 'Direction', key: 'direction' },
      { title: 'Meaning', key: 'meaning' },
      { title: 'Actions', key: 'actions', sortable: false },
    ];

    if (props.qType !== 'mbti') {
      headers = headers.filter(header => header.key !== 'direction');
    }

    let sysType = ref<Record<string, string>>({
      "text": "text",
      "image": "image",
    });
    let selectedSysType = ref<string>("text");

    let dimensionsMBTI = ref<Record<string, string>>({
      "EI": "EI (Extraversion vs. Introversion)",
      "SN": "SN (Sensing vs. Intuition)",
      "TF": "TF (Thinking vs. Feeling)",
      "JP": "JP (Judging vs. Perceiving)"
    });
    let selectedDimensionMBTI = ref<string>("EI (Extraversion vs. Introversion)");

    let directions = ref<Record<string, string>>({
      "-1": "Negative",
      "1": "Positive"
    });
    let selectedDirection = ref<string>("Positive");

    let meaningsMBTI = ref<Record<string, string>>({
      "E": "E (Extraversion)",
      "I": "I (Introversion)",
      "S": "S (Sensing)",
      "N": "N (Intuition)",
      "T": "T (Thinking)",
      "F": "F (Feeling)",
      "J": "J (Judging)",
      "P": "P (Perceiving)"
    });
    let selectedMeaningMBTI = ref<string>("E (Extraversion)");

    let dimensionsAttach = ref<Record<string, string>>({
      "Secure": "Secure",
      "Anxious": "Anxious",
      "Fearful": "Fearful",
      "Dismissive": "Dismissive"
    });
    let selectedDimensionAttach = ref<string>("Secure");

    let meaningsAttach = ref<Record<string, string>>({
      "High": "High",
      "Low": "Low",
    });
    let selectedMeaningAttach = ref<string>("High");


    watch(dialog, (val) => {
      if (!val) close();
    });

    watch(dialogDelete, (val) => {
      if (!val) closeDelete();
    });

    watch(dialogDelete, (val) => {
      if (!val) closeDelete();
    });

    watch(questions, (newQuestions) => {
      emit('update:questions', newQuestions);
    });

    watch(search, (searchTerm) => {
      fetchQuestions(searchTerm);
    });


    onMounted(initialize);

    function updateSelectedMeaningAttach(selected: string) {
      for (let key in meaningsAttach.value) {
        if (meaningsAttach.value[key] === selected) {
          selectedMeaningAttach.value = key;
          break;
        }
      }
    }

    function updateSelectedDimensionAttach(selected: string) {
      for (let key in dimensionsAttach.value) {
        if (dimensionsAttach.value[key] === selected) {
          selectedDimensionAttach.value = key;
          break;
        }
      }
    }

    function updateSysType(selected: string) {
      for (let key in sysType.value) {
        if (sysType.value[key] === selected) {
          selectedSysType.value = key;
          break;
        }
      }
    }

    function updateSelectedMeaningMBTI(selected: string) {
      for (let key in meaningsMBTI.value) {
        if (meaningsMBTI.value[key] === selected) {
          selectedMeaningMBTI.value = key;
          break;
        }
      }
    }

    function updateSelectedDimensionMBTI(selected: string) {
      for (let key in dimensionsMBTI.value) {
        if (dimensionsMBTI.value[key] === selected) {
          selectedDimensionMBTI.value = key;
          break;
        }
      }
    }

    function updateSelectedDirection(selected: string) {
      for (let key in directions.value) {
        if (directions[key] === selected) {
          selectedDirection.value = key;
          break;
        }
      }
    }

    const fetchQuestions = async (searchValue: string) => {
      try {
        const resp = await qsApi.questionsGet(searchValue)
        console.log(resp.data.data)
        questions.value = []
        let qs = resp.data.data
        if (props.qType === 'mbti') {
          qs?.mbti.forEach(at => {
            questions.value.push({
              type: "mbti",
              id: at.id,
              dimension: at.dimension,
              direction: at.direction,
              meaning: DefsQuestionReqMeaningEnum[at.meaning],
              question: at.question,
              system_type: at.system_type as ModelsQuestionSysType,
            })
          });
        } else {
          qs?.attachment.forEach(at => {
            questions.value.push({
              type: "attachment",
              id: at.id,
              dimension: at.dimension,
              direction: 1,
              meaning: DefsQuestionReqMeaningEnum[at.meaning],
              question: at.question,
              system_type: at.system_type as ModelsQuestionSysType,
            })
          });
        }
      } catch (error) {
        console.error('Failed to get questions:', error);
      }
    };


    // 'dimension': DefsQuestionReqDimensionEnum;
    // 'direction': number;
    // 'id'?: string;
    // 'meaning': DefsQuestionReqMeaningEnum;
    // 'question': string;
    // 'status'?: ModelsStatusType;
    // 'systemType': ModelsQuestionSysType;
    // 'type': ModelsTestType;

    async function _save(qestion: Question) {
      let req = {
            dimension: qestion.dimension as DefsQuestionReqDimensionEnum,
            direction: qestion.direction,
            meaning: qestion.meaning as DefsQuestionReqMeaningEnum,
            question: qestion.question,
            systemType: selectedSysType.value as ModelsQuestionSysType,
            type: props.qType as ModelsTestType,
      }
      console.log(req)
      try {
        let resp: any
        if (qestion.id === undefined) {
          resp = await qApi.questionPost(req)
        } else {
          resp = await qApi.questionQIDPut(qestion.id, req)
        }
        console.log(resp.data)
        notify({ type: "success", text: "question saved successfully!" });
        fetchQuestions("");
      } catch (error) {
        notify({ type: "error", text: "failed to save question" });
        console.error('Failed to save question:', error);
      }
    }

    async function saveQuestion(qestion: Question) {
      console.log(qestion)
      if (props.qType === 'mbti') {
        qestion.dimension = selectedDimensionMBTI.value.split(" ")[0];
        qestion.meaning = DefsQuestionReqMeaningEnum[selectedMeaningMBTI.value.split(" ")[0]];
        qestion.direction = selectedDirection.value === "Negative" ? -1 : 1
      } else {
        qestion.dimension = selectedDimensionAttach.value;
        qestion.meaning = DefsQuestionReqMeaningEnum[selectedMeaningAttach.value];
      }
      _save(qestion)
    }

    async function deleteQestion(uqestion: Question) {
      try {
        const resp = await qsApi.questionsDelete()
        console.log(resp.data.data)
      } catch (error) {
        console.error('Failed to delete questions:', error);
      }
    }

    function initialize() {
      fetchQuestions("");
    }

    function setMeaning(meaning: string) {
      for (let key in meaningsMBTI.value) {
        if (key === meaning) {
          selectedMeaningMBTI.value = meaningsMBTI.value[key];
          break;
        }
      }
    }

    function setDimension(dimension: string) {
      for (let key in dimensionsMBTI.value) {
        if (key === dimension) {
          selectedDimensionMBTI.value = dimensionsMBTI.value[key];
          break;
        }
      }
    }

    function setDirection(direction: number) {
      for (let key in directions.value) {
        if (key === direction.toString()) {
          selectedDirection.value = directions.value[key];
          break;
        }
      }
    }

    function setMeaningAttach(meaning: string) {
      for (let key in meaningsAttach.value) {
        if (key === meaning) {
          selectedMeaningAttach.value = meaningsAttach.value[key];
          break;
        }
      }
    }

    function setDimensionAttach(dimension: string) {
      for (let key in dimensionsAttach.value) {
        if (key === dimension) {
          selectedDimensionAttach.value = dimensionsAttach.value[key];
          break;
        }
      }
    }

    function setSysType(sType: string) {
      for (let key in sysType.value) {
        if (key === sType) {
          selectedSysType.value = sysType.value[key];
          break;
        }
      }
    }

    function editItem(question: Question) {
      editedIndex.value = questions.value.indexOf(question);
      editedItem.value = Object.assign({}, question);
      if (props.qType === 'mbti') {
        setMeaning(question.meaning);
        setDimension(question.dimension);
        setDirection(question.direction)
      } else {
        setMeaningAttach(question.meaning)
        setDimensionAttach(question.dimension)
      }
      setSysType(question.system_type)
      dialog.value = true;
    }

    function deleteItem(uqestion: Question) {
      editedIndex.value = questions.value.indexOf(uqestion);
      editedItem.value = Object.assign({}, uqestion);
      dialogDelete.value = true;
    }

    function deleteItemConfirm() {
      questions.value.splice(editedIndex.value, 1);
      closeDelete();
    }

    function close() {
      dialog.value = false;
      editedItem.value = Object.assign({}, defaultItem);
      editedIndex.value = -1;
    }

    async function closeDelete() {
      dialogDelete.value = false;
      await deleteQestion(editedItem.value);
      editedItem.value = Object.assign({}, defaultItem);
      editedIndex.value = -1;
    }

    async function save() {
      if (editedIndex.value > -1) {
        Object.assign(questions.value[editedIndex.value], editedItem.value);
        await saveQuestion(editedItem.value);
      } else {
        let id = await saveQuestion(editedItem.value);
        console.log(id);
        fetchQuestions("");
      }
      close();
    }

    return {
      initialize,
      dialog,
      dialogDelete,
      headers,
      questions,
      editedIndex,
      editedItem,
      formTitle,
      editItem,
      deleteItem,
      deleteItemConfirm,
      close,
      closeDelete,
      save,
      search,
      props,
      dimensionsMBTI,
      selectedDimensionMBTI,
      meaningsMBTI,
      selectedMeaningMBTI,
      updateSelectedMeaningMBTI,
      updateSelectedDimensionMBTI,
      directions,
      selectedDirection,
      updateSelectedDirection,
      dimensionsAttach,
      selectedDimensionAttach,
      meaningsAttach,
      selectedMeaningAttach,
      updateSelectedMeaningAttach,
      updateSelectedDimensionAttach,
      sysType,
      updateSysType,
      selectedSysType,
    }
  }
}

