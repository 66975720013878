import { ref } from 'vue';
import { Header, EntityType } from '@/types';
import { useToken } from '../auth';
import {
    Configuration,
    EntitiesApiFactory,
    EducationsApiFactory,
    BodyTypesApiFactory,
    EyeColorsApiFactory,
    ProfessionsApiFactory,
    InterestsApiFactory,
    GendersApiFactory,
    HobbiesApiFactory,
    RacesApiFactory,
    SexualOrientationsApiFactory,
    DefsBodyTypeData,
    DefsEducationData,
    DefsProfessionData,
    DefsSexualOrientationData,
    DefsGenderData,
    DefsRaceData,
    DefsInterestsData,
    DefsHobbyData,
    DefsEyesColorData,
} from '../../api';

export class Entities {
    educationHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Education', key: 'education' },
        { title: 'Degree', key: 'degree' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    educations = ref<DefsEducationData[]>([]);

    professionHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Job Title', key: 'job_title' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    professions = ref<DefsProfessionData[]>([]);

    sexlOrientationHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Orientation', key: 'orientation' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    sexOrientations = ref<DefsSexualOrientationData[]>([]);

    genderHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Gender', key: 'gender' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    genders = ref<DefsGenderData[]>([]);

    raceHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Ethnicity', key: 'ethnicity' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    races = ref<DefsRaceData[]>([]);

    interestHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Interest', key: 'interest' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    interests = ref<DefsInterestsData[]>([]);

    hobbyHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Hobby', key: 'hobby' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    hobbies = ref<DefsHobbyData[]>([]);

    eyeColorHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Color', key: 'color' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    eyeColors = ref<DefsEyesColorData[]>([]);

    bodyTypeHeaders: Header[] = [
        { title: 'ID', key: 'id' },
        { title: 'Body Type', key: 'type' },
        { title: 'Actions', key: 'actions', sortable: false },
    ];
    bodyTypes = ref<DefsBodyTypeData[]>([]);

    token = useToken();
    config = new Configuration({ apiKey: this.token as string });
    entitiesApi = EntitiesApiFactory(this.config);
    eduApi = EducationsApiFactory(this.config);
    bodyTypesApi = BodyTypesApiFactory(this.config);
    profApi = ProfessionsApiFactory(this.config);
    eyeApi = EyeColorsApiFactory(this.config);
    genderApi = GendersApiFactory(this.config);
    hobbiesApi = HobbiesApiFactory(this.config);
    interestsApi = InterestsApiFactory(this.config);
    raceApi = RacesApiFactory(this.config);
    sexOrApi = SexualOrientationsApiFactory(this.config);

    async getEntity(entityType: EntityType) {
        try {
            switch (entityType) {
                case EntityType.Profession: {
                    const resp = await this.profApi.professionsGet();
                    this.professions.value = resp.data.data as Array<DefsProfessionData>;
                    break;
                }
                case EntityType.BodyType: {
                    const resp = await this.bodyTypesApi.bodyTypesGet();
                    this.bodyTypes.value = resp.data.data as Array<DefsBodyTypeData>
                    break;
                }
                case EntityType.Education: {
                    const resp = await this.eduApi.educationsGet()
                    this.educations.value = resp.data.data as Array<DefsEducationData>
                    break;
                }
                // case EntityType.EyeColor: {
                //     const resp = await this.eyeApi.eyeColorsGet()
                //     this.eyeColors.value = resp.data.data as Array<DefsEyesColorData>
                //     break;
                // }
                case EntityType.Gender: {
                    const resp = await this.genderApi.gendersGet()
                    this.genders.value = resp.data.data as Array<DefsGenderData>
                    break;
                }
                case EntityType.Hobby: {
                    const resp = await this.hobbiesApi.hobbiesGet()
                    this.hobbies.value = resp.data.data as Array<DefsHobbyData>
                    break;
                }
                case EntityType.Interest: {
                    const resp = await this.interestsApi.interestsGet()
                    this.interests.value = resp.data.data as Array<DefsInterestsData>
                    break;
                }
                case EntityType.Race: {
                    const resp = await this.raceApi.racesGet()
                    this.races.value = resp.data.data as Array<DefsRaceData>
                    break;
                }
                case EntityType.SexualOrientation: {
                    const resp = await this.sexOrApi.sexualOrientationsGet()
                    this.sexOrientations.value = resp.data.data as Array<DefsSexualOrientationData>
                    break;
                }

                default: {
                    break;
                }
            }

        } catch (error) {
            console.error(`An unexpected error occurred: ${error}`);
            throw error;
        }
    }

    async getEntities() {
        try {
            const resp = await this.entitiesApi.entitiesGet()
            const en = resp.data.data
            this.educations.value = en?.educations as Array<DefsEducationData>
            this.bodyTypes.value = en?.body_types as Array<DefsBodyTypeData>
            this.eyeColors.value = en?.eye_colors as Array<DefsEyesColorData>
            this.hobbies.value = en?.hobbies as Array<DefsHobbyData>
            this.interests.value = en?.interests as Array<DefsInterestsData>
            this.sexOrientations.value = en?.sexual_orientations as Array<DefsSexualOrientationData>
            this.professions.value = en?.professions as Array<DefsProfessionData>
            this.genders.value = en?.genders as Array<DefsGenderData>
            this.races.value = en?.races as Array<DefsRaceData>
        } catch (error) {
            console.error(`An unexpected error occurred: ${error}`);
        }
    }

    async save<T>(entityType: EntityType, id: string, data: T) {
        console.log("id: " + id + " entityType: " + entityType)
        console.log(data)
        try {
            switch (entityType) {
                case EntityType.Profession: {
                    if (id != "") {
                        await this.profApi.professionsPIDPut(id, data as DefsProfessionData);
                    } else {
                        await this.profApi.professionsPost(data as DefsProfessionData);
                    }
                    break;
                }
                case EntityType.BodyType: {
                    if (id != "") {
                        await this.bodyTypesApi.bodyTypesBtIDPut(id, data as DefsBodyTypeData);
                    } else {
                        await this.bodyTypesApi.bodyTypesPost(data as DefsBodyTypeData);
                    }
                    break;
                }
                case EntityType.Education: {
                    if (id != "") {
                        await this.eduApi.educationsEIDPut(id, data as DefsEducationData);
                    } else {
                        await this.eduApi.educationsPost(data as DefsEducationData);
                    }
                    break;
                }
                // case EntityType.EyeColor: {
                //     if (id != "") {
                //         await this.eyeApi.eyeColorsEcIDPut(id, data as DefsEyesColorData);
                //     } else {
                //         await this.eyeApi.eyeColorsPost(data as DefsEyesColorData);
                //     }
                //     break;
                // }
                case EntityType.Gender: {
                    if (id != "") {
                        await this.genderApi.gendersGIDPut(id, data as DefsGenderData);
                    } else {
                        await this.genderApi.gendersPost(data as DefsGenderData);
                    }
                    break;
                }
                case EntityType.Hobby: {
                    if (id != "") {
                        await this.hobbiesApi.hobbiesHIDPut(id, data as DefsHobbyData);
                    } else {
                        await this.hobbiesApi.hobbiesPost(data as DefsHobbyData);
                    }
                    break;
                }
                case EntityType.Interest: {
                    if (id != "") {
                        await this.interestsApi.interestsIIDPut(id, data as DefsInterestsData);
                    } else {
                        await this.interestsApi.interestsPost(data as DefsInterestsData);
                    }
                    break;
                }
                case EntityType.Race: {
                    if (id != "") {
                        await this.raceApi.racesRIDPut(id, data as DefsRaceData);
                    } else {
                        await this.raceApi.racesPost(data as DefsRaceData);
                    }
                    break;
                }
                case EntityType.SexualOrientation: {
                    if (id != "") {
                        await this.sexOrApi.sexualOrientationsSoIDPut(id, data as DefsSexualOrientationData);
                    } else {
                        await this.sexOrApi.sexualOrientationsPost(data as DefsSexualOrientationData);
                    }
                    break;
                }

                default: {
                    break;
                }
            }

        } catch (error) {
            console.error(`An unexpected error occurred: ${error}`);
            throw error;
        }
    }

    async delelte(entityType: EntityType, id: string) {
        try {
            switch (entityType) {
                case EntityType.Profession: {
                    await this.profApi.professionsPIDDelete(id);
                    break;
                }
                case EntityType.BodyType: {
                    await this.bodyTypesApi.bodyTypesBtIDDelete(id);
                    break;
                }
                case EntityType.Education: {
                    await this.eduApi.educationsEIDDelete(id);
                    break;
                }
                case EntityType.EyeColor: {
                    await this.eyeApi.eyeColorsEcIDDelete(id);
                    break;
                }
                case EntityType.Gender: {
                    await this.genderApi.gendersGIDDelete(id);
                    break;
                }
                case EntityType.Hobby: {
                    await this.hobbiesApi.hobbiesHIDDelete(id);
                    break;
                }
                case EntityType.Interest: {
                    await this.interestsApi.interestsIIDDelete(id);
                    break;
                }
                case EntityType.Race: {
                    await this.raceApi.racesRIDDelete(id);
                    break;
                }
                case EntityType.SexualOrientation: {
                    await this.sexOrApi.sexualOrientationsSoIDDelete(id);
                    break;
                }

                default: {
                    break;
                }
            }

        } catch (error) {
            console.error(`An unexpected error occurred: ${error}`);
            throw error;
        }
    }

    initialize() {
        console.log("entities initialize")
    }
}
